import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import * as React from "react";
import {
  Address,
  transformGeocoderAddressComponentsToAddress,
} from "../../services/address";

const googleApiLibraries: Array<"places"> = ["places"];
const googleAutocompleteResultFields: Array<string> = ["address_components"];

// https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-multiple-countries
const USA_AND_UNINCORPORATED_ORGANIZED_TERRITORIES = [
  "us",
  "pr",
  "vi",
  "gu",
  "mp",
];
interface Props {
  children: React.ReactElement;
  onAddressSelected: (address: Address) => void;
}

export const AddressAutocomplete = ({
  children,
  onAddressSelected,
}: Props): React.ReactElement => {
  const ref = React.useRef<Autocomplete>(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
    libraries: googleApiLibraries,
  });
  const [autocomplete, setAutocomplete] =
    React.useState<google.maps.places.Autocomplete>();

  const handleLoad = (ac: google.maps.places.Autocomplete) => {
    /**
     * Currently, when user select the address using enter key, it will also trigger form submission, which is not desireable.
     * Pressing enter when selecting address from dropdown should only trigger address selection, not form submission.
     * I can't figure out how to disable the form submission behavior when the address selection is visible.
     * So for now, we just always disable the Enter button for form submission. - Abadi Kurniawan
     */
    if (ref.current) {
      ref.current.containerElement.current?.addEventListener(
        "keydown",
        preventEnter
      );
    }

    ac.setComponentRestrictions({
      country: USA_AND_UNINCORPORATED_ORGANIZED_TERRITORIES,
    });

    setAutocomplete(ac);
  };

  const triggerAddressSelected = () => {
    const place = autocomplete?.getPlace();
    if (!place?.address_components) return;

    const address = transformGeocoderAddressComponentsToAddress(
      place.address_components
    );
    onAddressSelected(address);
  };

  // Fall back to plain input if the Google API fails to load.
  if (!isLoaded || loadError) return children;

  return (
    <Autocomplete
      onLoad={handleLoad}
      onPlaceChanged={triggerAddressSelected}
      fields={googleAutocompleteResultFields}
      ref={ref}
    >
      {children}
    </Autocomplete>
  );
};

function preventEnter(key: KeyboardEvent) {
  if (key.code === "Enter") {
    key.preventDefault();
  }
}
