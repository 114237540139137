import { GoogleMap } from "@react-google-maps/api";

export class GoogleMap2 extends GoogleMap {
  private mapByRef: WeakMap<HTMLElement, google.maps.Map> = new WeakMap();

  getInstance: () => google.maps.Map | null = () => {
    if (this.mapRef === null) {
      return null;
    }

    let map = this.mapByRef.get(this.mapRef);
    if (!map) {
      map = new google.maps.Map(this.mapRef, this.props.options);
      this.mapByRef.set(this.mapRef, map);
    }

    return map;
  };
}
