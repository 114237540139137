import { Flex, BoxProps, Button } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "../components/InputText";
import { useDebounce } from "../hooks/useDebounce";
import { searchPlacements } from "../store/placements";
import { RootState } from "../store";

interface Props extends BoxProps {
  onClose: () => void;
}
export const Search = ({ onClose, ...props }: Props): React.ReactElement => {
  const search: any = useSelector((root: RootState) => root.search);
  const [term, setTerm] = React.useState<string>(search?.placements?.text);
  const debouncedTerm = useDebounce(term, 100);
  const dispatch = useDispatch();
  const intl = useIntl();

  React.useEffect(() => {
    if (debouncedTerm !== undefined) {
      dispatch(searchPlacements(debouncedTerm));
    }
  }, [dispatch, debouncedTerm]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTerm(e?.target?.value || "");
  };

  const handleClear = () => {
    setTerm("");
  };

  return (
    <Flex
      {...props}
      overflow="auto"
      background="gray.100"
      paddingX={{ base: 7, lg: 4 }}
      paddingY={{ base: 5, lg: 3 }}
      align="center"
      width={{ base: "100%" }}
    >
      <InputText
        autoFocus={true}
        label={intl.formatMessage({ id: "SEARCH_LABEL" })}
        placeholder={intl.formatMessage({ id: "SEARCH_PLACEHOLDER" })}
        onChange={handleSearch}
        flexGrow={1}
        background="white"
        value={term}
        inputRightElement={
          <Button
            variant="link"
            onClick={handleClear}
            fontSize="xs"
            color="gray.700"
            paddingX={4}
          >
            <FormattedMessage id="CLEAR_SEARCH_LABEL" />
          </Button>
        }
      />
    </Flex>
  );
};
