import { Step } from "../hooks/useStep";

export interface CalculateAcceptanceProbabilityOptions {
  minimumDesired: number;
  defaultAcceptanceRate: number;
  individualScalingFactor: number;
}

export const calculateAcceptanceProbability = (
  probabilities: (number | undefined)[],
  options: CalculateAcceptanceProbabilityOptions
): number => {
  const scalingFactor =
    probabilities.length > options.minimumDesired
      ? 1
      : probabilities.length / options.minimumDesired;

  const rejectionProbability = probabilities.reduce((acc, cur) => {
    const probability = cur || options.defaultAcceptanceRate;
    return Number(acc) * (1 - probability * options.individualScalingFactor);
  }, 1);

  const acceptanceProbability =
    rejectionProbability === undefined ? 0 : 1 - rejectionProbability;

  return scalingFactor * acceptanceProbability;
};

const thresholds: Record<Step, number> = {
  1: 0,
  2: 0.25,
  3: 0.5,
  4: 0.75,
  5: 0.96,
};

export const getStepForProbability = (
  acceptanceProbability: number | undefined
): Step | undefined => {
  if (acceptanceProbability === undefined) return;

  let step: Step | undefined = undefined;

  Object.keys(thresholds).forEach((curStepString) => {
    const curStep = Number(curStepString) as Step;
    const threshold: number = thresholds[curStep];
    if (threshold <= acceptanceProbability) {
      step = curStep;
    }
  });

  if (step === undefined) return undefined;
  return isNaN(step) ? undefined : step;
};
