import {
  createAsyncThunk,
  createSlice,
  ThunkDispatch,
  AnyAction,
} from "@reduxjs/toolkit";
import axios from "axios";
import { Message } from "../types/Message";

export type RemoteData =
  | { status: "notAsked" }
  | { status: "loading" }
  | { status: "finished"; timeoutId: number }
  | { status: "failure"; timeoutId: number };

const notAsked: RemoteData = { status: "notAsked" };
const loading: RemoteData = { status: "loading" };
const finished = (timeoutId: number): RemoteData => ({
  status: "finished",
  timeoutId,
});
const failure = (timeoutId: number): RemoteData => ({
  status: "failure",
  timeoutId,
});

type MessagingSliceState = {
  sms: RemoteData;
  email: RemoteData;
};
const initialState: MessagingSliceState = {
  sms: notAsked,
  email: notAsked,
};

export const sendSms = createAsyncThunk(
  "messaging/sms/send",
  async (message: Message, thunkAPI): Promise<any> => {
    if (!process.env.REACT_APP_PIPELINE_ENDPOINT)
      return failure(delayClear(thunkAPI.dispatch));
    try {
      await axios.post(
        `${process.env.REACT_APP_PIPELINE_ENDPOINT}/sms`,
        message
      );
      return finished(delayClear(thunkAPI.dispatch));
    } catch (error) {
      return failure(delayClear(thunkAPI.dispatch));
    }
  }
);

export const sendEmail = createAsyncThunk(
  "messaging/email/send",
  async (message: Message, thunkAPI): Promise<any> => {
    if (!process.env.REACT_APP_PIPELINE_ENDPOINT)
      return failure(delayClear(thunkAPI.dispatch));

    try {
      await axios.post(
        `${process.env.REACT_APP_PIPELINE_ENDPOINT}/email`,
        message
      );
      return finished(delayClear(thunkAPI.dispatch));
    } catch (error) {
      return failure(delayClear(thunkAPI.dispatch));
    }
  }
);

const CLEAR_DELAY = 5000;
function delayClear(
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>
): number {
  return window.setTimeout(() => {
    dispatch(clear());
  }, CLEAR_DELAY);
}

const messagingSlice = createSlice({
  name: "messaging",
  initialState,
  reducers: {
    clear: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendSms.pending, (state, action) => {
      if (state.sms.status === "finished" || state.sms.status === "failure") {
        clearTimeout(state.sms.timeoutId);
      }
      state.sms = loading;
    });
    builder.addCase(sendSms.fulfilled, (state, action) => {
      state.sms = action.payload;
    });
    builder.addCase(sendEmail.pending, (state, action) => {
      if (
        state.email.status === "finished" ||
        state.email.status === "failure"
      ) {
        clearTimeout(state.email.timeoutId);
      }
      state.email = loading;
    });
    builder.addCase(sendEmail.fulfilled, (state, action) => {
      state.email = action.payload;
    });
  },
});

const { reducer } = messagingSlice;
export const { clear } = messagingSlice.actions;
export default reducer;
