import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useConfig } from "./useConfig";
import * as Map from "../services/map";
import { Placement } from "../types/Placement";

export const useMapClusters = (): Map.ClusterCoordinates<Placement> | null => {
  const placements = useSelector((state: RootState) => state.placements);
  const results = useSelector((state: RootState) => state.results);
  const config = useConfig();

  const clusters = React.useMemo(() => {
    if (
      placements.status !== "finished" ||
      results.status !== "finished" ||
      config === undefined
    )
      return null;
    const coordinates = results.ids.map((id) => placements.byId[id]);
    return Map.clusterCoordinates(coordinates);
  }, [config, placements, results]);

  return clusters;
};
