import * as React from "react";
import { FlexProps } from "@chakra-ui/react";
import { RiMailLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { sendEmail } from "../../../store/messaging";
import { useConfig } from "../../../hooks/useConfig";
import { MessageForm } from "./MessageForm";
import { useIntl } from "react-intl";
import { InputText } from "../../../components/InputText";
import { MessageItem } from "../../../types/Message";
import { onEmailAnalytics } from "../../../services/analytics";
import { useOrganization } from "../../../hooks/useOrganization";

const verySimpleEmailRegex = /.+@.+/;
function validateEmail(email: string): boolean {
  return verySimpleEmailRegex.test(email);
}

export function EmailForm(props: FlexProps) {
  const { messaging } = useSelector((state: RootState) => state);
  const savedIds = useSelector((state: RootState) => state.saved.ids);
  const [destination, setDestination] = React.useState<string>("");
  const config = useConfig();
  const dispatch = useDispatch();
  const { locale } = useIntl();
  const intl = useIntl();
  const organization = useOrganization();
  const baseURL = document.location.origin;

  const handleSubmit = (items: MessageItem[]) => {
    if (!config) return;

    dispatch(
      sendEmail({
        to: destination,
        items,
        locale,
        organization_path: organization?.path || "",
        base_url: baseURL,
      })
    );

    onEmailAnalytics({ savedIds: savedIds.join(",") });
  };

  const isValid = React.useMemo(() => {
    try {
      return validateEmail(destination) && savedIds.length > 0;
    } catch {
      return false;
    }
  }, [destination, savedIds]);

  return (
    <MessageForm
      buttonLabel={"SEND"}
      buttonLoadingText={"SENDING"}
      successMessage={"EMAIL_SUCCESS"}
      errorMessage={"EMAIL_FAILURE"}
      message={messaging.email}
      {...props}
      onSubmit={handleSubmit}
      isValid={isValid}
    >
      <InputText
        type="email"
        label={intl.formatMessage({ id: "EMAIL" })}
        placeholder={intl.formatMessage({ id: "EMAIL" })}
        background="white"
        leftIcon={RiMailLine}
        autoFocus={true}
        onChange={(event) => {
          setDestination(event.target.value);
        }}
        value={destination}
      />
    </MessageForm>
  );
}
