import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { PlacementId } from "../types/Placement";
import { setSaved } from "../store/saved";
import { usePanelDisclosure } from "./usePanelDislosure";
import { useQueryParams } from "./useQueryParams";

export function useSavedPlacementsFromRoute(): PlacementId[] | undefined {
  const { saved } = useQueryParams();
  if (saved === undefined) {
    return undefined;
  }

  return Array.isArray(saved) ? saved : [saved];
}

export function useSyncRouteToRedux(): void {
  const { open, openPlacementProfile } = usePanelDisclosure();
  const { placementId } = useParams();
  const savedPlacementsFromRoute = useSavedPlacementsFromRoute();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (placementId) {
      openPlacementProfile(placementId);
    }

    if (savedPlacementsFromRoute) {
      open("savedPlacements");
      dispatch(setSaved(savedPlacementsFromRoute));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placementId]);
}
