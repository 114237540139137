import * as React from "react";
import { ButtonProps, Icon } from "@chakra-ui/react";
import { OverlayButton } from "./OverlayButton";
import { FaList } from "react-icons/fa";
import { RiMap2Line } from "react-icons/ri";
import { usePanelDisclosure } from "../hooks/usePanelDislosure";
import { FormattedMessage } from "react-intl";

export const MapListButton = (props: ButtonProps): React.ReactElement => {
  const { isOpen, open, close, closeMiniPlacementCard } = usePanelDisclosure();
  return (
    <OverlayButton
      {...props}
      onClick={() => {
        if (isOpen("placementList")) {
          close("placementList");
          open("map");
        } else {
          close("map");
          closeMiniPlacementCard();
          open("placementList");
        }
      }}
      leftIcon={
        isOpen("placementList") ? (
          <Icon fontSize="lg" as={RiMap2Line} />
        ) : (
          <Icon fontSize="md" as={FaList} />
        )
      }
    >
      <FormattedMessage
        id={isOpen("placementList") ? "MAP_VIEW_BUTTON" : "LIST_VIEW_BUTTON"}
      />
    </OverlayButton>
  );
};
