import { Flex } from "@chakra-ui/react";
import { FreeTextInput } from "./FreeTextInput";
import { AddressAutocomplete } from "./AddressAutoComplete";
import { useCallback } from "react";
import { Address } from "../../services/address";
import { useFormikContext } from "formik";
import { EligibilityForm } from "../../services/eligibility";

type Props = {
  fieldPrefix: string;
  isRequired?: boolean;
};

export const AddressForm = ({ fieldPrefix, isRequired }: Props) => {
  const formik = useFormikContext<EligibilityForm>();
  const withPrefix = useCallback(
    (name: string) => {
      if (!fieldPrefix) {
        return name;
      }

      return [fieldPrefix, name].join(".");
    },
    [fieldPrefix]
  );

  const handleAddressSelected = useCallback(
    (address: Address) => {
      formik.setValues(
        { ...formik.values, [fieldPrefix]: { ...address } },
        true // triggers validation
      );
    },
    [formik, fieldPrefix]
  );

  return (
    <Flex direction="column" gap={2}>
      <AddressAutocomplete onAddressSelected={handleAddressSelected}>
        <FreeTextInput
          isRequired={isRequired}
          name={withPrefix("streetAddress")}
          label="Street address"
          placeholder="E.g.: 532 Oakland Ave"
        />
      </AddressAutocomplete>
      <FreeTextInput
        name={withPrefix("streetAddressLine2")}
        label="Apartment, suite, or floor # (Optional)"
        placeholder="E.g.: 5B"
      />
      <FreeTextInput
        isRequired={isRequired}
        name={withPrefix("city")}
        label="City"
        placeholder="E.g.: New Orleans"
      />

      <Flex gap={2}>
        <FreeTextInput
          isRequired={isRequired}
          name={withPrefix("state")}
          label="State"
          placeholder="E.g.: LA"
        />
        <FreeTextInput
          isRequired={isRequired}
          name={withPrefix("zipCode")}
          label="Zip code"
          placeholder="E.g.: 10181"
        />
      </Flex>
    </Flex>
  );
};
