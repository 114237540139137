import { ComponentStyleConfig, CSSObject } from "@chakra-ui/react";
import { UIConfig } from "../types/UIConfig";
import { incompleteSwitch } from "./incompleteSwitch";
import { getPrimaryColor, getSecondaryColor } from "./colors";

export type Color = "secondary" | "primary" | "white";
export type Position = "inside" | "inside-offset" | "offset" | "outside";
export type Variant = { position?: Position; color?: Color };

const defaultVariant: Variant = { position: "outside", color: "secondary" };

export function getFocusStyle(
  config: UIConfig,
  { position, color }: Variant = defaultVariant
) {
  let override: CSSObject = {};
  switch (position) {
    case "inside":
      override.outlineOffset = "-3px";
      break;
    case "inside-offset":
      override.outlineOffset = "-5px";
      break;
    case "offset":
      override.outlineOffset = "2px";
      break;
    case "outside":
    case undefined:
      override.outlineOffset = "0";
      break;
    default:
      incompleteSwitch(position);
  }

  switch (color) {
    case "white":
      override.outlineColor = "white";
      break;
    case "primary":
      override.outlineColor = getPrimaryColor(config);
      break;
    case "secondary":
    case undefined:
      break;
    default:
      incompleteSwitch(color);
  }
  return getCustomFocusStyle(config, override);
}

function getCustomFocusStyle(config: UIConfig, override?: CSSObject) {
  const focus: CSSObject = {
    boxShadow: override?.boxShadow ?? "none",
    outlineColor: override?.outlineColor ?? getSecondaryColor(config),
    outlineWidth: override?.outlineWidth ?? 3,
    outlineOffset: override?.outlineOffset ?? 0,
    outlineStyle: override?.outlineStyle ?? "solid",
  };
  return {
    ":focus:not(:focus-visible)": {
      outline: "none",
      boxShadow: "none",
    },
    _focus: focus,
    _focusVisible: focus,
  };
}

export function applyFocusStyle(
  config: UIConfig,
  component: ComponentStyleConfig,
  variant: Variant = defaultVariant
): ComponentStyleConfig {
  const focusStyle = getFocusStyle(config, variant);
  return applyFocusStyleToComponent(focusStyle, component);
}

function applyFocusStyleToComponent(
  focusStyle: CSSObject,
  component: ComponentStyleConfig
): ComponentStyleConfig {
  return {
    ...component,
    baseStyle: {
      ...component.baseStyle,
      ...focusStyle,
    },
  } as any;
}
