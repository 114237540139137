import React from "react";
import { Box, Flex } from "@chakra-ui/layout";
import {
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { usePlacementData } from "../../../hooks/usePlacementData";
import { ContentItem, Tab as TabType } from "../../../types/UIConfig";
import { NavigationButton } from "./NavigationButton";
import animateScrollTo from "animated-scroll-to";
import { FormattedContentItem } from "./FormattedContentItem";
import { ContentItemLabel } from "../../../components/ContentItemLabel";

interface ProfileTabSectionProps {
  placementId: string | undefined;
}

export const ProfileTabSection = ({
  placementId,
}: ProfileTabSectionProps): React.ReactElement | null => {
  const config = useSelector((state: RootState) => state.config);
  const { getPlacementStringValue } = usePlacementData(placementId);
  const tabListRef = React.useRef<HTMLDivElement>();
  const [enableLeftNavigation, setEnableLeftNavigation] =
    React.useState<boolean>(false);
  const [enableRightNavigation, setEnableRightNavigation] =
    React.useState<boolean>(false);
  const [hasScroll, setHasScroll] = React.useState<boolean>(false);
  const [scrollLeft, setScrollLeft] = React.useState<number>(0);

  const tabs = React.useMemo(() => {
    if (config.state !== "finished") return [];

    return config.values.profile.tabs.filter((tab: TabType) => {
      return tab.items.find((item) => {
        return getPlacementStringValue(item);
      });
    });
  }, [config, getPlacementStringValue]);

  const [selectedTabText, setSelectedTabText] = React.useState<string>(
    tabs[0]?.label
  );

  const tabIndex = React.useMemo(() => {
    const index = tabs.findIndex((tab) => tab.label === selectedTabText);
    return index === -1 ? 0 : index;
  }, [tabs, selectedTabText]);

  const onScroll = React.useCallback(() => {
    if (!tabListRef.current) return;
    const { scrollLeft: left, scrollWidth, clientWidth } = tabListRef.current;

    setScrollLeft(tabListRef.current.scrollLeft);
    setEnableLeftNavigation(left > 0);
    setEnableRightNavigation(left < scrollWidth - clientWidth);
  }, []);

  React.useEffect(() => {
    if (!tabListRef.current) {
      return;
    }

    tabListRef.current.removeEventListener("scroll", onScroll);
    tabListRef.current.addEventListener("scroll", onScroll);

    setHasScroll(
      tabListRef.current.scrollWidth > tabListRef.current.clientWidth
    );
    setEnableRightNavigation(hasScroll);
  }, [tabListRef, getPlacementStringValue, hasScroll, onScroll]);

  const handleTabsChange = (index: number) => {
    setSelectedTabText(tabs[index].label);
  };

  return (
    <Box>
      <Tabs variant="unstyled" index={tabIndex} onChange={handleTabsChange}>
        <TabList
          ref={tabListRef}
          background="white"
          boxShadow={{ lg: "0px 4px 4px rgba(0, 0, 0, 0.15)" }}
          overflowX="auto"
          position="relative"
          css={{
            "&::-webkit-scrollbar": {
              display: "none" /* Chome, Safari, Opera */,
            },
            "&": {
              msOverflowStyle: "none" /* IE and Edge */,
              scrollbarWidth: "none" /* Firefox */,
            },
          }}
        >
          {hasScroll && (
            <NavigationButton
              disabled={!enableLeftNavigation}
              direction="left"
              onClick={() => {
                animateScrollTo([scrollLeft - 100, null], {
                  elementToScroll: tabListRef.current,
                });
              }}
              zIndex="2"
            />
          )}
          {tabs.map((tab: TabType) => (
            <Tab
              key={tab.label}
              background="gray.300"
              marginX="1px"
              fontWeight="semibold"
              fontSize="sm"
              letterSpacing="xl"
              paddingX={5}
              whiteSpace="nowrap"
              _selected={{ backgroundColor: "brand.500", color: "white" }}
            >
              {tab.label}
            </Tab>
          ))}
          {hasScroll && (
            <NavigationButton
              disabled={!enableRightNavigation}
              direction="right"
              onClick={() => {
                animateScrollTo([scrollLeft + 100, null], {
                  elementToScroll: tabListRef.current,
                });
              }}
              zIndex="2"
            />
          )}
        </TabList>

        <TabPanels>
          {tabs.map((tab: TabType) => (
            <TabPanel key={tab.label} backgroundColor="gray.100" padding={6}>
              {tab.items.map((item: ContentItem, idx: number) => {
                const placementValue = getPlacementStringValue(item);

                return (
                  placementValue && (
                    <Flex
                      key={`${tab.label}${item.label}${idx}`}
                      letterSpacing="xs"
                      paddingBottom={2}
                    >
                      <Text fontSize="sm" width="100%">
                        <ContentItemLabel item={item} />
                        <FormattedContentItem
                          value={placementValue}
                          contentItem={item}
                        />
                      </Text>
                    </Flex>
                  )
                );
              })}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
};
