import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PlacementId } from "../types/Placement";

type SelectedPlacementSliceType = {
  selectedPlacementId: PlacementId | undefined;
};
const initialState: SelectedPlacementSliceType = {
  selectedPlacementId: undefined,
};

const selectedPlacementSlice = createSlice({
  name: "selectedPlacement",
  initialState,
  reducers: {
    setSelectedPlacement: (state, action: PayloadAction<PlacementId>) => {
      state.selectedPlacementId = action.payload;
    },
    clearSelectedPlacement: (state) => {
      state.selectedPlacementId = undefined;
    },
  },
});

export const { setSelectedPlacement, clearSelectedPlacement } =
  selectedPlacementSlice.actions;
export default selectedPlacementSlice.reducer;
