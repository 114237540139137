import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Organization } from "../types/Organization";

type OrganizationSliceType = {
  organization: Organization | undefined;
};
const initialState: OrganizationSliceType = {
  organization: undefined,
};

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setOrganization: (state, action: PayloadAction<Organization>) => {
      state.organization = action.payload;
    },
    clearOrganization: (state) => {
      state.organization = undefined;
    },
  },
});

export const { setOrganization, clearOrganization } = organizationSlice.actions;
export default organizationSlice.reducer;
