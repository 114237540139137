import React from "react";
import { useSelector } from "react-redux";
import { MarkerClusterer } from "@react-google-maps/api";
import { CustomMarker } from "../../../components/CustomMarker";
import { PlacementId } from "../../../types/Placement";
import { RootState } from "../../../store";
import { ListPopover } from "../../../components/ListPopover";
import { ClusteredPlacementMarker } from "../../../components/ClusteredPlacementMarker";
import { Placement } from "../../../types/Placement";
import * as Map from "../../../services/map";
import { PlacementMarker } from "../../../components/PlacementMarker";
import { useIntl } from "react-intl";
import { useMapClusters } from "../../../hooks/useMapClusters";
import * as ClusterIcon from "../../../images/clusterIcon";
import { useColors } from "../../../hooks/useColors";

type Props = {
  onPlacementMarkerClick: (
    placementId: PlacementId,
    position: google.maps.LatLngLiteral
  ) => void;
};

const GMapContents = React.memo(
  ({ onPlacementMarkerClick }: Props): React.ReactElement | null => {
    const address = useSelector((state: RootState) => state.address);
    const [activeCluster, setActiveCluster] =
      React.useState<Map.Cluster<Placement>>();
    const intl = useIntl();

    const clusters = useMapClusters();
    const { primary } = useColors();

    const options = {
      minimumClusterSize: (clusters?.length ?? 0) < 500 ? 500 : undefined,
      styles: [
        {
          textColor: "white",
          url: ClusterIcon.url("", primary, 43, 47),
          height: 43,
          width: 47,
          fontWeight: "bold",
        },
      ],
    };

    return (
      <>
        {address.status === "finished" && (
          <CustomMarker
            markerType="location"
            label={intl.formatMessage({ id: "ADDRESS_MARKER_LABEL" })}
            position={{ lat: address.values.lat, lng: address.values.lng }}
          />
        )}

        {clusters && (
          <MarkerClusterer options={options} clusterClass="custom-cluster-icon">
            {(clusterer) => (
              <>
                {clusters.map((cluster: Map.CoordinateOrCluster<Placement>) => {
                  return Map.isCluster(cluster) ? (
                    <ClusteredPlacementMarker
                      key={cluster.coordinates[0].id}
                      cluster={cluster}
                      onClick={setActiveCluster}
                    />
                  ) : (
                    <PlacementMarker
                      key={cluster.id}
                      cluster={{ coordinate: cluster, coordinates: [cluster] }}
                      clusterer={clusterer}
                      disableHover={activeCluster !== undefined}
                      handleClick={(placementId, position) => {
                        setActiveCluster(undefined);
                        onPlacementMarkerClick(placementId, position);
                      }}
                    />
                  );
                })}
              </>
            )}
          </MarkerClusterer>
        )}

        <style>
          {`
            .custom-cluster-icon {
              z-index: 1;
            }
            .custom-cluster-icon:hover {
              transform: scale(1.2);
              filter: brightness(120%);
            }
        `}
        </style>
        {activeCluster && (
          <ListPopover
            placements={activeCluster.coordinates}
            onCloseClick={() => setActiveCluster(undefined)}
            onPlacementMarkerClick={onPlacementMarkerClick}
            position={new google.maps.LatLng(activeCluster.coordinate)}
          />
        )}
      </>
    );
  }
);

export { GMapContents };
