import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { CheckEligibilityButton } from "./CheckEligibilityButton";

export type CheckEligibilityProps = {
  buttonText: string;
  onCheckEligibility: () => void;
};
export const CheckEligibility = ({
  buttonText,
  onCheckEligibility,
}: CheckEligibilityProps): React.ReactElement => {
  return (
    <Flex direction="column" gap={4}>
      <Flex direction="column">
        <Text>Eligible schools</Text>
        <Text fontSize="sm" color="gray.700">
          Check eligibility with a student to see what they qualify for.
        </Text>
      </Flex>

      <Flex>
        <CheckEligibilityButton
          variant="outline"
          buttonText={buttonText}
          onClick={onCheckEligibility}
        />
      </Flex>
    </Flex>
  );
};
