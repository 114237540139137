import * as React from "react";
import { PlacementList } from "./PlacementList";
import { PlacementProfile } from "./PlacementProfile";
import { Map } from "./Map";
import { ControlBar } from "./ControlBar";
import { usePanelDisclosure, TopPanel } from "../hooks/usePanelDislosure";
import { SlideIn } from "../components/animation/SlideIn";
import { Filter } from "./Filter";
import { SavedPlacements } from "./SavedPlacements";
import { FadeIn } from "../components/animation/FadeIn";
import { Box, Flex } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { PlacementId } from "../types/Placement";
import { Search } from "./Search";

const Z_INDEX_MAP = 0;
const Z_INDEX_CONTROL_BAR = 1;
const Z_INDEX_PANELS = 2;

export const LargeScreen = (): React.ReactElement => {
  const [center, setCenter] = React.useState<
    google.maps.LatLngLiteral | undefined
  >(undefined);
  const { isOpen, open, close, openPlacementProfile, closePlacementProfile } =
    usePanelDisclosure();

  const initializePanel = React.useCallback(() => {
    open("placementList");
    open("map");
  }, [open]);

  const [map, setMap] = React.useState<google.maps.Map>();

  React.useEffect(() => {
    initializePanel();
  }, [initializePanel]);

  function defaultToggle(panel: TopPanel) {
    if (isOpen(panel)) {
      close(panel);
    } else {
      open(panel);
    }
  }

  function toggleSavedPlacements() {
    if (isOpen("savedPlacements")) {
      close("savedPlacements");
      open("placementList");
    } else {
      close("placementList");
      open("savedPlacements");
    }
  }

  function toggleFilter() {
    if (isOpen("filter")) {
      close("filter");
    } else {
      closePlacementProfile();
      open("filter");
    }
  }

  function toggle(panel: TopPanel) {
    switch (panel) {
      case "savedPlacements":
        toggleSavedPlacements();
        break;
      case "filter":
        toggleFilter();
        break;
      default:
        defaultToggle(panel);
    }
  }

  const handlePlacementClick = React.useCallback(
    (placementId: PlacementId, position: google.maps.LatLngLiteral) => {
      close("filter");
      openPlacementProfile(placementId);
      setCenter(position);
    },
    [close, openPlacementProfile]
  );

  const [placementProfileWidth, setPlacementProfileWidth] =
    React.useState<number>();

  const handleLoadMap = (loadedMap: google.maps.Map) => {
    setMap(loadedMap);
  };

  return (
    <Flex
      direction="column"
      width="100%"
      height="100%"
      flexWrap="nowrap"
      overflow="hidden"
      flexGrow={1}
    >
      <ControlBar
        isOpen={isOpen}
        toggle={toggle}
        map={map}
        zIndex={Z_INDEX_CONTROL_BAR}
      />
      <Flex
        as="main"
        direction="row"
        height="100%"
        width="100%"
        overflow="hidden"
        position="relative"
      >
        {(isOpen("placementProfile") || isOpen("filter")) && (
          <Box
            height="100%"
            padding={0}
            position="relative"
            width={{ base: "100%", lg: "440px", "2xl": "700px" }}
            zIndex={Z_INDEX_PANELS}
          >
            <AnimatePresence>
              {isOpen("placementProfile") && (
                <SlideIn
                  key="placementProfile"
                  boxShadow="0px 12px 12px rgba(0, 0, 0, 0.15)"
                  direction="left"
                  height="100%"
                  position="relative"
                  width="100%"
                >
                  <PlacementProfile onWidthChange={setPlacementProfileWidth} />
                </SlideIn>
              )}

              {isOpen("filter") && (
                <SlideIn
                  key="filter"
                  boxShadow="0px 12px 12px rgba(0, 0, 0, 0.15)"
                  direction="left"
                  height="100%"
                  left="0"
                  position="absolute"
                  top="0"
                  width="100%"
                >
                  <Filter
                    onClose={() => toggle("filter")}
                    zIndex={Z_INDEX_PANELS}
                  />
                </SlideIn>
              )}
            </AnimatePresence>
          </Box>
        )}
        <Box height="100%" flexGrow={2} position="relative" padding={0}>
          <AnimatePresence initial={false}>
            <Map
              key="map"
              map={map}
              onLoadMap={handleLoadMap}
              onPlacementMarkerClick={handlePlacementClick}
              zIndex={Z_INDEX_MAP}
              profilePanelWidth={placementProfileWidth}
              center={center}
            />
            {isOpen("search") && (
              <SlideIn
                key="search"
                direction="top"
                width={{ lg: isOpen("placementProfile") ? "100%" : "50%" }}
                height={16}
                position="absolute"
                right={0}
                zIndex={Z_INDEX_PANELS}
              >
                <Search onClose={() => close("search")} />
              </SlideIn>
            )}
          </AnimatePresence>
        </Box>
        <Box
          height="100%"
          width="460px"
          overflow="hidden"
          position="relative"
          zIndex={Z_INDEX_PANELS}
          boxShadow="0px 12px 12px rgba(0, 0, 0, 0.15)"
        >
          <AnimatePresence initial={false}>
            {isOpen("placementList") && (
              <FadeIn key="placementList">
                <PlacementList onClick={handlePlacementClick} />
              </FadeIn>
            )}
            {isOpen("savedPlacements") && (
              <SlideIn key="savedPlacements" direction="right">
                <SavedPlacements onClose={() => toggleSavedPlacements()} />
              </SlideIn>
            )}
          </AnimatePresence>
        </Box>
      </Flex>
    </Flex>
  );
};
