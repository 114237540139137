import React from "react";
import { Box, CheckboxProps, useCheckbox } from "@chakra-ui/react";
import { useFocusStyle } from "../hooks/useFocusStyle";

type CheckboxCardProps = CheckboxProps & {
  children: React.ReactNode;
};

export const CheckboxCard = (props: CheckboxCardProps): React.ReactElement => {
  const { getRootProps, getInputProps, getCheckboxProps } = useCheckbox(props);
  const focusStyle = useFocusStyle({ position: "offset" });

  const root = getRootProps();
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" {...root}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius={8}
        fontWeight="600"
        fontSize="sm"
        borderColor="gray.300"
        _checked={{
          bg: "brand.500",
          color: "white",
        }}
        px={4}
        py={3}
        sx={focusStyle}
      >
        {props.children}
      </Box>
    </Box>
  );
};
