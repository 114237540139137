import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DistanceById {
  [key: string]: number;
}

type DistanceSliceState = { distanceToPlacementById: DistanceById };

const initialState: DistanceSliceState = { distanceToPlacementById: {} };

const distanceSlice = createSlice({
  name: "distance",
  initialState,
  reducers: {
    setDistanceToPlacementById(state, action: PayloadAction<DistanceById>) {
      return {
        ...state,
        distanceToPlacementById: action.payload,
      };
    },
  },
});

export const { setDistanceToPlacementById } = distanceSlice.actions;
export default distanceSlice.reducer;
