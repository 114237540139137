import { UIConfig } from "../types/UIConfig";

export const getPrimaryColor = (
  config: UIConfig,
  opacity: number = 1
): string => {
  if (config.primaryColor) {
    return `hsla(${config.primaryColor.hue}, ${config.primaryColor.saturation}, ${config.primaryColor.lightness}, ${opacity})`;
  }

  return `hsla(182, 100%, 34%, ${opacity})`;
};

export const getSecondaryColor = (config: UIConfig): string => {
  if (config.secondaryColor) {
    return `hsl(${config.secondaryColor.hue}, ${config.secondaryColor.saturation}, ${config.secondaryColor.lightness})`;
  }

  return "hsla(234, 24%, 39%, 1)";
};
