import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Field, FieldProps, useField } from "formik";
import { ChangeEvent, useCallback } from "react";

type Props = {
  name: string;
  label: string;
  onChange?: () => void;
  placeholder?: string;
  isRequired?: boolean;
};

export const FreeTextInput = ({
  name,
  label,
  placeholder,
  onChange,
  isRequired,
}: Props) => {
  const [, , helper] = useField(name);

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      helper.setValue(event.target.value);
      onChange && onChange();
    },
    [helper, onChange]
  );

  return (
    <Field name={name} isRequired={isRequired}>
      {({ field, meta }: FieldProps) => (
        <FormControl
          isRequired={isRequired}
          isInvalid={Boolean(meta.error && meta.touched)}
        >
          <FormLabel>{label}</FormLabel>
          <Input
            {...field}
            placeholder={placeholder}
            onChange={handleOnChange}
          />
          {meta.error && <FormErrorMessage>{meta.error}</FormErrorMessage>}
        </FormControl>
      )}
    </Field>
  );
};
