import {
  FormControl,
  Icon,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  VisuallyHidden,
  InputLeftAddon,
  forwardRef,
} from "@chakra-ui/react";
import * as React from "react";
import { IconType } from "react-icons/lib";

interface Props extends InputProps {
  label: string;
  hideLabel?: boolean;
  leftIcon?: IconType;
  leftAddon?: string;
  inputRightElement?: React.ReactNode;
}
export const InputText = forwardRef<Props, "input">(
  (
    {
      hideLabel = true,
      label,
      leftIcon,
      leftAddon,
      inputRightElement,
      ...props
    }: Props,
    ref
  ): React.ReactElement => {
    return (
      <FormControl>
        {hideLabel ? (
          <VisuallyHidden>
            <FormLabel>{label}</FormLabel>
          </VisuallyHidden>
        ) : (
          <FormLabel>{label}</FormLabel>
        )}
        <InputGroup>
          {leftAddon && <InputLeftAddon children={leftAddon} />}
          {leftIcon && (
            <InputLeftElement
              children={<Icon as={leftIcon} color="gray.500" />}
            />
          )}
          <Input ref={ref} {...props}></Input>
          {inputRightElement && (
            <InputRightElement marginX={2} children={inputRightElement} />
          )}
        </InputGroup>
      </FormControl>
    );
  }
);
