import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { CheckEligibilityLink } from "../Buttons/CheckEligibilityLink";
import { Text } from "@chakra-ui/react";
import { InfoAlert } from "./InfoAlert";
import {
  setShowCheckEligibilityModal,
  setShowFirstSavedSchoolModal,
} from "../../store/modals";
import React from "react";

export type Props = {
  children: ({
    checkEligibilityLink,
  }: {
    checkEligibilityLink: React.ReactNode;
  }) => React.ReactNode;
};

export const EligibilityInfoAlert: React.FC<Props> = ({ children }: Props) => {
  const config = useSelector((state: RootState) => state.config);
  const placements = useSelector((state: RootState) => state.placements);
  const dispatch = useDispatch();

  if (config.state === "loading") return <></>;
  if (placements.status === "loading") return <></>;
  if (!config.values.eligibility?.isEnabled) return <></>;

  const checkEligibilityLink = (
    <CheckEligibilityLink
      onClick={() => {
        dispatch(setShowFirstSavedSchoolModal(false));
        dispatch(setShowCheckEligibilityModal(true));
      }}
      continueButtonText={
        config?.values.eligibility?.welcomeModal?.continueButtonText ??
        "Check eligibility"
      }
    />
  );

  return !placements.eligibilityEnabled ? (
    <InfoAlert children={<Text>{children({ checkEligibilityLink })}</Text>} />
  ) : (
    <></>
  );
};
