import * as React from "react";
import {
  Flex,
  IconButton,
  Spacer,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  Image,
  Link,
  Box,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

import { Navigation } from "./Navigation";
import { useBreakpointValue } from "../hooks/useBreakpointValue";
import { TranslationSelector } from "../components/TranslationSelector";
import { useConfig } from "../hooks/useConfig";
import { SubtleAlert } from "../components/SubtleAlert";
import poweredByImg from "../images/powered-by.png";

export const Header = (): React.ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isBaseBreakpoint } = useBreakpointValue();
  const config = useConfig();

  const logo = config?.logo;
  const disclaimer = config?.list?.disclaimer;

  return (
    <header>
      <Flex
        paddingY={2}
        paddingX={{ lg: 2 }}
        gridGap={2}
        alignItems="center"
        background="white"
        height={{ base: "48px", lg: "72px" }}
      >
        {isBaseBreakpoint && (
          <>
            <IconButton
              aria-label="Menu"
              icon={<HamburgerIcon />}
              onClick={onOpen}
              variant="unstyled"
              size="lg"
              width="20px"
            />
            <Drawer isOpen={isOpen} onClose={onClose} placement="left">
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerBody>
                  <Navigation marginY={14} marginX={2} />
                </DrawerBody>
                <DrawerFooter justifyContent="center">
                  <Flex flexDirection="column" align="center">
                    {disclaimer && <SubtleAlert content={disclaimer.value} />}
                    <Image src={poweredByImg} width="86px" marginTop={4} />
                  </Flex>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
          </>
        )}
        {logo && (
          <Link
            marginLeft={{ lg: 8 }}
            href={logo.href}
            isExternal={logo.opensNewTab !== false}
          >
            <Image
              src={logo.imageUrl}
              alt={logo.label}
              height={{ base: "24px", lg: "40px" }}
            />
          </Link>
        )}
        {isBaseBreakpoint && <Spacer />}
        {!isBaseBreakpoint && (
          <>
            <Spacer />
            <Box paddingRight={5}>
              <Navigation />
            </Box>
          </>
        )}
        <TranslationSelector />
      </Flex>
    </header>
  );
};
