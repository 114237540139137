// workaround for 100vh safari iOS issue from: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
export default function calculateViewHeightWorkaround(): void {
  function calculateViewHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }
  calculateViewHeight();

  // We listen to the resize event
  // We execute the same script as before
  window.addEventListener("resize", () => {
    calculateViewHeight();
  });
}
