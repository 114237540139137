export const url = (fill: string | undefined, width: number, height: number) =>
  `data:image/svg+xml;base64,${window.btoa(svg(fill, width, height))}`;

export const svg = (
  fill: string | undefined,
  width: number,
  height: number
) => `
<svg width="${width}" height="${height}" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39.6763 18.9879C39.1892 21.503 37.9007 24.2895 36.1841 27.0807C34.4724 29.8639 32.3613 32.6098 30.2718 35.0381C28.183 37.4654 26.1258 39.5637 24.5293 41.0512C23.7301 41.7958 23.0536 42.3805 22.5514 42.7747C22.3207 42.9558 22.1365 43.0889 22 43.1772C21.8636 43.0889 21.6794 42.9558 21.4487 42.7747C20.9465 42.3805 20.27 41.7958 19.4708 41.0512C17.8743 39.5637 15.8171 37.4654 13.7283 35.0381C11.6388 32.6098 9.52767 29.8639 7.81598 27.0807C6.09938 24.2895 4.81084 21.503 4.32382 18.9879C2.39439 9.02357 11.0204 0.75 22 0.75C32.9797 0.75 41.6057 9.02357 39.6763 18.9879ZM21.8197 43.2803C21.8197 43.2802 21.8237 43.2783 21.8313 43.2757C21.8235 43.2792 21.8198 43.2805 21.8197 43.2803Z" ${
  fill ? `fill="${fill}"` : ""
} fill-opacity=".75" stroke="#fff" stroke-width="1.5" />
<path d="M22 28.1366L26.95 23.1246C27.9289 22.1333 28.5955 20.8704 28.8656 19.4956C29.1356 18.1207 28.9969 16.6957 28.4671 15.4006C27.9373 14.1056 27.04 12.9987 25.8889 12.2199C24.7378 11.4411 23.3844 11.0255 22 11.0255C20.6156 11.0255 19.2622 11.4411 18.1111 12.2199C16.96 12.9987 16.0628 14.1056 15.5329 15.4006C15.0031 16.6957 14.8644 18.1207 15.1345 19.4956C15.4045 20.8704 16.0711 22.1333 17.05 23.1246L22 28.1366ZM22 31L15.636 24.5563C14.3773 23.2819 13.5202 21.6581 13.1729 19.8905C12.8257 18.1228 13.0039 16.2905 13.6851 14.6254C14.3663 12.9603 15.5198 11.5371 16.9999 10.5358C18.4799 9.53445 20.22 9 22 9C23.78 9 25.5201 9.53445 27.0001 10.5358C28.4802 11.5371 29.6337 12.9603 30.3149 14.6254C30.9961 16.2905 31.1743 18.1228 30.8271 19.8905C30.4798 21.6581 29.6227 23.2819 28.364 24.5563L22 31V31ZM22 20.1377C22.5304 20.1377 23.0391 19.9243 23.4142 19.5445C23.7893 19.1648 24 18.6497 24 18.1126C24 17.5755 23.7893 17.0605 23.4142 16.6807C23.0391 16.3009 22.5304 16.0876 22 16.0876C21.4696 16.0876 20.9609 16.3009 20.5858 16.6807C20.2107 17.0605 20 17.5755 20 18.1126C20 18.6497 20.2107 19.1648 20.5858 19.5445C20.9609 19.9243 21.4696 20.1377 22 20.1377ZM22 22.1627C20.9391 22.1627 19.9217 21.736 19.1716 20.9765C18.4214 20.2169 18 19.1868 18 18.1126C18 17.0385 18.4214 16.0083 19.1716 15.2488C19.9217 14.4892 20.9391 14.0625 22 14.0625C23.0609 14.0625 24.0783 14.4892 24.8284 15.2488C25.5786 16.0083 26 17.0385 26 18.1126C26 19.1868 25.5786 20.2169 24.8284 20.9765C24.0783 21.736 23.0609 22.1627 22 22.1627Z"
fill="#fff" fill-opacity="1" stroke-width="0" />
</svg>
`;
