import { Button, ButtonProps } from "@chakra-ui/react";
import { EligibilityWelcomeModal } from "../../types/UIConfig";
export interface EligibilityButtonGroupProps {
  variant: ButtonProps["variant"];
  isContinueDisabled?: boolean;
  onCancelButton: () => void;
  onContinueButton: () => void;
  modalConfig: EligibilityWelcomeModal;
}
export const EligibilityButtonGroup = ({
  variant,
  isContinueDisabled,
  onCancelButton,
  onContinueButton,
  modalConfig,
}: EligibilityButtonGroupProps) => {
  const { cancelButtonText, continueButtonText } = modalConfig;

  const cancelText = cancelButtonText ? cancelButtonText : "Cancel";
  const continueText = continueButtonText
    ? continueButtonText
    : "Check eligibility";

  return (
    <>
      <Button
        variant={variant}
        color="primary.500"
        aria-label={cancelText}
        onClick={onCancelButton}
        _focus={{ outline: "none", boxShadow: "none" }}
      >
        {cancelText}
      </Button>

      <Button
        variant={variant}
        color="primary.500"
        aria-label={continueText}
        onClick={onContinueButton}
        isDisabled={isContinueDisabled}
        _focus={{ outline: "none", boxShadow: "none" }}
      >
        {continueText}
      </Button>
    </>
  );
};
