import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PlacementId = string;
type SavedSliceType = {
  ids: PlacementId[];
  savedFirstSchoolAcknowledged: boolean;
  eligibilityWelcomeAcknowledged: boolean;
};
const initialState: SavedSliceType = {
  ids: [],
  savedFirstSchoolAcknowledged: false,
  eligibilityWelcomeAcknowledged: false,
} as SavedSliceType;

const savedSlice = createSlice({
  name: "saved",
  initialState,
  reducers: {
    setSaved: (state, action: PayloadAction<PlacementId[]>) => {
      state.ids = action.payload;
    },
    addSaved: (state, action: PayloadAction<PlacementId>) => {
      state.ids.push(action.payload);
    },
    removeSaved: (state, action: PayloadAction<PlacementId>) => {
      state.ids = state.ids.filter((id) => id !== action.payload);
    },
    setSavedFirstSchoolAcknowledged: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.savedFirstSchoolAcknowledged = action.payload;
    },
    setEligibilityWelcomeAcknowledged: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.eligibilityWelcomeAcknowledged = action.payload;
    },
  },
});

export const {
  setSaved,
  addSaved,
  removeSaved,
  setSavedFirstSchoolAcknowledged,
  setEligibilityWelcomeAcknowledged,
} = savedSlice.actions;
export default savedSlice.reducer;
