import axios from "axios";

export const fetchConfigHash = async (
  organizationPath: string,
  filename: string
): Promise<string> => {
  const URL = process.env.REACT_APP_PIPELINE_ENDPOINT;
  if (!URL) return Promise.reject();

  const path = `${URL}/etag/${organizationPath}/${filename}`;
  return await axios
    .get<{ etag: string }>(path)
    .then(({ data }) => {
      return data.etag;
    })
    .catch((_) => {
      return "";
    });
};
