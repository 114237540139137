import * as React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, BoxProps, Flex, Text, Image } from "@chakra-ui/react";
import { placementPath, thumbnailUrl } from "../services/urlBuilder";
import { usePlacementData } from "../hooks/usePlacementData";
import { RootState } from "../store";
import { FormattedMessage } from "react-intl";
import { SavePlacementButton } from "./SavePlacementButton";
import { FormattedDistanceToPlacement } from "./FormattedDistanceToPlacement";
import { usePanelDisclosure } from "../hooks/usePanelDislosure";
import { ContentItem } from "../types/UIConfig";
import noSchoolImg from "../images/no-photo-tn.jpg";
import { BoundaryTag } from "./BoundaryTag";
import { useOrganization } from "../hooks/useOrganization";
import * as Redux from "react-redux";

export const MiniPlacementCard = ({
  ...props
}: BoxProps): React.ReactElement | null => {
  const location = useLocation();
  const { selectedPlacementId } = Redux.useSelector(
    (root: RootState) => root.selectedPlacement
  );

  const organization = useOrganization();
  const { placement, getPlacementStringValue } =
    usePlacementData(selectedPlacementId);
  const distanceToPlacementById = useSelector(
    (state: RootState) => state.distance.distanceToPlacementById
  );
  const config = useSelector((state: RootState) => state.config);

  const { openPlacementProfile } = usePanelDisclosure();

  if (selectedPlacementId === undefined) return null;
  if (config.state !== "finished") return null;

  const continueButtonText =
    config?.values?.eligibility?.welcomeModal?.continueButtonText ??
    "Check eligibility";

  return (
    <Box
      onClick={() => openPlacementProfile(selectedPlacementId)}
      as={RouterLink}
      to={{
        ...location,
        pathname: placementPath(selectedPlacementId),
      }}
      display="block"
      height="100%"
      width="100%"
      position="absolute"
      bottom="0"
      backgroundColor="white"
      {...props}
      boxShadow="dark-lg"
    >
      <Flex
        flexDirection="row-reverse"
        position="relative"
        paddingY="2"
        backgroundColor="gray.100"
        height="100%"
        borderBottom="2px solid"
        borderColor="gray.100"
        align="center"
        _hover={{
          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Image
          src={thumbnailUrl(organization?.path || "", selectedPlacementId)}
          alt={placement?.name}
          fallbackSrc={noSchoolImg}
          position="relative"
          objectFit="cover"
          width="auto"
          maxHeight="80%"
        />
        <Flex
          direction="column"
          flexGrow={1}
          margin={2}
          marginLeft={3}
          alignItems="flex-start"
        >
          <Text as="span" fontSize="md" letterSpacing="xl" minHeight="2em">
            {placement?.name}
          </Text>
          <Box
            fontSize="sm"
            display="flex"
            fontWeight={600}
            flexDirection="row"
            flexWrap="wrap"
            alignItems="flex-start"
          >
            <Box as="span" marginRight={3}>
              {config.values.list.highlighted
                .map((item: ContentItem) => getPlacementStringValue(item))
                .join(" | ")}
            </Box>
            {distanceToPlacementById[selectedPlacementId] && (
              <Box>
                <Box as="span" marginRight={1} fontWeight={400}>
                  <FormattedMessage id="DISTANCE" />:
                </Box>
                <Box as="span" marginRight={1}>
                  <FormattedDistanceToPlacement id={selectedPlacementId} />
                </Box>
              </Box>
            )}
          </Box>
          {placement && <BoundaryTag placement={placement} />}
        </Flex>
        <Box
          position="absolute"
          right={0}
          top={0}
          alignSelf="baseline"
          marginTop="0"
          marginRight="1"
        >
          <SavePlacementButton
            continueButtonText={continueButtonText}
            placementId={selectedPlacementId}
            boxShadow="0px 2px 2px rgba(0, 0, 0, 0.15)"
          />
        </Box>
      </Flex>
    </Box>
  );
};
