import React from "react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { getConfiguredStore } from "../store";
import { fetchConfigRaw } from "../store/config";
import { UIConfig } from "../types/UIConfig";
import { App } from "./App";
import { Routes, Route } from "react-router-dom";
import { HOME_PATH, PLACEMENT_PATH } from "../services/urlBuilder";
import { Organization, fetchOrganization } from "../types/Organization";
import { GenericError } from "../components/Feedback/GenericError";

export const ConfiguredApp = () => {
  const [uiConfig, setUiConfig] = React.useState<UIConfig>();
  const [organization, setOrganization] = React.useState<
    Organization | undefined
  >();

  React.useEffect(() => {
    const fetch = async () => {
      const organization = await fetchOrganization(document.location.hostname);
      setOrganization(organization);
      const config = await fetchConfigRaw(undefined, organization.path);
      setUiConfig(config);
    };
    fetch();
  }, []);

  const store = React.useMemo(() => {
    return getConfiguredStore(uiConfig);
  }, [uiConfig]);

  const persistor = React.useMemo(() => {
    return persistStore(store);
  }, [store]);

  if (store === undefined || persistor === undefined || uiConfig === undefined)
    return null;

  if (!organization) return <GenericError></GenericError>;

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes>
          <Route
            path={HOME_PATH}
            element={
              <App initialConfig={uiConfig} organization={organization} />
            }
          >
            <Route path={PLACEMENT_PATH} element={null} />
          </Route>
        </Routes>
      </PersistGate>
    </Provider>
  );
};
