import { UIConfig } from "../../types/UIConfig";
import { ComponentSingleStyleConfig } from "@chakra-ui/theme";

export const getMobileButton = (
  config: UIConfig
): ComponentSingleStyleConfig => ({
  baseStyle: {
    border: "2px solid",
    borderColor: "brand.500",
    color: "white",
    borderRadius: "8px",
    fontSize: "20px",
    lineHeight: "20px",
    minWidth: "40px",
    minHeight: "40px",
    textAlign: "center",
  },
  variants: {
    filled: {
      backgroundColor: "brand.500",
    },
    outlined: {},
    text: {},
  },
  defaultProps: {
    variant: "outlined",
  },
});
