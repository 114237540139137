import * as React from "react";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  LinkBox,
  LinkOverlay,
  Box,
  Flex,
  Text,
  Image,
  Alert,
  AlertIcon,
  AlertDescription,
  Tag,
  TagLabel,
  TagLeftIcon,
} from "@chakra-ui/react";
import { placementPath } from "../services/urlBuilder";
import { usePlacementData } from "../hooks/usePlacementData";
import {
  clearHighlightedPlacement,
  setHighlightedPlacement,
} from "../store/highlightedPlacement";
import { RootState } from "../store";
import { useColors } from "../hooks/useColors";
import { FormattedMessage } from "react-intl";
import { SavePlacementButton } from "./SavePlacementButton";
import { FormattedDistanceToPlacement } from "./FormattedDistanceToPlacement";
import { ContentItem } from "../types/UIConfig";
import { thumbnailUrl } from "../services/urlBuilder";
import noSchoolImg from "../images/no-photo-tn.jpg";
import { useFocusStyle } from "../hooks/useFocusStyle";
import { BoundaryTag } from "./BoundaryTag";
import { PlacementId } from "../types/Placement";
import { useOrganization } from "../hooks/useOrganization";
import { RiStarLine } from "react-icons/ri";

interface Props {
  placementId: string;
  onClick: (
    placementId: PlacementId,
    position: google.maps.LatLngLiteral
  ) => void;
  style: React.CSSProperties;
}

export const PlacementCard = ({
  placementId,
  onClick,
  style,
}: Props): React.ReactElement | null => {
  const dispatch = useDispatch();
  const location = useLocation();
  const organization = useOrganization();
  const focusStyle = useFocusStyle();
  const { placement, isElibilityEnabled, getPlacementStringValue } =
    usePlacementData(placementId);

  const distanceToPlacementById = useSelector(
    (state: RootState) => state.distance.distanceToPlacementById
  );
  const config = useSelector((state: RootState) => state.config);

  const params = useParams();
  const { primary, primary100 } = useColors();

  const isSelected = params.placementId === placementId;

  if (config.state !== "finished") return null;
  const continueButtonText =
    config?.values?.eligibility?.welcomeModal?.continueButtonText ??
    "Check eligibility";

  if (!placement)
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>Something went wrong!</AlertDescription>
      </Alert>
    );

  const placementNameColor = config?.values?.eligibility?.isEnabled
    ? placement.eligibility
      ? "gray.800"
      : "gray.600"
    : "gray.800";
  return (
    <LinkBox
      style={style}
      onMouseEnter={() => dispatch(setHighlightedPlacement(placementId))}
      onMouseLeave={() => dispatch(clearHighlightedPlacement())}
    >
      <Flex
        flexDirection="row"
        position="relative"
        paddingY="2"
        backgroundColor={isSelected ? primary100 : "white"}
        height="100%"
        borderBottom="2px solid"
        borderColor="gray.100"
        borderLeft="6px solid"
        borderLeftColor={isSelected ? primary : "gray.100"}
        align="center"
        _hover={{
          backgroundColor: isSelected ? "" : "gray.100",
        }}
        zIndex={isSelected ? 0 : 1}
        gridGap={2}
      >
        <Flex
          direction="column"
          flexGrow={1}
          margin={0}
          marginLeft={{ base: 2, lg: 4 }}
          alignItems="flex-start"
        >
          <LinkOverlay
            onClick={() =>
              onClick(placementId, { lat: placement.lat, lng: placement.lng })
            }
            as={RouterLink}
            to={{
              ...location,
              pathname: placementPath(placementId),
            }}
            sx={focusStyle}
          >
            <Flex gap={2} flexWrap="wrap" mb={1}>
              <Text
                as="span"
                fontSize="md"
                letterSpacing="md"
                textColor={placementNameColor}
              >
                {placement.name}
              </Text>
              {isElibilityEnabled && placement.eligibility ? (
                <Tag
                  size="sm"
                  key={placement.id}
                  variant="outline"
                  colorScheme="green"
                >
                  <TagLeftIcon boxSize="12px" as={RiStarLine} />
                  <TagLabel>Eligible</TagLabel>
                </Tag>
              ) : (
                <></>
              )}
            </Flex>
          </LinkOverlay>
          <Box
            fontSize="sm"
            display="flex"
            fontWeight={600}
            flexDirection="row"
            flexWrap="wrap"
            alignItems="flex-start"
          >
            <Box as="span" marginRight={3}>
              {config.values.list.highlighted
                .map((item: ContentItem) => getPlacementStringValue(item))
                .join(" | ")}
            </Box>
            {distanceToPlacementById[placementId] && (
              <Box>
                <Box as="span" marginRight={1} fontWeight={400}>
                  <FormattedMessage id="DISTANCE" />:
                </Box>
                <Box as="span" marginRight={1}>
                  <FormattedDistanceToPlacement id={placementId} />
                </Box>
              </Box>
            )}
          </Box>
          {placement && <BoundaryTag placement={placement} />}
        </Flex>
        <Image
          src={thumbnailUrl(organization?.path || "", placementId)}
          alt={placement?.name}
          fallbackSrc={noSchoolImg}
          position="relative"
          objectFit="cover"
          height="96px"
          width="96px"
        />
        <Box
          position="absolute"
          right={0}
          top={0}
          alignSelf="baseline"
          marginTop="0"
          marginRight="1"
        >
          <SavePlacementButton
            continueButtonText={continueButtonText}
            placementId={placementId}
            boxShadow="0px 2px 2px rgba(0, 0, 0, 0.15)"
          />
        </Box>
      </Flex>
    </LinkBox>
  );
};
