import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { OverlayButton } from "./OverlayButton";
import { setBounds } from "../store/latLngBounds";
import {
  useInlineSizeObserver,
  useInlineSizeObserverForElement,
} from "../hooks/useInlineSizeObserver";
import { FormattedMessage } from "react-intl";

interface RedoSearchButtonProps {
  shown: boolean;
  onClick: () => void;
  map: google.maps.Map;
  profilePanelWidth?: number;
}

export const RedoSearchButton = (props: RedoSearchButtonProps) => {
  const config = useSelector((state: RootState) => state.config);
  const placements = useSelector((state: RootState) => state.placements);
  const dispatch = useDispatch();
  const [mapWidth, setMapWidth] = React.useState<number>();
  const [buttonWidth, setButtonWidth] = React.useState<number>();

  const handleRedoSearch = () => {
    if (placements.status !== "finished" || config.state !== "finished") return;

    const bounds = props.map.getBounds()?.toJSON();
    if (bounds) {
      dispatch(setBounds(bounds));
    }

    props.onClick();
  };

  const { ref: buttonRef } = useInlineSizeObserver(setButtonWidth);
  useInlineSizeObserverForElement(props.map.getDiv(), setMapWidth);

  const positionX = React.useMemo(() => {
    if (buttonWidth && mapWidth) {
      const profilePanelWidth = props.profilePanelWidth || 0;
      return (
        (mapWidth - profilePanelWidth) / 2 - buttonWidth / 2 + profilePanelWidth
      );
    }
  }, [buttonWidth, mapWidth, props.profilePanelWidth]);

  return (
    <OverlayButton
      ref={buttonRef}
      visibility={props.shown && positionX !== undefined ? "visible" : "hidden"}
      onClick={handleRedoSearch}
      position="absolute"
      top={{ base: "24px", lg: "initial" }}
      bottom={{ lg: "16px" }}
      left={positionX ? `${positionX}px` : undefined}
    >
      <FormattedMessage id="REDO_SEARCH" />
    </OverlayButton>
  );
};
