export const homePath = (): string => "/";
export const HOME_PATH = "/";

export const placementPath = (place: string): string => `/${place}`;

export const PLACEMENT_PATH = "/:placementId";

export function imageUrl(clientID: string, placementId: string): string {
  return `${process.env.REACT_APP_BUCKET_URL}/output/${clientID}/images/${placementId}.jpg`;
}

export function thumbnailUrl(clientID: string, placementId: string): string {
  return `${process.env.REACT_APP_BUCKET_URL}/output/${clientID}/images/${placementId}-tn.jpg`;
}
