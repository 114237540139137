import React from "react";
import { Box, Flex, IconButton, useStyleConfig } from "@chakra-ui/react";

interface MobileButtonProps {
  onClick: () => void;
  label: string;
  variant?: "filled" | "outlined" | "text";
  icon?: React.ReactElement;
  indicator?: boolean;
}

export const MobileButton = (props: MobileButtonProps) => {
  const { onClick, label, variant, icon, indicator = false } = props;
  const styles = useStyleConfig("MobileButton", { variant });

  return (
    <Flex alignContent="top" justifyContent="center" position="relative">
      <IconButton
        __css={styles}
        icon={icon}
        aria-label={label}
        onClick={onClick}
        color="white"
        borderColor="white"
      />
      {indicator && (
        <Box
          borderRadius="5px"
          width="10px"
          height="10px"
          position="absolute"
          top="-2px"
          right="-2px"
          boxShadow="0px 2px 2px rgb(0,0,0,0.15)"
          margin="0 0 2px 2px"
        ></Box>
      )}
    </Flex>
  );
};
