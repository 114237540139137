import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { parse } from "bcp-47";
import { Language } from "../types/Language";

interface Lang {
  locale: Language;
}

type LangSliceState = Lang;

const initialState = (): Lang => {
  const locale = parse(navigator.language)?.language || "en";
  return {
    locale,
  } as LangSliceState;
};

const langSlice = createSlice({
  name: "lang",
  initialState: initialState(),
  reducers: {
    setLang(state, action: PayloadAction<Language>) {
      state.locale = action.payload;
    },
  },
});

export const { setLang } = langSlice.actions;
export default langSlice.reducer;
