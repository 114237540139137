import {
  ComponentStyleConfig,
  extendTheme,
  ThemeOverride,
} from "@chakra-ui/react";
import { getMobileButton } from "../components/MobileButton/styleConfig";
import { getDesktopButton } from "../components/DesktopButton/styleConfig";
import { UIConfig } from "../types/UIConfig";
import { getPrimaryColor, getSecondaryColor } from "./colors";
import { applyFocusStyle, getFocusStyle } from "./focusStyle";

export const buildTheme = (config: UIConfig) => {
  const focusStyle = getFocusStyle(config);
  const focusStyleOffset = getFocusStyle(config, {
    position: "offset",
  });
  const theme: ThemeOverride = {
    components: {
      Button: {
        baseStyle: focusStyle,
        ...getButton(config),
      },
      MobileButton: applyFocusStyle(config, getMobileButton(config), {
        position: "offset",
        color: "primary",
      }),
      DesktopButton: applyFocusStyle(config, getDesktopButton(config), {
        position: "offset",
        color: "primary",
      }),
      CloseButton: { baseStyle: focusStyle },
      IconButton: { baseStyle: focusStyle },
      Slider: getSlider(config),
      Link: { baseStyle: focusStyleOffset },
      Input: {
        parts: ["field"],
        baseStyle: {
          field: focusStyle,
        },
      },
      Menu: {
        parts: ["button"],
        baseStyle: {
          button: focusStyle,
        },
      },
      Tabs: {
        parts: ["tab"],
        baseStyle: {
          tab: getFocusStyle(config, {
            position: "inside",
          }),
        },
      },
    },
    fonts: {
      heading: "Open Sans,arial,sans-serif",
      body: "Open Sans,arial,sans-serif",
    },
    fontSizes: {
      xs: "12px",
      sm: "14px",
      md: "16px",
      lg: "20px",
      xl: "24px",
      "2xl": "30px",
      "3xl": "38px",
      "4xl": "46px",
      "5xl": "56px",
    },
    letterSpacings: {
      xs: 0,
      sm: "0.1px",
      md: "0.3px",
      xl: "0.9px",
    },
    fontWeights: {
      normal: 400,
      semibold: 600,
      bold: 700,
    },
    colors: {
      primary: { 500: getPrimaryColor(config) },
      secondary: { 500: getSecondaryColor(config) },
      brand: {
        500: getPrimaryColor(config),
      },
      gray: {
        100: "#F4F4F4",
        300: "#E4E4E4",
        500: "#C7C7C7",
        700: "#838383",
        900: "#3F3F3F",
      },
    },
    config: {
      initialColorMode: "light",
      useSystemColorMode: false,
    },
  };

  return extendTheme(theme);
};

function getSlider(config: UIConfig) {
  const primaryColor = getPrimaryColor(config);
  const focusStyle = getFocusStyle(config, { position: "offset" });
  return {
    baseStyle: {
      filledTrack: {
        bg: primaryColor,
      },
      thumb: {
        bg: primaryColor,
        border: "1px solid black",
        borderColor: "black",
        ...focusStyle,
      },
    },
  };
}

function getButton(config: UIConfig): ComponentStyleConfig {
  const primary = getPrimaryColor(config);
  const secondary = getSecondaryColor(config);
  const secondaryStyle = {
    bg: secondary,
    color: "white",
  };
  const primaryStyle = {
    bg: primary,
    color: "white",
  };
  return {
    variants: {
      primary: {
        ...primaryStyle,
        _hover: {
          ...primaryStyle,
          _disabled: {
            ...primaryStyle,
          },
        },
        _disabled: {
          ...primaryStyle,
          paddingX: 5,
        },
      },
      secondary: {
        ...secondaryStyle,
        _hover: {
          ...secondaryStyle,
          _disabled: {
            ...secondaryStyle,
          },
        },
        _disabled: {
          ...secondaryStyle,
          paddingX: 5,
        },
      },
    },
  };
}
