import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Placement } from "../types/Placement";
import { filterAndSort } from "../services/filterResults";
import { useBoundaryTagsByPlacementId } from "./useBoundary";

function searchIsInitializing(search: any) {
  return (
    search.placements.isSearching &&
    search.placements.text === "" &&
    search.placements.result.length === 0
  );
}
export const usePlacementResults = (): Placement[] | null => {
  const placements = useSelector((state: RootState) => state.placements);
  const config = useSelector((state: RootState) => state.config);
  const filter = useSelector((state: RootState) => state.filter);
  const search: any = useSelector((state: RootState) => state.search);
  const sort = useSelector((state: RootState) => state.sort);
  const distance = useSelector((state: RootState) => state.distance);
  const latLngBounds = useSelector((state: RootState) => state.latLngBounds);
  const boundaryTagsByPlacementId = useBoundaryTagsByPlacementId(
    placements.status === "finished" ? placements.byId : undefined
  );

  const placementResults = useMemo(() => {
    if (placements.status !== "finished" || config.state !== "finished")
      return null;

    const searchResultPlacementIds: string[] = searchIsInitializing(search)
      ? Object.keys(placements.byId)
      : search.placements.result;

    const placementsList = searchResultPlacementIds
      .map((id) => {
        return placements.byId[id];
      })
      .filter((id) => id !== undefined);

    const filtersList = Object.keys(filter.byFieldName).map(
      (fieldName) => filter.byFieldName[fieldName]
    );

    return filterAndSort(
      placementsList,
      config.values,
      filtersList,
      sort.type,
      distance.distanceToPlacementById,
      latLngBounds.bounds,
      boundaryTagsByPlacementId
    );
  }, [
    placements,
    config,
    filter,
    search,
    distance,
    sort,
    latLngBounds,
    boundaryTagsByPlacementId,
  ]);

  return placementResults;
};
