import { ConfigurableLink, UIConfig } from "../types/UIConfig";
import Mustache from "mustache";
import strict_uri_encode from "strict-uri-encode";

const TOKEN = "avlplacementIdsCommaSeparated";

export function applyNow(
  config: UIConfig,
  placementIds: string[]
): ConfigurableLink | undefined {
  const applyNowButton = config.savedList?.applyNowButton;

  if (!applyNowButton) return undefined;

  const hasToken = applyNowButton.href.indexOf(`{{${TOKEN}}}`) > 0;
  if (hasToken && placementIds.length === 0) return undefined;

  const idsCommaSeparated = placementIds
    .map((id) => strict_uri_encode(id))
    .join(",");

  const forwardSavedSchools: boolean =
    !!config.savedList?.applyNowButton?.forwardSavedSchools;

  const href = forwardSavedSchools
    ? Mustache.render(`${applyNowButton.href}?${TOKEN}={{TOKEN}}`, {
        TOKEN: idsCommaSeparated,
      })
    : Mustache.render(`${applyNowButton.href}`, {});

  return {
    ...applyNowButton,
    href: href,
  };
}
