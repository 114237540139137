import { UIConfig } from "../types/UIConfig";

export const getPlacementIndices = (uiConfig: UIConfig) => {
  const fields = new Set();
  const { placement, filters, profile, list } = uiConfig;

  fields.add(placement.nameFieldName);

  filters?.forEach((filter) => {
    if (filter.type === "multiple") {
      if (filter.fieldName) {
        fields.add(filter.fieldName);
      }
    }
  });

  profile.highlighted.forEach((item) => {
    if (item.contentType === "field" || item.contentType === undefined)
      fields.add(item.fieldName);
  });

  profile.keyInfo.forEach((item) => {
    if (item.contentType === "field" || item.contentType === undefined)
      fields.add(item.fieldName);
  });

  profile.tabs.forEach((tab) => {
    tab.items.forEach((item) => {
      if (item.contentType === "field" || item.contentType === undefined)
        fields.add(item.fieldName);
    });
  });

  list.highlighted.forEach((item) => {
    if (item.contentType === "field" || item.contentType === undefined)
      fields.add(item.fieldName);
  });

  return Array.from(fields);
};
