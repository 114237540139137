import * as React from "react";
import { FlexProps } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { sendSms } from "../../../store/messaging";
import { useConfig } from "../../../hooks/useConfig";
import { MessageForm } from "./MessageForm";
import { useIntl } from "react-intl";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";
import { InputText } from "../../../components/InputText";
import { MessageItem } from "../../../types/Message";
import { onSmsAnalytics } from "../../../services/analytics";
import { useOrganization } from "../../../hooks/useOrganization";

export function SmsForm(props: FlexProps) {
  const { messaging } = useSelector((state: RootState) => state);
  const savedIds = useSelector((state: RootState) => state.saved.ids);
  const config = useConfig();
  const dispatch = useDispatch();
  const { locale } = useIntl();
  const [destination, setDestination] = React.useState<string>("");
  const intl = useIntl();
  const organization = useOrganization();
  const baseURL = document.location.origin;

  const handleSubmit = React.useCallback(
    (items: MessageItem[]) => {
      if (!config) return;
      dispatch(
        sendSms({
          to: destination,
          items,
          locale,
          organization_path: organization?.path || "",
          base_url: baseURL,
        })
      );
      onSmsAnalytics({ savedIds: savedIds.join(",") });
    },
    [config, destination, dispatch, locale, savedIds, organization, baseURL]
  );

  const isValid = React.useMemo(() => {
    try {
      return isValidPhoneNumber(destination) && savedIds.length > 0;
    } catch {
      return false;
    }
  }, [destination, savedIds]);

  return (
    <MessageForm
      buttonLabel={"SEND"}
      buttonLoadingText={"SENDING"}
      successMessage={"SMS_SUCCESS"}
      errorMessage={"SMS_FAILURE"}
      message={messaging.sms}
      {...props}
      onSubmit={handleSubmit}
      isValid={isValid}
    >
      <InputText
        as={PhoneInput}
        country="US"
        type="tel"
        label={intl.formatMessage({ id: "PHONE_NUMBER" })}
        placeholder={intl.formatMessage({ id: "PHONE_NUMBER" })}
        background="white"
        autoFocus={true}
        leftAddon="+1"
        onChange={(value: any) => {
          setDestination(value);
        }}
        value={destination}
      />
    </MessageForm>
  );
}
