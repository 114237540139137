import { Cluster } from "../services/map";
import { Placement } from "../types/Placement";
import { CustomMarker } from "./CustomMarker";
import { useSelector } from "react-redux";
import { RootState } from "../store";

export type Props = {
  cluster: Cluster<Placement>;
  onClick: (cluster: Cluster<Placement>) => void;
};

export const ClusteredPlacementMarker = ({ onClick, cluster }: Props) => {
  const position: google.maps.LatLngLiteral = {
    lat: cluster.coordinate.lat,
    lng: cluster.coordinate.lng,
  };
  const highlightedPlacementId = useSelector(
    (state: RootState) => state.highlightedPlacement.highlightedPlacementId
  );
  const key = cluster.coordinates[0]?.id;
  const isHighlighted =
    !!highlightedPlacementId &&
    cluster.coordinates?.find((item) => item.id === highlightedPlacementId) !==
      undefined;
  const numPlacements = cluster.coordinates.length;

  return (
    <>
      <CustomMarker
        key={key}
        cluster={cluster}
        markerType="cluster"
        label={`${numPlacements}`}
        position={position}
        isHighlighted={isHighlighted}
        onClick={() => onClick(cluster)}
      />
    </>
  );
};
