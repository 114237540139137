import React from "react";
import { useDispatch } from "react-redux";
import { useConfig } from "../../../hooks/useConfig";
import {
  clearAddress,
  setAddress,
  setAddressLoading,
} from "../../../store/address";
import { setSortType, SortTypeEnum } from "../../../store/sort";

interface GMapAddressProps {
  map: google.maps.Map | undefined;
  placeId: string | undefined;
}

export const GMapPlaceAddress = ({ map, placeId }: GMapAddressProps): null => {
  const dispatch = useDispatch();
  const config = useConfig();

  React.useEffect(() => {
    if (map && !placeId) dispatch(clearAddress());
    if (!map || !placeId) return;

    const placeService = new google.maps.places.PlacesService(map);
    dispatch(setAddressLoading());
    placeService.getDetails(
      {
        placeId: placeId.toString(),
        fields: ["place_id", "formatted_address", "geometry"],
      },
      (place, status) => {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          place?.place_id &&
          place?.formatted_address &&
          place?.geometry?.location
        ) {
          dispatch(
            setAddress({
              googlePlaceId: place.place_id,
              formattedText: place.formatted_address,
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            })
          );
          const nextSortType = config?.boundary?.sort
            ? SortTypeEnum.BOUNDARY
            : SortTypeEnum.DISTANCE;

          dispatch(setSortType(nextSortType));
        }
      }
    );
  }, [dispatch, map, placeId, config?.boundary?.sort]);

  return null;
};
