import { Flex, Image, Text } from "@chakra-ui/react";
import { useConfig } from "../../hooks/useConfig";
import eligibilityError from "../../images/eligibility-error.svg";

export function EligibilityError() {
  const config = useConfig();
  const header =
    config?.eligibility?.error?.header ?? "Unable to check eligibility";
  const body =
    config?.eligibility?.error?.body ??
    "Our engines are not powering up. Try again later.";
  return (
    <Flex
      top="0"
      left="0"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      direction="column"
      gap={2}
      mb={4}
    >
      <Image width={140} src={eligibilityError} />
      <Text fontWeight="bold">{header}</Text>
      <Text textAlign="center">{body}</Text>
    </Flex>
  );
}
