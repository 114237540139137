import { createSlice } from "@reduxjs/toolkit";

type MiscPersistedSliceType = { disclaimerOpen: boolean };
const initialState: MiscPersistedSliceType = {
  disclaimerOpen: true,
} as MiscPersistedSliceType;

const miscPersistedSlice = createSlice({
  name: "miscPersisted",
  initialState,
  reducers: {
    openDisclaimer: (state) => {
      state.disclaimerOpen = true;
    },
    closeDisclaimer: (state) => {
      state.disclaimerOpen = false;
    },
  },
});

export const { openDisclaimer, closeDisclaimer } = miscPersistedSlice.actions;
export default miscPersistedSlice.reducer;
