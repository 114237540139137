export type NotAsked = { type: "notAsked" };
export type Loading = { type: "loading" };
export type Failure<E> = { type: "error"; error: E };
export type Success<T> = { type: "success"; data: T };

export type RemoteData<E, T> = NotAsked | Loading | Failure<E> | Success<T>;

export const notAsked: NotAsked = { type: "notAsked" };
export const loading: Loading = { type: "loading" };
export function failure<E>(error: E): Failure<E> {
  return { type: "error", error };
}
export function failureFromUnknown(error: unknown): Failure<Error> {
  if (error instanceof Error) {
    return { type: "error", error };
  }
  return { type: "error", error: new Error("Unknown error", { cause: error }) };
}
export function success<T>(data: T): Success<T> {
  return { type: "success", data };
}

export function isSuccess<E, T>(
  remoteData: RemoteData<E, T>
): remoteData is Success<T> {
  return remoteData.type === "success";
}

export function isFailure<E, T>(
  remoteData: RemoteData<E, T>
): remoteData is Failure<E> {
  return remoteData.type === "error";
}

export function isLoading<E, T>(
  remoteData: RemoteData<E, T>
): remoteData is Loading {
  return remoteData.type === "loading";
}

export function andThen<E, T1, T2>(
  remoteData: RemoteData<E, T1>,
  f: (data: T1) => RemoteData<E, T2>
): RemoteData<E, T2> {
  switch (remoteData.type) {
    case "notAsked":
      return notAsked;
    case "loading":
      return loading;
    case "error":
      return remoteData;
    case "success":
      return f(remoteData.data);
  }
}

export function map<E, T1, T2>(
  remoteData: RemoteData<E, T1>,
  f: (data: T1) => T2
): RemoteData<E, T2> {
  switch (remoteData.type) {
    case "notAsked":
      return notAsked;
    case "loading":
      return loading;
    case "error":
      return remoteData;
    case "success":
      return success(f(remoteData.data));
  }
}

export function map2<E, T1, T2, T3>(
  a: RemoteData<E, T1>,
  b: RemoteData<E, T2>,
  f: (a: T1, b: T2) => T3
): RemoteData<E, T3> {
  if (a.type === "error") {
    return failure(a.error);
  }

  if (b.type === "error") {
    return failure(b.error);
  }

  if (a.type === "loading" || b.type === "loading") {
    return loading;
  }

  if (a.type === "notAsked" || b.type === "notAsked") {
    return notAsked;
  }

  return success(f(a.data, b.data));
}

export function toTuple<E, T1, T2>(
  a: RemoteData<E, T1>,
  b: RemoteData<E, T2>
): RemoteData<E, [T1, T2]> {
  return map2(a, b, (dataA, dataB) => [dataA, dataB]);
}

export function isRemoteData<E, T>(value: unknown): value is RemoteData<E, T> {
  if (typeof value !== "object" || value === null) {
    return false;
  }

  const remoteData = value as RemoteData<E, T>;

  switch (remoteData.type) {
    case "notAsked":
    case "loading":
      return true;
    case "error":
      return "error" in remoteData;
    case "success":
      return "data" in remoteData;
    default:
      return false;
  }
}

export function withDefault<E, T>(
  remoteData: RemoteData<E, T>,
  defaultValue: T
): T {
  if (isSuccess(remoteData)) {
    return remoteData.data;
  }

  return defaultValue;
}
