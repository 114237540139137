import * as React from "react";
import { useBreakpointValue } from "../../hooks/useBreakpointValue";

interface Props {
  base: React.ReactElement;
  lg?: React.ReactElement;
}

export const ResponsiveLayout = ({
  base,
  lg,
}: Props): React.ReactElement | null => {
  const { isLargeBreakpoint, isBaseBreakpoint } = useBreakpointValue();

  if (isLargeBreakpoint) {
    return lg ?? base;
  } else if (isBaseBreakpoint) {
    return base;
  }

  return null;
};
