export const url = (
  fill: string | undefined,
  width: number,
  height: number,
  alpha: number
) =>
  `data:image/svg+xml;base64,${window.btoa(svg(fill, width, height, alpha))}`;

export const svg = (
  fill: string | undefined,
  width: number,
  height: number,
  alpha: number
) => `
<svg width="${width}" height="${height}" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39.6763 18.9879C39.1892 21.503 37.9007 24.2895 36.1841 27.0807C34.4724 29.8639 32.3613 32.6098 30.2718 35.0381C28.183 37.4654 26.1258 39.5637 24.5293 41.0512C23.7301 41.7958 23.0536 42.3805 22.5514 42.7747C22.3207 42.9558 22.1365 43.0889 22 43.1772C21.8636 43.0889 21.6794 42.9558 21.4487 42.7747C20.9465 42.3805 20.27 41.7958 19.4708 41.0512C17.8743 39.5637 15.8171 37.4654 13.7283 35.0381C11.6388 32.6098 9.52767 29.8639 7.81598 27.0807C6.09938 24.2895 4.81084 21.503 4.32382 18.9879C2.39439 9.02357 11.0204 0.75 22 0.75C32.9797 0.75 41.6057 9.02357 39.6763 18.9879ZM21.8197 43.2803C21.8197 43.2802 21.8237 43.2783 21.8313 43.2757C21.8235 43.2792 21.8198 43.2805 21.8197 43.2803Z" ${
  fill ? `fill="${fill}"` : ""
} fill-opacity="${alpha}" stroke="#fff" stroke-width="1.5" />
</svg>`;
