import ReactGA from "react-ga4";

const AVELA_MEASUREMENT_ID = "G-R2ZF8Z19NQ";

export const handleError = (error: any) => {
  console.log("Analytics error", error);
};

export const initializeAnalytics = (clientId: string | void) => {
  try {
    const accounts = [{ trackingId: AVELA_MEASUREMENT_ID }];

    if (clientId) {
      accounts.push({ trackingId: clientId });
    }

    ReactGA.initialize(accounts);

    ReactGA.send("pageview");
  } catch (error) {
    handleError(error);
  }
};

export const onVisitProfileAnalytics = (options: {
  pathname: string;
}): void => {
  try {
    ReactGA.send({ hitType: "pageview", page: options.pathname });
  } catch (error) {
    handleError(error);
  }
};

export const onChangeResultsAnalytics = (options: {
  numResults: number;
}): void => {
  try {
    ReactGA.event("onChangeResults", options);
  } catch (error) {
    handleError(error);
  }
};

export const onSearchAnalytics = (options: { query: string }): void => {
  try {
    ReactGA.event("onSearch", options);
  } catch (error) {
    handleError(error);
  }
};

export const onFilterChangedAnalytics = (options: {
  selectedFilters: string;
}): void => {
  try {
    ReactGA.event("onChangeFilter", options);
  } catch (error) {
    handleError(error);
  }
};

export const onChangeSavedListAnalytics = (options: {
  savedIds: string;
}): void => {
  try {
    ReactGA.event("onChangeSavedList", options);
  } catch (error) {
    handleError(error);
  }
};

export const onEmailAnalytics = (options: { savedIds: string }): void => {
  try {
    ReactGA.event("onEmail", options);
  } catch (error) {
    handleError(error);
  }
};

export const onSmsAnalytics = (options: { savedIds: string }): void => {
  try {
    ReactGA.event("onText", options);
  } catch (error) {
    handleError(error);
  }
};

export const onApplyAnalytics = (options: { savedIds: string }): void => {
  try {
    ReactGA.event("onClickApply", options);
  } catch (error) {
    handleError(error);
  }
};

export const onSelectAddressAnalytics = (): void => {
  try {
    ReactGA.event("onSelectAddress");
  } catch (error) {
    handleError(error);
  }
};
