import { Flex, Link, Text } from "@chakra-ui/layout";
import { Fade } from "@chakra-ui/transition";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SubtleAlert } from "../../components/SubtleAlert";
import { useConfig } from "../../hooks/useConfig";
import { RootState } from "../../store";
import { closeDisclaimer, openDisclaimer } from "../../store/miscPersisted";

export const Footer = (): React.ReactElement => {
  const disclaimerOpen = useSelector(
    (state: RootState) => state.miscPersisted.disclaimerOpen
  );
  const config = useConfig();
  const dispatch = useDispatch();

  const disclaimer = config?.list?.disclaimer;

  const handleClickDisclaimer = () => {
    if (disclaimerOpen) {
      dispatch(closeDisclaimer());
    } else {
      dispatch(openDisclaimer());
    }
  };

  return (
    <>
      {disclaimer && disclaimerOpen && (
        <Fade in={disclaimerOpen}>
          <SubtleAlert
            content={disclaimer.value}
            onClose={handleClickDisclaimer}
          />
        </Fade>
      )}

      <Flex
        height="32px"
        width="100%"
        justify="right"
        align="center"
        backgroundColor="gray.100"
        fontSize="xs"
        gridGap={2}
        paddingRight={4}
        flexShrink={0}
      >
        {disclaimer && (
          <>
            <Link
              _hover={{
                textDecoration: "none",
              }}
              onClick={handleClickDisclaimer}
              href="#"
            >
              {disclaimer.label}
            </Link>
            <Text>|</Text>
          </>
        )}
        <Link
          href="https://avela.org/terms"
          isExternal
          _hover={{
            textDecoration: "none",
          }}
        >
          <FormattedMessage id="TERMS_OF_SERVICE" />
        </Link>
        <Text>|</Text>
        <Link
          href="https://avela.org"
          isExternal
          _hover={{
            textDecoration: "none",
          }}
        >
          <FormattedMessage id="POWERED_BY_AVELA" />
        </Link>
      </Flex>
    </>
  );
};
