import { SystemStyleObject } from "@chakra-ui/react";
import { getFocusStyle, Variant } from "../services/focusStyle";
import { useConfig } from "./useConfig";

export function useFocusStyle(variant?: Variant): SystemStyleObject {
  const config = useConfig();
  if (config === undefined) {
    return {};
  }
  return getFocusStyle(config, variant);
}
