import React from "react";
import { Button, ButtonProps, forwardRef } from "@chakra-ui/react";
import { useFocusStyle } from "../hooks/useFocusStyle";

type OverlayButtonProps = {
  children: React.ReactNode;
} & ButtonProps;

export const OverlayButton = forwardRef(
  (
    { children, ...buttonProps }: OverlayButtonProps,
    ref
  ): React.ReactElement => {
    const focusStyle = useFocusStyle({ position: "offset" });
    return (
      <Button
        ref={ref}
        {...buttonProps}
        backgroundColor="blackAlpha.800"
        color="white"
        fontSize="sm"
        fontWeight="semibold"
        size="sm"
        borderRadius="16px"
        _active={{ backgroundColor: "blackAlpha.800" }}
        _hover={{ backgroundColor: "blackAlpha.800" }}
        sx={focusStyle}
      >
        {children}
      </Button>
    );
  }
);
