import * as geojson from "geojson";
import { BoundaryConfig } from "../types/UIConfig";
import { Boundary } from "../types/Boundary";
import { BoundaryMap } from "../store/boundary";

export function coordinatesToPaths(
  coordinates: geojson.Position[][]
): google.maps.LatLngLiteral[][] {
  return coordinates.map(positionsToPath);
}

export function positionsToPath(
  positions: geojson.Position[]
): google.maps.LatLngLiteral[] {
  return positions.map(positionToPath);
}

export function positionToPath(
  position: geojson.Position
): google.maps.LatLngLiteral {
  return { lng: position[0], lat: position[1] };
}

export function getColorByFeature(
  config: BoundaryConfig,
  feature: geojson.Feature
): string | null {
  if (feature.properties === null) return null;
  const id = getId(config, feature);
  if (!id) return null;
  return getColorById(config, id);
}

export function getColorById(
  config: BoundaryConfig,
  id: string
): string | null {
  if (config.style === undefined) return null;
  return config.style[id]?.color;
}

export function getId(
  config: BoundaryConfig,
  feature: geojson.Feature
): string | null {
  if (feature.properties === null) return null;
  return feature.properties[config.idProperty];
}

export function featureToBoundary(
  config: BoundaryConfig,
  feature: geojson.Feature
): Boundary | null {
  const polygon = geometryToPolygon(feature.geometry);
  const id = getId(config, feature);

  if (id === null || polygon === null) return null;
  return {
    id,
    boundaryType: config.boundaryType,
    polygon,
    showAllBoundaries: config.showAllBoundaries,
    showMapLabel: config.showMapLabel === true,
    showAddressBoundary: config.showAddressBoundary,
    showSelectedPlacementBoundary: config.showSelectedPlacementBoundary,
  };
}

export function geoJSONToBoundaries(
  config: BoundaryConfig,
  geoJSON: geojson.GeoJSON
): Boundary[] | null {
  switch (geoJSON.type) {
    case "FeatureCollection": {
      return geoJSON.features.flatMap(
        (feature) => featureToBoundary(config, feature) ?? []
      );
    }
  }

  console.error(`Unsupported GeoJSON type: ${geoJSON.type}`);
  return null;
}

export function geometryToPolygon(
  geometry: geojson.Geometry
): google.maps.Polygon | null {
  if (geometry.type !== "Polygon") {
    console.error(`Unsupported geometry type: ${geometry.type}`);
    return null;
  }

  return new google.maps.Polygon({
    paths: coordinatesToPaths(geometry.coordinates),
  });
}

export function findBoundaryForAddress(
  position: google.maps.LatLngLiteral,
  boundaryMap: BoundaryMap,
  configs: BoundaryConfig[]
): Boundary[] {
  const boundaries: (Boundary | null)[] = configs.map((config) => {
    const geojson = boundaryMap[config.boundaryType];
    if (geojson === undefined) {
      return null;
    }
    if (geojson.type !== "FeatureCollection") {
      console.error(`Unsupported GeoJSON type: ${geojson.type}`);
      return null;
    }
    if (google.maps?.geometry?.poly === undefined) {
      console.error("google maps library is not fully loaded yet");
      return null;
    }

    const result = geojson.features
      .map((feature) => ({
        id: getId(config, feature),
        polygon: geometryToPolygon(feature.geometry),
      }))
      .find(({ polygon }) =>
        polygon === null
          ? false
          : google.maps.geometry.poly.containsLocation(position, polygon)
      );

    if (!result || !result.id || !result.polygon) return null;
    return {
      type: "address",
      boundaryType: config.boundaryType,
      id: result.id,
      polygon: result.polygon,
      showAllBoundaries: config.showAllBoundaries,
      showMapLabel: config.showMapLabel === true,
      showAddressBoundary: config.showAddressBoundary,
      showSelectedPlacementBoundary: config.showSelectedPlacementBoundary,
    };
  });
  return boundaries.filter((b: Boundary | null): b is Boundary => b !== null);
}

export function findBoundariesForPlacement(
  boundaryKeys: string[],
  boundaryMap: BoundaryMap,
  configs: BoundaryConfig[]
): Boundary[] | null {
  const boundaries: (Boundary[] | null)[] = configs.map((config) => {
    const geojson = boundaryMap[config.boundaryType];
    if (geojson === undefined) return null;
    if (geojson.type !== "FeatureCollection") {
      console.error(`Unsupported GeoJSON type: ${geojson.type}`);
      return null;
    }

    if (config.showSelectedPlacementBoundary !== true) return null;

    return geojson.features
      .map((feature) => {
        const id = getId(config, feature);
        return { id, feature };
      })
      .filter(({ id }) => {
        return id !== null && boundaryKeys.includes(id);
      })
      .flatMap(({ id, feature }) => {
        const polygon = geometryToPolygon(feature.geometry);
        if (!polygon) return [];

        return {
          id: id as string, // id is not null, proven by line 148
          type: "placement",
          boundaryType: config.boundaryType,
          polygon,
          showAllBoundaries: config.showAllBoundaries,
          showMapLabel: config.showMapLabel === true,
          showAddressBoundary: config.showAddressBoundary,
          showSelectedPlacementBoundary: config.showSelectedPlacementBoundary,
        };
      });
  });
  return boundaries
    .filter((b: Boundary[] | null): b is Boundary[] => b !== null)
    .flat();
}

export function getBoundaryCenter(boundary: Boundary): google.maps.LatLng {
  const bounds = new google.maps.LatLngBounds();
  boundary.polygon.getPaths().forEach((path) => {
    path.forEach((coordinate: google.maps.LatLng) => {
      bounds.extend(coordinate);
    });
  });
  return bounds.getCenter();
}
