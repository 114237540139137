import * as React from "react";
import { Box, Icon, BoxProps, Flex } from "@chakra-ui/react";
import { RiBookmarkFill, RiEqualizerLine, RiSearchLine } from "react-icons/ri";
import { FormattedMessage } from "react-intl";
import { FadeIn } from "../components/animation/FadeIn";
import { AddressSearchBar } from "../components/AddressSearchBar";
import { useBreakpointValue } from "../hooks/useBreakpointValue";
import { MobileButton } from "../components/MobileButton";
import { DesktopButton } from "../components/DesktopButton";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { TopPanel as TopPanelType } from "../store/openedPanel";
import { useColors } from "../hooks/useColors";
import { MotionBox } from "../components/animation/MotionBox";

type Props = {
  isOpen(panel: TopPanelType): boolean;
  toggle(panel: TopPanelType): void;
  map?: google.maps.Map;
} & BoxProps;

export const ControlBar = ({
  toggle,
  isOpen,
  map,
  ...props
}: Props): React.ReactElement | null => {
  const [isFocus, setFocus] = React.useState(false);
  const config = useSelector((state: RootState) => state.config);
  const filter = useSelector((state: RootState) => state.filter);
  const search: any = useSelector((state: RootState) => state.search);
  const { isBaseBreakpoint } = useBreakpointValue();
  const { secondary } = useColors();

  const hideButtons = isBaseBreakpoint && isFocus;
  const hasActiveFilter = Object.keys(filter.byFieldName).length > 0;
  const hasKeyword = search?.placements?.text?.length > 0;

  const renderFilterButton = (
    <FadeIn width="unset" fontSize="md">
      {isBaseBreakpoint ? (
        <MobileButton
          onClick={() => toggle("filter")}
          icon={<Icon as={RiEqualizerLine} fontSize="xl" />}
          label="Filter"
          variant={isOpen("filter") ? "filled" : "outlined"}
          indicator={hasActiveFilter && !isOpen("filter")}
        />
      ) : (
        <DesktopButton
          onClick={() => toggle("filter")}
          icon={<Icon as={RiEqualizerLine} fontSize="xl" />}
          variant={isOpen("filter") ? "filled" : "outlined"}
          indicator={hasActiveFilter && !isOpen("filter")}
        >
          <FormattedMessage id="FILTER" />
        </DesktopButton>
      )}
    </FadeIn>
  );

  const renderSearchButton = (
    <FadeIn width="unset" fontSize="md">
      {isBaseBreakpoint ? (
        <MobileButton
          onClick={() => toggle("search")}
          icon={<Icon as={RiSearchLine} fontSize="xl" />}
          label="Search"
          variant={isOpen("search") ? "filled" : "outlined"}
          indicator={hasKeyword && !isOpen("search")}
        />
      ) : (
        <DesktopButton
          onClick={() => toggle("search")}
          icon={<Icon as={RiSearchLine} fontSize="xl" />}
          variant={isOpen("search") ? "filled" : "outlined"}
          indicator={hasKeyword && !isOpen("search")}
        >
          <FormattedMessage id="KEYWORD" />
        </DesktopButton>
      )}
    </FadeIn>
  );

  const renderSavedButton = (
    <FadeIn width="unset" flexGrow={1} fontSize="md">
      {isBaseBreakpoint ? (
        <MobileButton
          onClick={() => toggle("savedPlacements")}
          icon={<Icon as={RiBookmarkFill} fontSize="xl" />}
          label="Saved"
          variant={isOpen("savedPlacements") ? "filled" : "outlined"}
        />
      ) : (
        <Box alignItems="right" fontSize="md">
          <DesktopButton
            onClick={() => toggle("savedPlacements")}
            icon={<Icon as={RiBookmarkFill} fontSize="xl" />}
            variant={isOpen("savedPlacements") ? "filled" : "outlined"}
          >
            {config.state === "finished" && (
              <FormattedMessage
                id="SAVED_LIST"
                values={{
                  schools: config.values.variableWords.school.plural.label,
                }}
              />
            )}
          </DesktopButton>
        </Box>
      )}
    </FadeIn>
  );

  return (
    <Flex
      paddingX={{ base: 3, lg: 8 }}
      paddingY={{ base: 3 }}
      alignItems="center"
      gridGap="4"
      background={secondary}
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15)"
      {...props}
    >
      <MotionBox width={{ base: "100%" }} maxWidth={{ lg: 420 }}>
        <AddressSearchBar
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          map={map}
        />
      </MotionBox>
      {!hideButtons && (
        <>
          {renderFilterButton}
          {renderSearchButton}
          {renderSavedButton}
        </>
      )}
    </Flex>
  );
};
