import { createSlice } from "@reduxjs/toolkit";

type GoogleMapSliceState = { status: "loading" } | { status: "finished" };
const initialState: GoogleMapSliceState = {
  status: "loading",
} as GoogleMapSliceState;

const googleMapSlice = createSlice({
  name: "googleMap",
  initialState,
  reducers: {
    googleMapLoaded() {
      return { status: "finished" };
    },
  },
});

const { reducer } = googleMapSlice;

export const { googleMapLoaded } = googleMapSlice.actions;

export default reducer;
