import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { dataFileName } from "../services/lang";
import { Language } from "../types/Language";
import { Placement as PlacementType } from "../types/Placement";
import { PlacementMapping } from "../types/UIConfig";
import { fetchConfigHash } from "../services/fetchConfigHash";
import { EligibilitySchool } from "../services/eligibility";
// import { mockData } from "../mockData/data";

const reduxSearchModule = require("redux-search");
const { createSearchAction } = reduxSearchModule;

export interface PlacementMap {
  [key: string]: PlacementType;
}

export type PlacementsSliceType =
  | { status: "loading" }
  | { status: "finished"; byId: PlacementMap; eligibilityEnabled: boolean };

const initialState: PlacementsSliceType = {
  status: "loading",
} as PlacementsSliceType;

interface FetchPlacementsOptions {
  placementMapping: PlacementMapping;
  lang: Language;
  organizationPath: string;
}

export const fetchPlacements = createAsyncThunk(
  "placements/fetch",
  async ({
    placementMapping,
    lang,
    organizationPath,
  }: FetchPlacementsOptions): Promise<PlacementType[]> => {
    const URL = process.env.REACT_APP_BUCKET_URL;
    if (!URL || !organizationPath) return Promise.resolve([]);
    const etag = await fetchConfigHash(organizationPath, dataFileName(lang));
    const endpoint = `${URL}/output/${organizationPath}/${dataFileName(
      lang
    )}?etag=${etag}`;

    return await axios.get(endpoint).then(({ data }) => {
      const placements = (data as unknown[]).map<PlacementType>(
        (datum: any) => {
          const lat = Number(datum[placementMapping.latFieldName]);
          const lng = Number(datum[placementMapping.lngFieldName]);
          return {
            id: datum[placementMapping.idFieldName],
            name: datum[placementMapping.nameFieldName],
            lat,
            lng,
            boundaries: placementMapping.boundaryFieldName
              ? getBoundaries(datum, placementMapping.boundaryFieldName)
              : undefined,
            details: {
              ...datum,
            },
            eligibility: true,
          };
        }
      );
      return placements;
    });
  }
);

function getBoundaries(
  datum: any,
  boundaries: { [boundaryType: string]: string }
): { [boundaryType: string]: string[] } {
  return Object.keys(boundaries).reduce((prev, key) => {
    return { ...prev, [key]: datum[boundaries[key]] };
  }, {});
}

const placementsSlice = createSlice({
  name: "placements",
  initialState,
  reducers: {
    setPlacementsEligibility: (
      state,
      action: PayloadAction<{ ineligibleSchools: EligibilitySchool[] }>
    ) => {
      if (state.status !== "finished") return;

      const ineligibleSchoolIds = new Set(
        action.payload.ineligibleSchools.map((school) => school.referenceId)
      );
      Object.keys(state.byId).forEach((id) => {
        state.byId[id].eligibility = !ineligibleSchoolIds.has(id);
      });

      state.eligibilityEnabled = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlacements.fulfilled, (state, action: any) => {
      const { payload } = action;
      return {
        status: "finished",
        byId: payload.reduce((acc: PlacementMap, cur: PlacementType) => {
          acc[cur.id] = cur;
          return acc;
        }, {}),
        eligibilityEnabled: false,
      };
    });
  },
});

export const searchPlacements = createSearchAction("placements");

const { reducer } = placementsSlice;
export const { setPlacementsEligibility } = placementsSlice.actions;

export default reducer;
