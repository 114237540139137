import { useSelector } from "react-redux";
import { Organization } from "../types/Organization";
import { RootState } from "../store/index";

export const useOrganization = (): Organization | undefined => {
  const { organization } = useSelector(
    (state: RootState) => state.organization
  );
  return organization;
};
