import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PlacementId } from "../types/Placement";

type HighlightedPlacementSliceType = {
  highlightedPlacementId: PlacementId | undefined;
};
const initialState: HighlightedPlacementSliceType = {
  highlightedPlacementId: undefined,
};

const highlightedPlacementSlice = createSlice({
  name: "highlightedPlacement",
  initialState,
  reducers: {
    setHighlightedPlacement: (state, action: PayloadAction<PlacementId>) => {
      state.highlightedPlacementId = action.payload;
    },
    clearHighlightedPlacement: (state) => {
      state.highlightedPlacementId = undefined;
    },
  },
});

export const { setHighlightedPlacement, clearHighlightedPlacement } =
  highlightedPlacementSlice.actions;
export default highlightedPlacementSlice.reducer;
