export const url = (
  label: string,
  fill: string | undefined,
  width: number,
  height: number
) =>
  `data:image/svg+xml;base64,${window.btoa(svg(label, fill, width, height))}`;

export const svg = (
  label: string,
  fill: string | undefined,
  width: number,
  height: number
) =>
  `<svg width="${width}" height="${height}" viewBox="0 0 29.5 35.5" xmlns="http://www.w3.org/2000/svg">
  <path d="M29.0326 15.5123C28.2515 19.6267 24.8924 24.5842 21.5178 28.5841C19.8457 30.5662 18.199 32.2793 16.9223 33.4926C16.2829 34.1002 15.7435 34.5757 15.3449 34.8947C15.2078 35.0046 15.0928 35.0914 15 35.157C14.9073 35.0914 14.7922 35.0046 14.6551 34.8947C14.2565 34.5757 13.7171 34.1002 13.0777 33.4926C11.801 32.2793 10.1544 30.5662 8.48217 28.5841C5.10761 24.5842 1.74849 19.6267 0.967417 15.5123C-0.562868 7.4513 6.2748 0.75 15 0.75C23.7252 0.75 30.5629 7.4513 29.0326 15.5123ZM14.7983 35.2855C14.7887 35.2899 14.7908 35.288 14.8026 35.2834C14.8006 35.2844 14.7992 35.2851 14.7983 35.2855ZM15.1974 35.2834C15.2092 35.288 15.2113 35.2899 15.2017 35.2855C15.2008 35.2851 15.1994 35.2844 15.1974 35.2834Z" ${
    fill ? `fill="${fill}"` : ""
  } fill-opacity=".75" stroke="#fff" stroke-width="1" />
  <text transform="translate(15)" y="20" style="font-family:'arial';font-weight:600;font-size:12px;font-style:normal;fill:#fff;stroke:none;">
    <tspan text-anchor="middle">${label}</tspan></text>
  </svg>`;
