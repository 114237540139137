import { Flex, Image, Text } from "@chakra-ui/react";
import { useConfig } from "../../hooks/useConfig";
import noEligibleSchoolImage from "../../images/no-eligible-schools.svg";

export function NoEligibleSchools() {
  const config = useConfig();
  return (
    <Flex
      top="0"
      left="0"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      direction="column"
      gap={2}
      mb={4}
    >
      <Image width={140} src={noEligibleSchoolImage} />
      <Text fontWeight="bold">
        {`Not eligible for any ${
          config?.variableWords.school.singular.label.toLowerCase() ?? "school"
        }`}
      </Text>
      <Text textAlign="center">
        Review or modify your eligibility check, or contact the district for
        help.
      </Text>
    </Flex>
  );
}
