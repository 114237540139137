import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  StyleProps,
} from "@chakra-ui/react";
import React from "react";
import { RiInformationFill } from "react-icons/ri";

export type Props = {
  title?: string;
  children: React.ReactNode;
  closeAlert?: () => void;
  styleProps?: StyleProps;
};
export const InfoAlert: React.FC<Props> = ({
  children,
  closeAlert,
  title,
  styleProps,
}) => {
  return (
    <Alert status="info" borderRadius={6} alignItems="center" sx={styleProps}>
      <AlertIcon as={RiInformationFill} />
      {title ? <AlertTitle>{title}</AlertTitle> : null}
      <AlertDescription flexGrow={1}>{children}</AlertDescription>
      {closeAlert ? <CloseButton onClick={closeAlert} /> : null}
    </Alert>
  );
};
