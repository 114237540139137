import { Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { RiLightbulbLine } from "react-icons/ri";

export const TipTag = () => {
  return (
    <Tag bg="purple.500" color="white" alignSelf="flex-start" size="sm">
      <TagLeftIcon as={RiLightbulbLine} />
      <TagLabel>Tip</TagLabel>
    </Tag>
  );
};
