export interface IMarker {
  getPosition: () => ILatLng | null | undefined;
}
export interface Options {
  tolerance: number;
}
const DEFAULT_OPTIONS = { tolerance: 0.0001 };
export function checkAllMarkersInSameCoordinate(
  markers: IMarker[],
  options: Options = DEFAULT_OPTIONS
): boolean {
  if (markers.length < 2) {
    // has only one marker
    return true;
  }

  const firstMarker = markers[0];
  for (const marker of markers) {
    if (firstMarker === marker) {
      continue;
    }
    const coordinate1 = firstMarker.getPosition();
    const coordinate2 = marker.getPosition();

    if (!isEqual(coordinate1, coordinate2, options)) {
      return false;
    }
  }

  return true;
}
function isNullOrUndefined(val: unknown): val is undefined | null {
  return val === null || val === undefined;
}

export interface ILatLng {
  lat: number;
  lng: number;
}
export function isEqual(
  coordinate1: ILatLng | null | undefined,
  coordinate2: ILatLng | null | undefined,
  { tolerance }: Options = DEFAULT_OPTIONS
): boolean {
  if (isNullOrUndefined(coordinate1) && isNullOrUndefined(coordinate2))
    return true;
  if (isNullOrUndefined(coordinate1) || isNullOrUndefined(coordinate2))
    return false;

  return (
    Math.abs(coordinate1.lat - coordinate2.lat) <= tolerance &&
    Math.abs(coordinate1.lng - coordinate2.lng) <= tolerance
  );
}

export interface Cluster<T extends ILatLng> {
  coordinate: ILatLng;
  coordinates: T[];
}
export type ClusterCoordinates<T extends ILatLng> = Array<
  CoordinateOrCluster<T>
>;
export function clusterCoordinates<T extends ILatLng>(
  coordinates: T[],
  options: Options = DEFAULT_OPTIONS
): ClusterCoordinates<T> {
  if (coordinates.length < 2) return coordinates;
  return coordinates.reduce<ClusterCoordinates<T>>((clusters, coordinate) => {
    return updateClusters(clusters, coordinate, options);
  }, []);
}

export type CoordinateOrCluster<T extends ILatLng> = T | Cluster<T>;

export function isCluster<T extends ILatLng>(
  item: CoordinateOrCluster<T>
): item is Cluster<T> {
  return item !== null && item !== undefined && "coordinate" in item;
}

function updateClusters<T extends ILatLng>(
  clusters: ClusterCoordinates<T>,
  coordinate: T | undefined,
  options: Options = DEFAULT_OPTIONS
): ClusterCoordinates<T> {
  if (coordinate === undefined) return clusters;
  for (let index = 0; index < clusters.length; index++) {
    const item = clusters[index];
    if (item === undefined) {
      return clusters;
    }

    if (!isCluster(item)) {
      if (isEqual(item, coordinate, options)) {
        clusters[index] = { coordinate: item, coordinates: [item, coordinate] };
        return clusters;
      }
    } else {
      if (isInsideCluster(item, coordinate, options)) {
        item.coordinates.push(coordinate);
        return clusters;
      }
    }
  }

  clusters.push(coordinate);
  return clusters;
}

function isInsideCluster<T extends ILatLng>(
  cluster: Cluster<T>,
  coordinate: T,
  options: Options = DEFAULT_OPTIONS
): boolean {
  return isEqual(cluster.coordinate, coordinate, options);
}
