type GoogleAddressComponents = {
  street_number?: string;
  route?: string; // Street
  // neighborhood?: string;
  locality?: string; // City
  // administrative_area_level_2?: string; // County
  administrative_area_level_1?: string; // State
  // country?: string;
  postal_code?: string;
  // postal_code_suffix?: string;
  subpremise?: string; // Street address line 2
};

export type Address = {
  streetAddress?: string;
  streetAddressLine2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
};

export function transformGeocoderAddressComponentsToAddress(
  googleComponentArray: google.maps.GeocoderAddressComponent[]
): Address {
  // Convert array to an object mapping each component's primary `type` to its value.
  const googleComponents: GoogleAddressComponents = Object.fromEntries(
    googleComponentArray.map(({ short_name, types }) => [types[0], short_name])
  );

  let streetAddress = undefined;
  if (googleComponents.street_number || googleComponents.route) {
    streetAddress = `${googleComponents.street_number ?? ""} ${
      googleComponents.route ?? ""
    }`.trim();
  }

  return {
    streetAddress,
    streetAddressLine2: googleComponents.subpremise ?? "",
    city: googleComponents.locality,
    state: googleComponents.administrative_area_level_1,
    zipCode: googleComponents.postal_code,
  };
}
