import { useLocation } from "react-router";
import queryString from "query-string";

type QueryParams = {
  place_id?: string;
  saved?: string | string[];
};

export const useQueryParams = (): QueryParams => {
  const { search } = useLocation();
  return queryString.parse(search);
};
