import { Text, Flex, Box } from "@chakra-ui/layout";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { FormattedDistanceToPlacement } from "../../../components/FormattedDistanceToPlacement";
import { BoundaryTag } from "../../../components/BoundaryTag";
import { usePlacementData } from "../../../hooks/usePlacementData";
import { RootState } from "../../../store";
import { ContentItem, KeyInfo } from "../../../types/UIConfig";
import { KeyInfoItem } from "./KeyInfoItem";
import { EligibilityInfoAlert } from "../../../components/Feedback/EligibilityInfoAlert";
import { SpaceProps } from "@chakra-ui/react";

export type ProfileInfoSectionProps = {
  placementId: string | undefined;
} & SpaceProps;

export const ProfileInfoSection = ({
  placementId,
  paddingTop,
  paddingBottom,
  paddingX,
}: ProfileInfoSectionProps): React.ReactElement | null => {
  const { getPlacementStringValue, placement } = usePlacementData(placementId);
  const config = useSelector((state: RootState) => state.config);
  const distance = useSelector((state: RootState) => state.distance);

  if (placementId === undefined) return null;

  const distanceToPlacement = distance?.distanceToPlacementById?.[placementId];

  if (config.state !== "finished") return null;

  return (
    <Flex
      direction="column"
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      paddingX={paddingX}
    >
      <Flex
        direction="row"
        paddingBottom={{ base: 2, lg: 3 }}
        paddingX={{ base: 1 }}
        wrap="wrap"
        gridColumnGap={3}
        gridRowGap={2}
      >
        <Text fontSize="sm" fontWeight="bold">
          {config.values.profile.highlighted
            .map((item: ContentItem) => getPlacementStringValue(item))
            .join(" | ")}
        </Text>
        {distanceToPlacement && (
          <Flex whiteSpace="nowrap" gridGap="1">
            <Text fontSize="sm">
              <FormattedMessage id="DISTANCE" />:
            </Text>
            <Text fontSize="sm" fontWeight="bold">
              <FormattedDistanceToPlacement id={placementId} />
            </Text>
          </Flex>
        )}
        {placement && <BoundaryTag placement={placement} />}
      </Flex>

      <Box>
        {config.values.profile.keyInfo.map(
          (keyInfoItem: KeyInfo, index: number) => {
            const placementValue = getPlacementStringValue(keyInfoItem);

            if (placementValue) {
              const key = `${
                keyInfoItem.contentType === "static"
                  ? keyInfoItem.value
                  : keyInfoItem.fieldName
              }${index}`;
              return (
                <KeyInfoItem
                  key={key}
                  keyInfo={keyInfoItem}
                  value={placementValue}
                />
              );
            } else {
              return null;
            }
          }
        )}
      </Box>
      <Box paddingTop={{ base: 1, lg: 3 }} paddingRight={{ base: 1, lg: 4 }}>
        <EligibilityInfoAlert>
          {({ checkEligibilityLink }) => (
            <>
              This school has eligibility requirements. {checkEligibilityLink}{" "}
              with a student to see if they qualify.
            </>
          )}
        </EligibilityInfoAlert>
      </Box>
    </Flex>
  );
};
