import { IconButton } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import { Flex, Text } from "@chakra-ui/layout";
import { Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/popover";
import React from "react";
import { RiQuestionLine } from "react-icons/ri";
import { useIntl } from "react-intl";
import { useColors } from "../../../hooks/useColors";
import { useConfig } from "../../../hooks/useConfig";
import { useStep } from "../hooks/useStep";

const NUM_STEPS = 5;

const colors = {
  1: "#FB8B24",
  2: "#FFC122",
  3: "#F8ED00",
  4: "#B2E325",
  5: "#30D773",
};

export const DemandMeter = (): React.ReactElement | null => {
  const step = useStep();
  const config = useConfig();
  const { secondary } = useColors();
  const intl = useIntl();

  const meter = config?.savedList?.meter;

  if (meter === undefined || step === undefined) return null;

  const color = colors[step];
  const steps = Array.from(Array(NUM_STEPS).keys()).map((i) => i + 1);

  return (
    <Flex align="center" gap={4}>
      <Flex width="160px" height="12px">
        {steps.map((stepIndex) => (
          <Flex
            key={stepIndex}
            width={1 / NUM_STEPS}
            align="center"
            _first={{
              borderRadius: "7px 0 0 7px",
            }}
            _last={{
              borderRadius: "0 7px 7px 0",
            }}
            backgroundColor={stepIndex <= step ? color : "gray.300"}
            borderLeft={stepIndex > 1 ? "2px solid" : "none"}
            borderColor="white"
          />
        ))}
      </Flex>
      <Flex>
        <Popover trigger="hover">
          <PopoverTrigger>
            <IconButton
              variant="ghost"
              aria-label={intl.formatMessage({ id: "DEMAND_METER_ICON_LABEL" })}
              icon={<Icon as={RiQuestionLine} fontSize="md" />}
              size="xs"
            />
          </PopoverTrigger>
          <PopoverContent
            paddingY={4}
            paddingX={6}
            width={{ base: "calc(100vw - 40px)", lg: "420px" }}
            marginX={5}
          >
            <Text
              as="h3"
              fontSize="md"
              fontWeight="semibold"
              color={secondary}
              textAlign="center"
            >
              {meter.hoverableStates?.[step]?.label}
            </Text>
            <Text as="p" marginTop={1} fontSize="sm">
              {meter.hoverableStates?.[step]?.value}
            </Text>
          </PopoverContent>
        </Popover>
      </Flex>
    </Flex>
  );
};
