import { getPrimaryColor, getSecondaryColor } from "../services/colors";
import { useConfig } from "./useConfig";

export type Colors = {
  primary: string | undefined;
  primary100: string | undefined;
  secondary: string | undefined;
};

export const useColors = (): Colors => {
  const config = useConfig();

  if (config === undefined) return {} as Colors;

  return {
    primary: getPrimaryColor(config),
    primary100: getPrimaryColor(config, 0.1),
    secondary: getSecondaryColor(config),
  };
};
