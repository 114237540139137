import React from "react";
import { Image, Box, Text, Flex, Spacer } from "@chakra-ui/react";

import { SavePlacementButton } from "../../../components/SavePlacementButton";
import { usePlacementData } from "../../../hooks/usePlacementData";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { imageUrl } from "../../../services/urlBuilder";
import noSchoolImg from "../../../images/no-photo.jpg";
import { useFocusStyle } from "../../../hooks/useFocusStyle";
import { useOrganization } from "../../../hooks/useOrganization";

interface ProfileImageSectionProps {
  placementId: string | undefined;
}

export const ProfileImageSection = ({
  placementId,
}: ProfileImageSectionProps): React.ReactElement | null => {
  const { placement } = usePlacementData(placementId);
  const config = useSelector((state: RootState) => state.config);
  const focusStyle = useFocusStyle({ position: "offset", color: "white" });
  const organization = useOrganization();

  if (config.state !== "finished") return null;

  const continueButtonText =
    config?.values?.eligibility?.welcomeModal?.continueButtonText ??
    "Check eligibility";

  return (
    <Box position="relative" height={164}>
      <Image
        src={
          placementId
            ? imageUrl(organization?.path || "", placementId)
            : noSchoolImg
        }
        alt={placement?.name}
        fallbackSrc={noSchoolImg}
        height="100%"
        width="100%"
        objectFit="cover"
      />
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        background="rgba(0, 0, 0, 0.6)"
        color="white"
        paddingY={2}
        paddingLeft={6}
        paddingRight={3}
      >
        <Flex align="center" gridGap="3">
          <Box>
            <Text as="span" fontSize="md" fontWeight="bold" letterSpacing="xl">
              {placement?.name}
            </Text>
          </Box>
          <Spacer />
          <SavePlacementButton
            continueButtonText={continueButtonText}
            placementId={placementId}
            size="sm"
            sx={focusStyle}
          />
        </Flex>
      </Box>
    </Box>
  );
};
