import React from "react";
import { Text, Flex, Link } from "@chakra-ui/react";
import { InfoWindowF } from "@react-google-maps/api";
import { getPlacementStringValue } from "../services/transformData";
import { useConfig } from "../hooks/useConfig";
import { Placement, PlacementId } from "../types/Placement";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { placementPath } from "../services/urlBuilder";
import { MARKER_HEIGHT } from "./CustomMarker";

export type Props = {
  position: google.maps.LatLng;
  placements: Placement[];
  onCloseClick: () => void;
  onPlacementMarkerClick: (
    placementId: PlacementId,
    position: google.maps.LatLngLiteral
  ) => void;
};

/*
  A popover that appears when a clster marker is selected and enables selecting a school from a list.
*/
export const ListPopover = ({
  placements,
  onCloseClick,
  onPlacementMarkerClick,
  position,
}: Props) => {
  const location = useLocation();
  const config = useConfig();

  const getHighlight = React.useCallback(
    (placement: Placement) => {
      if (!config) return null;
      return config.map.listPopover.highlighted
        .map((contentItem) => {
          return getPlacementStringValue(placement, contentItem);
        })
        .join("|");
    },
    [config]
  );

  if (!config) return null;

  return (
    <InfoWindowF
      options={{
        position,
        pixelOffset: new google.maps.Size(0, -MARKER_HEIGHT),
      }}
      onCloseClick={onCloseClick}
    >
      <Flex direction="column">
        <Text paddingY={1}>
          <FormattedMessage
            id="SCHOOLS_IN_PROXIMITY"
            values={{
              count: placements.length,
              schools: config.variableWords.school.plural.label.toLowerCase(),
            }}
          />
        </Text>
        {placements.map((placement) => {
          const highlight = getHighlight(placement);
          return (
            <Link
              key={`list-popover-link-${placement.id}`}
              borderBottomColor="gray.300"
              borderBottomWidth={1}
              borderBottomStyle="solid"
              _last={{ borderBottom: "none" }}
              paddingY={2}
              as={RouterLink}
              to={{
                ...location,
                pathname: placementPath(placement.id),
              }}
              onClick={() => {
                onPlacementMarkerClick(placement.id, {
                  lat: placement.lat,
                  lng: placement.lng,
                });
              }}
              _focus={{ boxShadow: "none" }}
            >
              <Text fontWeight={600} as="span">
                {placement.name}
              </Text>
              {highlight ? (
                <Text fontWeight={600} as="span" paddingX={2}>
                  |
                </Text>
              ) : undefined}
              <Text as="span">{highlight}</Text>
            </Link>
          );
        })}
      </Flex>
    </InfoWindowF>
  );
};
