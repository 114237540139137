import React from "react";
import { useDispatch } from "react-redux";
import { CheckboxCards } from "../../../components/CheckboxCards";
import { MultipleFilter } from "../../../types/UIConfig";
import { setFilterField } from "../../../store/filter";

interface MultipleSelectFilterProps {
  filter: MultipleFilter;
  selectedValues: string[];
}

export const MultipleSelectFilter = (
  props: MultipleSelectFilterProps
): React.ReactElement | null => {
  const { filter, selectedValues } = props;
  const { fieldName, label } = filter;
  const dispatch = useDispatch();

  const handleChange = (selected: string[]) => {
    dispatch(
      setFilterField({
        type: "multiple",
        fieldName,
        selected,
      })
    );
  };

  return (
    <CheckboxCards
      selectedValues={selectedValues}
      options={props.filter.options}
      label={label}
      onChange={handleChange}
    />
  );
};
