import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PlacementId = string;
type SliceType =
  | { status: "loading" }
  | { status: "finished"; ids: PlacementId[] };
const initialState: SliceType = { status: "loading" } as SliceType;

const resultsSlice = createSlice({
  name: "results",
  initialState,
  reducers: {
    setResults: (state, action: PayloadAction<PlacementId[]>) => {
      return {
        status: "finished",
        ids: action.payload,
      };
    },
  },
});

export const { setResults } = resultsSlice.actions;
export default resultsSlice.reducer;
