import * as React from "react";
import { ContentItem, ContentItemLink } from "../../../types/UIConfig";
import { Link } from "@chakra-ui/react";

type Props = {
  contentItem: ContentItem;
  value: string;
};

function isContentItemLink(
  contentItem: ContentItem
): contentItem is ContentItemLink {
  return "type" in contentItem && contentItem.type === "website";
}

function toUrl(value: string): string {
  const valueLower = value.toLowerCase();
  if (valueLower.startsWith("http://") || valueLower.startsWith("https://")) {
    return value;
  }

  return `https://${value}`;
}

export const FormattedContentItem = ({
  contentItem,
  value,
}: Props): React.ReactElement => {
  if (isContentItemLink(contentItem)) {
    return (
      <Link href={toUrl(value)} isExternal={contentItem.opensNewTab}>
        {value}
      </Link>
    );
  }

  return <>{value}</>;
};
