import { Tag } from "@chakra-ui/react";
import { useBoundaryTag } from "../hooks/useBoundary";
import { Placement } from "../types/Placement";

type Props = { placement: Placement };

export function BoundaryTag({ placement }: Props) {
  const tags = useBoundaryTag(placement);
  if (tags.length === 0) return null;

  return (
    <>
      {tags.map((label: string) => (
        <Tag
          variant="outline"
          borderRadius="full"
          colorScheme="secondary"
          key={label}
        >
          {label}
        </Tag>
      ))}
    </>
  );
}
