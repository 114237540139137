import { Box, BoxProps, Link, ListItem, UnorderedList } from "@chakra-ui/react";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { ConfigurableLink } from "../types/UIConfig";

type NavigationProps = BoxProps;

export const Navigation = (
  props: NavigationProps
): React.ReactElement | null => {
  const config = useSelector((state: RootState) => state.config);

  if (config.state !== "finished") return null;

  return (
    <Box as="nav" {...props}>
      <UnorderedList
        alignItems="flex-start"
        display="flex"
        flexDirection={{ base: "column", lg: "row" }}
        listStyleType="none"
        gridGap={10}
        margin={0}
        flexWrap="nowrap"
        fontSize="md"
      >
        {config.values.navigation.links.map((link: ConfigurableLink) => (
          <ListItem key={`${link.href}${link.label}`}>
            <Link href={link.href} isExternal={link.opensNewTab}>
              {link.label}
            </Link>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
};
