import { Text } from "@chakra-ui/layout";
import { Alert } from "@chakra-ui/alert";
import { CloseButton } from "@chakra-ui/close-button";
import React from "react";

interface SubtleAlertProps {
  content: React.ReactNode;
  onClose?: () => void;
}

export const SubtleAlert = ({
  content,
  onClose,
}: SubtleAlertProps): React.ReactElement => {
  return (
    <Alert backgroundColor="gray.900" color="white" fontSize="xs" padding={4}>
      <Text letterSpacing="xs" marginRight={4}>
        {content}
      </Text>
      {onClose && (
        <CloseButton onClick={onClose} position="absolute" top={0} right={0} />
      )}
    </Alert>
  );
};
