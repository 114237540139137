import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import Icon from "@chakra-ui/icon";
import { MdTranslate } from "react-icons/md";
import { RiArrowDownSLine } from "react-icons/ri";
import { RootState } from "../store";
import { UIConfig } from "../types/UIConfig";
import { setLang } from "../store/lang";
import { languageCodeToName } from "../services/lang";
import { fetchPlacements } from "../store/placements";
import { fetchConfig } from "../store/config";
import { Language } from "../types/Language";
import { Button } from "@chakra-ui/button";
import { useIntl } from "react-intl";
import { clearFilters } from "../store/filter";
import { useOrganization } from "../hooks/useOrganization";

export const TranslationSelector = (): React.ReactElement | null => {
  const config = useSelector((state: RootState) => state.config);
  const organization = useOrganization();
  const dispatch = useDispatch();
  const intl = useIntl();

  if (config.state !== "finished") return null;

  const { values }: { values: UIConfig } = config;
  const { languages, placement: placementMapping } = values;

  if (!languages) return null;

  const handleLang = (lang: Language) => () => {
    dispatch(setLang(lang));
    dispatch(
      fetchPlacements({
        placementMapping,
        lang,
        organizationPath: organization?.path || "",
      })
    );
    dispatch(fetchConfig({ lang, organizationPath: organization?.path || "" }));
    dispatch(clearFilters());
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        aria-label={intl.formatMessage({ id: "TRANSLATION_DROPDOWN_LABEL" })}
        marginX={{ base: 4 }}
        background="none"
        _hover={{ background: "none" }}
        _active={{ background: "none" }}
      >
        <Icon as={MdTranslate} boxSize={5} />
        <Icon as={RiArrowDownSLine} boxSize={4} />
      </MenuButton>
      <MenuList fontSize="sm" zIndex={10}>
        {languages.map((languageCode) => (
          <MenuItem key={languageCode} onClick={handleLang(languageCode)}>
            {languageCodeToName[languageCode]}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
