import React from "react";
import { Flex, Text } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";

import { useColors } from "../hooks/useColors";
import emptySchoolImg from "../images/empty-school.svg";

interface EmptyListProps {
  header: React.ReactNode;
  description: React.ReactNode;
  footer?: React.ReactNode;
}

export const EmptyList = ({
  header,
  description,
  footer,
}: EmptyListProps): React.ReactElement => {
  const { secondary } = useColors();

  return (
    <Flex
      direction="column"
      align="center"
      paddingX={12}
      flexGrow={1}
      overflowY="auto"
      justifyContent="center"
      gap={6}
    >
      <Text color={secondary} fontWeight="bold" fontSize="lg">
        {header}
      </Text>
      <Text fontSize="md">{description}</Text>
      <Image width={240} src={emptySchoolImg} />

      {footer && <Flex>{footer}</Flex>}
    </Flex>
  );
};
