import { UIConfig } from "../../types/UIConfig";
import { ComponentSingleStyleConfig } from "@chakra-ui/theme";

export const getDesktopButton = (
  config: UIConfig
): ComponentSingleStyleConfig => ({
  baseStyle: {
    border: "2px solid",
    borderColor: "brand.500",
    borderRadius: "4px",
    padding: "8px 20px",
    color: "white",
    letterSpacing: "xl",
    _hover: {
      filter: "brightness(90%)",
    },
  },
  variants: {
    outlined: {},
    filled: {
      backgroundColor: "brand.500",
    },
    text: {
      border: "none",
    },
  },
  defaultProps: {
    variant: "outlined",
  },
});
