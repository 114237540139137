import { Address } from "../store/address";
import { PlacementMap } from "../store/placements";
import { DistanceById } from "../store/distance";
import { UIConfig } from "../types/UIConfig";

export const calculateDistanceToPlacementById = (
  address: Address,
  placementsById: PlacementMap,
  config: UIConfig
): DistanceById => {
  return Object.keys(placementsById).reduce(
    (acc: DistanceById, cur: string) => {
      const placement = placementsById[cur];
      const lat = placement.lat;
      const lng = placement.lng;

      acc[cur] = distance(address.lat, address.lng, Number(lat), Number(lng));
      return acc;
    },
    {}
  );
};

const distance = (lat1: number, lng1: number, lat2: number, lng2: number) => {
  var p = 0.017453292519943295; // Math.PI / 180
  var c = Math.cos;
  var a =
    0.5 -
    c((lat2 - lat1) * p) / 2 +
    (c(lat1 * p) * c(lat2 * p) * (1 - c((lng2 - lng1) * p))) / 2;

  return 7917.6 * Math.asin(Math.sqrt(a)); // 2 * R; R = 3958.8 mi
};
