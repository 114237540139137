import { NavigateFunction, Location } from "react-router";
import { homePath, placementPath } from "../services/urlBuilder";
import { onVisitProfileAnalytics } from "./analytics";

export const gotoHome = (
  navigate: NavigateFunction,
  location: Location
): void => {
  navigate({ ...location, pathname: homePath() });
};

export const gotoProfile =
  (navigate: NavigateFunction, location: Location) =>
  (placement: string): void => {
    const pathname = placementPath(placement);

    navigate({
      ...location,
      pathname,
    });

    onVisitProfileAnalytics({ pathname });
  };
