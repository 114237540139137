import { IconButton } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import { FlexProps, Flex, Spacer } from "@chakra-ui/react";
import React from "react";
import { RiMailLine, RiMessage2Line } from "react-icons/ri";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useColors } from "../../../hooks/useColors";
import { useConfig } from "../../../hooks/useConfig";
import { useFocusStyle } from "../../../hooks/useFocusStyle";
import { onApplyAnalytics } from "../../../services/analytics";
import { RootState } from "../../../store";
import * as ConfigurableLink from "../../../services/configurableLink";
import { ApplyNowButton } from "../../../components/ApplyNowButton";

interface Props extends FlexProps {
  onSmsToggle: () => void;
  onEmailToggle: () => void;
}

export const SavedPlacementsFooter = ({
  onSmsToggle,
  onEmailToggle,
  ...props
}: Props): React.ReactElement | null => {
  const { secondary } = useColors();
  const savedIds = useSelector((state: RootState) => state.saved.ids);
  const config = useConfig();
  const intl = useIntl();
  const focusStyle = useFocusStyle({ color: "primary" });

  if (!config) return null;

  const handleClickApply = () => {
    onApplyAnalytics({ savedIds: savedIds.join(",") });
  };

  const applyNowLink = ConfigurableLink.applyNow(config, savedIds);

  return (
    <Flex
      background={secondary}
      align="center"
      gridGap={{ base: 2, lg: 4 }}
      paddingX={{ base: 3, lg: 6 }}
      color="white"
      {...props}
    >
      <IconButton
        variant="unstyled"
        onClick={onEmailToggle}
        icon={<Icon as={RiMailLine} />}
        title={intl.formatMessage(
          { id: "EMAIL_SAVED_SCHOOLS" },
          { schools: config.variableWords.school.plural.label }
        )}
        aria-label={intl.formatMessage(
          { id: "EMAIL_SAVED_SCHOOLS" },
          { schools: config.variableWords.school.plural.label }
        )}
        disabled={savedIds.length === 0}
        fontSize="22px"
        marginY={2}
        sx={focusStyle}
      />
      <IconButton
        variant="unstyled"
        onClick={onSmsToggle}
        icon={<Icon as={RiMessage2Line} />}
        title={intl.formatMessage(
          { id: "TEXT_SAVED_SCHOOLS" },
          { schools: config.variableWords.school.plural.label }
        )}
        aria-label={intl.formatMessage(
          { id: "TEXT_SAVED_SCHOOLS" },
          { schools: config.variableWords.school.plural.label }
        )}
        disabled={savedIds.length === 0}
        fontSize="22px"
        sx={focusStyle}
      />
      <Spacer />
      {applyNowLink && savedIds.length ? (
        <ApplyNowButton
          applyNowLink={applyNowLink}
          colorScheme="primary"
          onClick={handleClickApply}
        ></ApplyNowButton>
      ) : (
        <></>
      )}
    </Flex>
  );
};
