import React from "react";
import { RemoteData } from "../types/remoteData";
import { Loading } from "./Feedback/Loading";

type Props<E, T> = {
  remoteData: RemoteData<E, T>;
  error: (error: E) => React.ReactNode;
  children: (data: T) => React.ReactNode;
  notAsked?: React.ReactNode;
  loading?: React.ReactNode;
};
export function RemoteDataView<E, T>({
  remoteData,
  loading,
  error,
  children,
  notAsked,
}: Props<E, T>): React.ReactElement {
  switch (remoteData.type) {
    case "loading":
      return loading ? <>{loading}</> : <Loading />;
    case "notAsked":
      return notAsked ? <>{notAsked}</> : <></>;
    case "error":
      return <>{error(remoteData.error)}</>;
    case "success":
      return <>{children(remoteData.data)}</>;

    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _exhaustiveCheck: never = remoteData;
      throw new Error(`Unhandled remote data type: ${remoteData}`);
  }
}
