import {
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { EligibilityWelcomeModal, UIConfig } from "../../types/UIConfig";
import { EligibilityButtonGroup } from "../Buttons/EligibilityButtonGroup";
import { Organization } from "../../types/Organization";
import { useDispatch } from "react-redux";
import {
  setShowEligibilityWelcomeModal,
  setShowCheckEligibilityModal,
} from "../../store/modals";
import { setEligibilityWelcomeAcknowledged } from "../../store/saved";

type WelcomeEligibilityDialogProps = {
  config: UIConfig;
  organization: Organization;
  isOpen: boolean;
};
export const WelcomeEligibilityDialog = ({
  config,
  organization,
  isOpen,
}: WelcomeEligibilityDialogProps) => {
  const dispatch = useDispatch();
  const modalConfig =
    config?.eligibility?.welcomeModal || ({} as EligibilityWelcomeModal);

  const { titleText, bodyText, acknowledgementEnabled, acknowledgementText } =
    modalConfig;

  const [isAcknowledged, setIsAcknowledged] = useState<boolean>(
    !acknowledgementEnabled
  );

  const defaultTitleText = `Welcome 🎉`;
  const defaultBodyText = `Welcome to Explore, the best way to discover ${organization.title}’s Schools, calculate travel times, and learn about their educational programming!\n\nFind out what a student’s options by checking eligibility before applying. Save schools to create a list if you want to apply to more than one.`;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent m={4}>
        <ModalHeader fontSize="lg" fontWeight="bold">
          {titleText ? titleText : defaultTitleText}
        </ModalHeader>
        <ModalBody as={Flex} direction="column" gap={4}>
          <Text whiteSpace="pre-wrap">
            {bodyText ? bodyText : defaultBodyText}
          </Text>

          {acknowledgementEnabled && (
            <Checkbox
              paddingTop="0.5rem"
              paddingBottom="0.5rem"
              onChange={(event) => {
                setIsAcknowledged(event.target.checked);
              }}
            >
              {acknowledgementText}
            </Checkbox>
          )}
        </ModalBody>
        <ModalFooter>
          <EligibilityButtonGroup
            variant="ghost"
            onCancelButton={() => {
              dispatch(setEligibilityWelcomeAcknowledged(true));
              dispatch(setShowEligibilityWelcomeModal(false));
            }}
            onContinueButton={() => {
              dispatch(setEligibilityWelcomeAcknowledged(true));
              dispatch(setShowEligibilityWelcomeModal(false));
              dispatch(setShowCheckEligibilityModal(true));
            }}
            isContinueDisabled={!isAcknowledged}
            modalConfig={modalConfig}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
