import React from "react";
import * as Redux from "react-redux";
import { fetchBoundary } from "../../../store/boundary";
import { useConfig } from "../../../hooks/useConfig";
import {
  useAddressBoundary,
  useAllBoundaries,
  useSelectedPlacementBoundaries,
} from "../../../hooks/useBoundary";
import { BoundaryPolygon } from "./BoundaryPolygon";
import { Boundary } from "../../../types/Boundary";
import { InfoWindowF } from "@react-google-maps/api";
import { Text } from "@chakra-ui/react";
import { getBoundaryCenter } from "../../../services/geoJSON";

export function GeoJSONLayer() {
  const dispatch = Redux.useDispatch();
  const config = useConfig();
  const allBoundaries = useAllBoundaries();
  const addressBoundaries = useAddressBoundary();
  const selectedPlacementBoundaries = useSelectedPlacementBoundaries();
  const [activeBoundary, setActiveBoundary] = React.useState<Boundary>();
  const [position, setPosition] = React.useState<google.maps.LatLng>();

  const boundaries = React.useMemo(() => {
    if (addressBoundaries && addressBoundaries.length > 0) {
      const addressBoundariesShown = addressBoundaries.find((boundary) => {
        return boundary.showAddressBoundary === true;
      });
      if (addressBoundariesShown) {
        return addressBoundaries;
      }
    }

    if (selectedPlacementBoundaries && selectedPlacementBoundaries.length > 0) {
      const selectedPlacementBoundariesShown = selectedPlacementBoundaries.find(
        (boundary) => {
          return boundary.showSelectedPlacementBoundary === true;
        }
      );
      if (selectedPlacementBoundariesShown) {
        return selectedPlacementBoundaries;
      }
    }

    if (allBoundaries && allBoundaries.length > 0) {
      const allBoundariesShown = allBoundaries.find((boundary) => {
        return boundary.showAllBoundaries === true;
      });
      if (allBoundariesShown) {
        return allBoundaries;
      }
    }
    return [];
  }, [addressBoundaries, selectedPlacementBoundaries, allBoundaries]);

  React.useEffect(() => {
    if (config) {
      dispatch(fetchBoundary(config));
    }
  }, [dispatch, config]);

  if (!boundaries) return null;

  return (
    <>
      {boundaries
        .filter((boundary) => boundary /*.isVisible*/)
        .map((boundary, index) => [
          <BoundaryPolygon
            boundary={boundary}
            key={`polygon-${index}`}
            onClick={(event) => {
              if (event.latLng) setPosition(event.latLng);
              setActiveBoundary(boundary);
            }}
          />,

          activeBoundary?.id === boundary.id && boundary.showMapLabel && (
            <InfoWindowF
              position={position ?? getBoundaryCenter(boundary)}
              key={`info-${index}`}
              onCloseClick={() => {
                setPosition(undefined);
                setActiveBoundary(undefined);
              }}
            >
              <Text marginRight={3}>{boundary.id}</Text>
            </InfoWindowF>
          ),
        ])}
    </>
  );
}
