import * as React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Box, Flex, HStack, Stack } from "@chakra-ui/react";
import { Skeleton } from "../../components/Skeleton";
import { PlacementCard } from "../../components/PlacementCard";
import { SortPanel } from "./SortPanel";
import { RootState } from "../../store";
import { EmptyList } from "../../components/EmptyList";
import { Footer } from "./Footer";
import { useBreakpointValue } from "../../hooks/useBreakpointValue";
import { useConfig } from "../../hooks/useConfig";
import { PlacementId } from "../../types/Placement";

const CARD_HEIGHT = 114;

export interface Props {
  onClick: (
    placementId: PlacementId,
    position: google.maps.LatLngLiteral
  ) => void;
}

export const PlacementList = React.memo(
  ({ onClick }: Props): React.ReactElement | null => {
    const results = useSelector((state: RootState) => state.results);
    const { isBaseBreakpoint } = useBreakpointValue();
    const config = useConfig();

    return (
      <Flex direction="column" height="100%" width="100%">
        <>
          <SortPanel />
          <Box height="100%">
            {results.status === "loading" ? (
              <Stack padding={5} gridGap={5}>
                {Array(5)
                  .fill(null)
                  .map((val, index) => (
                    <HStack gridGap={5} key={index}>
                      <Skeleton
                        startColor="gray.100"
                        endColor="gray.200"
                        height="70px"
                        flexGrow={1}
                      />
                      <Skeleton
                        startColor="gray.100"
                        endColor="gray.200"
                        height="70px"
                        width="70px"
                      />
                    </HStack>
                  ))}
              </Stack>
            ) : results.ids.length === 0 ? (
              <EmptyList
                header={
                  <FormattedMessage
                    id="RESULTS_LIST_EMPTY_HEADER"
                    values={{
                      schools:
                        config?.variableWords.school.plural.label.toLowerCase(),
                    }}
                  />
                }
                description={
                  <FormattedMessage id="RESULTS_LIST_EMPTY_DESCRIPTION" />
                }
              />
            ) : (
              <AutoSizer>
                {({ height, width }: any) => (
                  <List
                    height={height ?? 0}
                    itemCount={results.ids.length}
                    itemSize={CARD_HEIGHT}
                    width={width ?? 0}
                  >
                    {({ index, style }) => {
                      const id = results.ids[index];
                      return (
                        <PlacementCard
                          onClick={onClick}
                          placementId={id}
                          style={style}
                        />
                      );
                    }}
                  </List>
                )}
              </AutoSizer>
            )}
          </Box>
          {!isBaseBreakpoint && <Footer />}
        </>
      </Flex>
    );
  }
);
