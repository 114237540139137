import { Box } from "@chakra-ui/layout";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../store";

interface FormattedDistanceToPlacementProps {
  id: string | undefined;
}
export const FormattedDistanceToPlacement = ({
  id,
}: FormattedDistanceToPlacementProps): React.ReactElement | null => {
  const distance = useSelector((state: RootState) => state.distance);

  if (id === undefined) return null;

  const distanceToPlacement = distance?.distanceToPlacementById?.[id];

  if (distanceToPlacement === undefined) return null;

  return (
    <Box as="span" flexWrap="nowrap">
      <FormattedMessage
        id="MILES_LABEL"
        values={{ distance: distanceToPlacement }}
      />
    </Box>
  );
};
