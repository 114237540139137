import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import * as Intl from "react-intl";
import { useFocusStyle } from "../../../hooks/useFocusStyle";

type Props = Omit<IconButtonProps, "aria-label"> & {
  direction: "left" | "right";
};
export const NavigationButton = ({ direction, ...props }: Props) => {
  const intl = Intl.useIntl();
  const isLeft = direction === "left";
  const focusStyle = useFocusStyle({ position: "inside" });
  return (
    <IconButton
      position="sticky"
      left={isLeft ? "0" : undefined}
      right={!isLeft ? "0" : undefined}
      variant="unstyled"
      background="gray.300"
      borderLeft={!isLeft ? "2px solid white" : undefined}
      borderRight={isLeft ? "2px solid white" : undefined}
      _hover={{ background: "gray.100" }}
      display="flex"
      alignItems="center"
      width={5}
      minWidth="fit-content"
      borderRadius={0}
      aria-label={intl.formatMessage({
        id: isLeft ? "SCROLL_LEFT" : "SCROLL_RIGHT",
      })}
      icon={
        isLeft ? (
          <RiArrowLeftSLine size={24} />
        ) : (
          <RiArrowRightSLine size={24} />
        )
      }
      zIndex={1}
      sx={focusStyle}
      {...props}
    ></IconButton>
  );
};
