import React from "react";
import {
  Button as ChakraButton,
  Flex,
  useStyleConfig,
  Box,
} from "@chakra-ui/react";

interface DesktopButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  variant?: "outlined" | "filled" | "text";
  icon?: React.ReactElement;
  indicator?: boolean;
}

export const DesktopButton = (props: DesktopButtonProps) => {
  const { onClick, children, variant, icon, indicator = false } = props;
  const styles = useStyleConfig("DesktopButton", { variant });

  return (
    <ChakraButton
      position="relative"
      __css={styles}
      borderColor="white"
      onClick={onClick}
    >
      <Flex flexDirection="row" align="center">
        <Box as="span" color="white" paddingRight={2} lineHeight="16px">
          {icon}
        </Box>
        {children}
      </Flex>
      {indicator && (
        <Box
          borderRadius="4px"
          backgroundColor="white"
          width="8px"
          height="8px"
          position="absolute"
          top="5px"
          right="5px"
          boxShadow="0px 2px 2px 2px rgb(0,0,0,0.15)"
          margin="0 0 2px 2px"
        ></Box>
      )}
    </ChakraButton>
  );
};
