import { Link, Button, Icon } from "@chakra-ui/react";
import { useFocusStyle } from "../hooks/useFocusStyle";
import { getSecondaryColor } from "../services/colors";
import { RiArrowRightCircleLine } from "react-icons/ri";
import { ConfigurableLink } from "../types/UIConfig";
import { Color } from "../services/focusStyle";
import { useConfig } from "../hooks/useConfig";

export type ApplyNowButtonProps = {
  onClick?: () => void;
  applyNowLink?: ConfigurableLink;
  colorScheme: Color;
};
export const ApplyNowButton: React.FC<ApplyNowButtonProps> = ({
  onClick,
  applyNowLink,
  colorScheme,
}): React.ReactElement | null => {
  const focusStyle = useFocusStyle({ color: colorScheme });
  const isExternal = applyNowLink?.opensNewTab ?? true;
  const config = useConfig();

  if (!config) return null;

  let color = undefined;
  let backgroundColor = undefined;

  switch (colorScheme) {
    case "primary": {
      // if the primary color scheme is selected, the button text should
      // be the secondary color
      color = getSecondaryColor(config);
      break;
    }
    case "secondary": {
      // if the secondary color scheme is selected, the button text should
      // be white and the background should be the secondary color
      color = "white";
      backgroundColor = getSecondaryColor(config);
      break;
    }
  }

  return (
    <>
      <Link
        variant="unstyled"
        margin={2}
        whiteSpace="nowrap"
        fontWeight="bold"
        href={applyNowLink?.href}
        isExternal={isExternal}
        onClick={onClick}
        sx={focusStyle}
      >
        <Button
          color={color}
          backgroundColor={backgroundColor}
          rightIcon={<Icon as={RiArrowRightCircleLine} />}
        >
          {applyNowLink?.label}
        </Button>
      </Link>
    </>
  );
};
