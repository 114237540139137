import { useMediaQuery, useTheme } from "@chakra-ui/react";

interface UseBreakpointValueReturn {
  isBaseBreakpoint: boolean;
  isLargeBreakpoint: boolean;
}

export const useBreakpointValue = (): UseBreakpointValueReturn => {
  const { breakpoints } = useTheme();
  const [isBaseBreakpoint, isLargeBreakpoint] = useMediaQuery([
    `(min-width: ${breakpoints.base})`,
    `(min-width: ${breakpoints.lg})`,
  ]);

  if (isLargeBreakpoint) {
    return { isBaseBreakpoint: false, isLargeBreakpoint: true };
  }

  return { isBaseBreakpoint: isBaseBreakpoint, isLargeBreakpoint: false };
};
