import * as React from "react";
import { Marker, OverlayViewF, MarkerProps } from "@react-google-maps/api";
import { Box } from "@chakra-ui/layout";
import { useColors } from "../../hooks/useColors";
import { Popover, PopoverBody, PopoverContent } from "@chakra-ui/popover";
import * as MarkerIcon from "../../images/markerIcon";
import * as LocationIcon from "../../images/locationIcon";
import * as ClusterIcon from "../../images/clusterIcon";
import { useBreakpointValue } from "../../hooks/useBreakpointValue";
import "./styles.css";
import * as Redux from "react-redux";
import { RootState } from "../../store";
import { useParams } from "react-router-dom";
import { Cluster } from "../../services/map";
import { Placement } from "../../types/Placement";

export type MarkerType = "placement" | "location" | "cluster";
export type Props = {
  markerType: MarkerType;
  label: string;

  cluster?: Cluster<Placement>;
  popoverContent?: React.ReactNode;
  url?: string;
  onClick?: () => void;
  isHighlighted?: boolean; // when hovering over an item in the list popover, not the marker itself
  eligible?: boolean;
} & MarkerProps;

const MARKER_WIDTH = 43;
export const MARKER_HEIGHT = 47;
const POPOVER_WIDTH = 275;

const HIGHLIGHTED_MARKER_ZINDEX = 3;
const LOCATION_MARKER_ZINDEX = 2;
const PLACEMENT_MARKER_ZINDEX = 1;
const CLUSTERED_MARKER_ZINDEX = 0;

export const CustomMarker = (props: Props): React.ReactElement => {
  const { selectedPlacementId } = Redux.useSelector(
    (root: RootState) => root.selectedPlacement
  );
  const { placementId } = useParams();
  const [isMarkerHovered, setIsMarkerHovered] = React.useState<boolean>(false);
  const [isMarkerSelected, setIsMarkerSelected] =
    React.useState<boolean>(false);
  const { primary, secondary } = useColors();
  const { isLargeBreakpoint } = useBreakpointValue();

  const isMarkerFocused =
    isMarkerHovered || isMarkerSelected || !!props.isHighlighted;

  const width =
    isMarkerHovered || isMarkerSelected ? MARKER_WIDTH : MARKER_WIDTH * 0.8;
  const height =
    isMarkerHovered || isMarkerSelected ? MARKER_HEIGHT : MARKER_HEIGHT * 0.8;

  const placementAlpha = !props.eligible ? 0.4 : 0.85;

  React.useEffect(() => {
    if (!props.cluster) return;
    const placementIDs: string[] = props.cluster.coordinates.map(
      (item) => item.id
    );
    const isSelected =
      placementIDs.includes(selectedPlacementId ?? "") ||
      placementIDs.includes(placementId ?? "");
    setIsMarkerSelected(isSelected);
  }, [props.cluster, selectedPlacementId, placementId]);

  const iconURL = (markerType: MarkerType, isFocused: boolean): string => {
    switch (markerType) {
      case "location": {
        const fill = isFocused ? primary : secondary;
        return LocationIcon.url(fill, width, height);
      }
      case "placement": {
        const fill = isFocused ? secondary : primary;
        return MarkerIcon.url(fill, width, height, placementAlpha);
      }
      case "cluster": {
        const fill = isFocused ? secondary : primary;
        return ClusterIcon.url(props.label, fill, width, height);
      }
    }
  };

  const zIndex = (markerType: MarkerType, isFocused: boolean): number => {
    if (isFocused) return HIGHLIGHTED_MARKER_ZINDEX;
    switch (markerType) {
      case "location": {
        return LOCATION_MARKER_ZINDEX;
      }
      case "placement": {
        return PLACEMENT_MARKER_ZINDEX;
      }
      case "cluster": {
        return CLUSTERED_MARKER_ZINDEX;
      }
    }
  };

  const animation = (
    markerType: MarkerType,
    isHighlighted: boolean
  ): google.maps.Animation | undefined => {
    switch (markerType) {
      case "location": {
        return google.maps.Animation.DROP;
      }
      case "placement": {
        return isHighlighted ? google.maps.Animation.BOUNCE : undefined;
      }
      case "cluster": {
        return isHighlighted ? google.maps.Animation.BOUNCE : undefined;
      }
    }
  };

  return (
    <>
      <Marker
        {...props}
        zIndex={zIndex(props.markerType, isMarkerFocused)}
        options={{ optimized: false }}
        onClick={props.onClick ? props.onClick : undefined}
        icon={{
          url: iconURL(props.markerType, isMarkerFocused),
          size: new google.maps.Size(width, height),
          anchor: new google.maps.Point(width / 2, height),
        }}
        onMouseOver={() => setIsMarkerHovered(true)}
        onMouseOut={() => setIsMarkerHovered(false)}
        label={{
          text: props.label,
          className: "custom-marker-label-hidden",
        }}
        animation={animation(props.markerType, !!props.isHighlighted)}
      />
      {isLargeBreakpoint &&
        isMarkerFocused &&
        props.markerType !== "location" && (
          <OverlayViewF mapPaneName="floatPane" position={props.position}>
            <Popover placement="top" isOpen={true}>
              {props.markerType === "placement" && !!props.popoverContent && (
                <Box transform={`translate(-${POPOVER_WIDTH / 2}px, 0px)`}>
                  <PopoverContent width={POPOVER_WIDTH}>
                    <PopoverBody>{props.popoverContent}</PopoverBody>
                  </PopoverContent>
                </Box>
              )}
            </Popover>
          </OverlayViewF>
        )}
    </>
  );
};
