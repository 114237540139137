import * as React from "react";
import { Box, Tooltip, Icon } from "@chakra-ui/react";
import { ContentItem } from "../types/UIConfig";
import { RiQuestionLine } from "react-icons/ri";
import { useColors } from "../hooks/useColors";

type Props = { item: ContentItem };
export const ContentItemLabel = ({ item }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { secondary } = useColors();
  if (!item.label) return null;
  const label = (
    <Box
      as="span"
      fontWeight="semibold"
      marginRight={1}
      alignItems="center"
      display="inline-flex"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      onClick={() => setIsOpen(true)}
    >
      {item.label}
      {item.helpText && (
        <Icon
          as={RiQuestionLine}
          height={5}
          width={5}
          color={secondary}
          marginLeft={1}
        />
      )}
      :
    </Box>
  );
  return item.helpText !== undefined ? (
    <Tooltip
      label={item.helpText}
      placement="top-start"
      isOpen={isOpen}
      background="gray.900"
      color="white"
      padding={4}
    >
      {label}
    </Tooltip>
  ) : (
    label
  );
};
