import * as React from "react";
import { motion, MotionProps } from "framer-motion";
import { Box, BoxProps, usePrefersReducedMotion } from "@chakra-ui/react";

const BoxWithForwardRef = React.forwardRef<any>((props: Props, ref) => {
  return (
    <Box {...props} ref={ref}>
      {props.children}
    </Box>
  );
});

const MotionBoxPrimitive = motion<BoxProps>(BoxWithForwardRef);

type Props = BoxProps &
  MotionProps & {
    forwardedref?: React.MutableRefObject<any>;
  };

export const MotionBox = ({
  children,
  ...props
}: Props): React.ReactElement => {
  const prefersReducedMotion = usePrefersReducedMotion();

  return (
    <MotionBoxPrimitive
      {...props}
      ref={props.forwardedref}
      layout={prefersReducedMotion ? false : props.layout}
    >
      {children}
    </MotionBoxPrimitive>
  );
};
