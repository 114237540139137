import * as React from "react";
import { Alert, Flex, Button, FlexProps } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useIntl } from "react-intl";
import { AnimatePresence } from "framer-motion";
import { FadeIn } from "../../../components/animation/FadeIn";
import { RemoteData } from "../../../store/messaging";
import { MessageItem } from "../../../types/Message";
import { useConfig } from "../../../hooks/useConfig";
import { useFocusStyle } from "../../../hooks/useFocusStyle";

interface Props extends Omit<FlexProps, "onSubmit"> {
  onSubmit: (items: MessageItem[]) => void;
  buttonLabel: string;
  buttonLoadingText: string;
  successMessage: string;
  errorMessage: string;
  message: RemoteData;
  isValid: boolean;
}

export const MessageForm = ({
  buttonLabel,
  onSubmit,
  successMessage,
  errorMessage,
  buttonLoadingText,
  message,
  children,
  isValid,
  ...props
}: Props) => {
  const intl = useIntl();
  const { saved, placements } = useSelector((state: RootState) => state);
  const config = useConfig();
  const isLoading = message.status === "loading";
  const focusStyle = useFocusStyle({ position: "offset" });

  return (
    <Flex
      boxShadow="dark-lg"
      background="gray.100"
      padding={3}
      gridGap={3}
      as="form"
      flexDirection="column"
      onSubmit={(event) => {
        if (placements.status === "loading") return;
        if (saved.ids.length === 0) return;
        if (!config) return;

        const savedPlacements = saved.ids
          .map((id: string) => {
            const placement = placements.byId[id];
            const name = placement.name;
            return {
              id,
              name: name || "",
            };
          })
          .filter((item: MessageItem) => item.name !== "");

        onSubmit(savedPlacements);
        event.preventDefault();
      }}
      {...props}
    >
      <Flex gridGap={3}>
        {children}
        <Button
          type="submit"
          variant="secondary"
          isLoading={isLoading}
          isDisabled={!isValid}
          loadingText={intl.formatMessage({ id: buttonLoadingText })}
          sx={focusStyle}
        >
          {intl.formatMessage({ id: buttonLabel })}
        </Button>
      </Flex>
      <AnimatePresence>
        {message.status === "finished" && (
          <FadeIn>
            <Alert status="success">
              {intl.formatMessage({ id: successMessage })}
            </Alert>
          </FadeIn>
        )}
        {message.status === "failure" && (
          <FadeIn>
            <Alert status="error">
              {intl.formatMessage({ id: errorMessage })}
            </Alert>
          </FadeIn>
        )}
      </AnimatePresence>
    </Flex>
  );
};
