import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MultipleFieldState {
  type: "multiple";
  fieldName: string;
  selected: string[];
}

export interface DistanceFieldState {
  type: "distance";
  fieldName: "distance";
  selected: number;
}

export type FieldState = MultipleFieldState | DistanceFieldState;

export interface FilterSliceState {
  byFieldName: { [key: string]: FieldState };
}

const initialState: FilterSliceState = {
  byFieldName: {},
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setFilterField(state, action: PayloadAction<FieldState>) {
      const { payload } = action;
      state.byFieldName[payload.fieldName] = payload;
    },
    removeFilterField(state, action: PayloadAction<{ fieldName: string }>) {
      delete state.byFieldName[action.payload.fieldName];
    },
    clearFilters() {
      return initialState;
    },
  },
});

const { reducer } = filterSlice;

export const { setFilterField, removeFilterField, clearFilters } =
  filterSlice.actions;

export default reducer;
