import * as React from "react";
import { GoogleMapProps, useLoadScript } from "@react-google-maps/api";
import { useQueryParams } from "../../hooks/useQueryParams";
import { GMapContents } from "./components/GMapContents";
import { PlacementId } from "../../types/Placement";
import { GMapPlaceAddress } from "./components/GMapPlaceAddress";
import { GMapAutoFitBounds } from "./components/GMapAutoFitBounds";
import { GeoJSONLayer } from "./components/GeoJSONLayer";
import { GoogleMap2 as GMap } from "./components/GoogleMap2";

const libraries: Array<"places"> = ["places"];
interface Props extends GoogleMapProps {
  height: number;
  onLoad: (map: google.maps.Map) => void;
  onPlacementMarkerClick: (
    placementId: PlacementId,
    position: google.maps.LatLngLiteral
  ) => void;
  onBoundsChange: () => void;
}

export const GoogleMap = React.memo(
  ({
    height,
    onLoad: onLoadProp,
    onBoundsChange: onBoundsChangeProp,
    onPlacementMarkerClick,
    options: customOptions,
    ...props
  }: Props): React.ReactElement | null => {
    const [map, setMap] = React.useState<google.maps.Map>();
    const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
      libraries,
    });

    const { place_id: placeIdParam } = useQueryParams();

    const handleLoad = React.useCallback(
      (mapParam: google.maps.Map) => {
        onLoadProp(mapParam);
        setMap(mapParam);
      },
      [onLoadProp]
    );

    const handleBoundsChanged = React.useCallback(() => {
      onBoundsChangeProp();
    }, [onBoundsChangeProp]);

    const options: google.maps.MapOptions = React.useMemo(
      () => ({
        gestureHandling: "greedy",
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
        ],
        ...customOptions,
      }),
      [customOptions]
    );

    const renderMap = React.useMemo(
      () => (
        <GMap
          mapContainerStyle={{
            width: "100%",
            height: `${height}px`,
          }}
          mapContainerClassName="avela-explore-google-map"
          options={options}
          onLoad={handleLoad}
          onDragEnd={handleBoundsChanged}
          onZoomChanged={handleBoundsChanged}
          clickableIcons={false}
          {...props}
        >
          <GMapContents onPlacementMarkerClick={onPlacementMarkerClick} />
          <GMapPlaceAddress map={map} placeId={placeIdParam} />
          <GMapAutoFitBounds map={map} />
          <GeoJSONLayer />
        </GMap>
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [handleBoundsChanged, handleLoad, height, options]
    );

    if (!isLoaded) return null;
    if (loadError) return null;
    return renderMap;
  }
);
