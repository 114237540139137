import { Button, Link } from "@chakra-ui/react";

interface CheckEligibilityLinkProps {
  continueButtonText: string;
  onClick: () => void;
}

export const CheckEligibilityLink = ({
  continueButtonText,
  onClick,
}: CheckEligibilityLinkProps) => {
  return (
    <>
      <Button
        as={Link}
        variant="link"
        aria-label={continueButtonText}
        onClick={onClick}
        fontWeight="600"
        textDecoration="underline"
        colorScheme="black"
      >
        {continueButtonText}
      </Button>
    </>
  );
};
