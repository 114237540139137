import { useSelector } from "react-redux";
import { RootState } from "../store";
import { UIConfig } from "../types/UIConfig";

export const useConfig = (): UIConfig | undefined => {
  const config = useSelector((state: RootState) => state.config);

  if (config.state !== "finished") return undefined;

  return config.values;
};
