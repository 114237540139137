import Icon from "@chakra-ui/icon";
import { Box, HStack, Text } from "@chakra-ui/layout";
import React from "react";
import { IconType } from "react-icons/lib";
import {
  RiAccountCircleLine,
  RiBusFill,
  RiInformationLine,
  RiMapPinLine,
  RiPhoneLine,
  RiTimeLine,
  RiGlobalLine,
  RiTeamLine,
  RiFilePaper2Line,
  RiBuildingLine,
  RiAwardLine,
  RiPushpinLine,
} from "react-icons/ri";
import { useColors } from "../../../hooks/useColors";
import { KeyInfo, KeyInfoTypes } from "../../../types/UIConfig";
import { FormattedContentItem } from "./FormattedContentItem";

function typeToIcon(type: KeyInfoTypes): IconType {
  switch (type) {
    case "location":
      return RiMapPinLine;
    case "contact":
      return RiPhoneLine;
    case "principal":
      return RiAccountCircleLine;
    case "hours":
      return RiTimeLine;
    case "bus":
      return RiBusFill;
    case "website":
      return RiGlobalLine;
    case "group":
      return RiTeamLine;
    case "paper":
      return RiFilePaper2Line;
    case "building":
      return RiBuildingLine;
    case "other":
      return RiInformationLine;
    case "award":
      return RiAwardLine;
    case "pushpin":
      return RiPushpinLine;
  }
}

interface KeyInfoItemProps {
  keyInfo: KeyInfo;
  value: string;
}

export const KeyInfoItem = (props: KeyInfoItemProps): React.ReactElement => {
  const { keyInfo, value } = props;
  const { type, label } = keyInfo;

  const { secondary } = useColors();

  const icon: IconType = React.useMemo(() => typeToIcon(type), [type]);

  return (
    <HStack lineHeight={6} align="top" letterSpacing="sm" paddingRight={5}>
      <Icon as={icon} color={secondary} height={{ base: 4 }} marginTop={1} />
      <Text fontSize="sm" width="100%">
        {label && (
          <Box as="span" fontWeight="semibold" marginRight={1}>
            {label}:
          </Box>
        )}
        <FormattedContentItem value={value} contentItem={keyInfo} />
      </Text>
    </HStack>
  );
};
