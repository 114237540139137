import { Placement, PlacementDataValue } from "../types/Placement";
import { ContentItem } from "../types/UIConfig";

export function getPlacementDataValue(
  placement: Placement,
  contentItemOrKey: ContentItem | string
): PlacementDataValue | undefined {
  if (typeof contentItemOrKey === "string") {
    return placement?.details?.[contentItemOrKey];
  }
  switch (contentItemOrKey.contentType) {
    case "static":
      return contentItemOrKey.value;
    case "field":
    case undefined:
      if (contentItemOrKey.displayTransformation) {
        return getTransformedContentItem(placement, contentItemOrKey);
      }
      return placement?.details?.[contentItemOrKey.fieldName];
  }
}

export function getPlacementStringValue(
  placement: Placement,
  contentItemOrKey: ContentItem | string
): string | undefined {
  const dataValue = getPlacementDataValue(placement, contentItemOrKey);
  if (!dataValue) {
    return "";
  } else if (Array.isArray(dataValue)) {
    return String(dataValue.join(", "));
  } else {
    return String(dataValue);
  }
}

export function getPlacementNumberValue(
  placement: Placement,
  contentItemKey: ContentItem | string
): number | undefined {
  return isNaN(Number(getPlacementDataValue(placement, contentItemKey)))
    ? undefined
    : Number(getPlacementDataValue(placement, contentItemKey));
}

const getTransformedContentItem = (
  placement: Placement,
  contentItem: ContentItem
): string | undefined => {
  if (
    contentItem.contentType !== "field" &&
    contentItem.contentType !== undefined
  )
    return;

  switch (contentItem.displayTransformation) {
    case "range":
      const placementDataValue = placement?.details?.[contentItem.fieldName];
      if (Array.isArray(placementDataValue)) {
        let rangeArray = [];
        if (placementDataValue.length > 0) {
          rangeArray.push(placementDataValue[0]);
        }

        if (placementDataValue.length > 1) {
          rangeArray.push(placementDataValue[placementDataValue.length - 1]);
        }

        return rangeArray.join(" - ");
      }
      return String(placementDataValue);
  }
};
