import { useFormikContext } from "formik";
import { useEffect, useMemo } from "react";
import { EligibilityForm } from "../services/eligibility";

type Props = {
  formTemplateId: string;
};
export const usePersistEligibilityAnswersForFormik = ({
  formTemplateId,
}: Props) => {
  const { values } = useFormikContext<EligibilityForm>();
  const key = useMemo(() => generateKey(formTemplateId), [formTemplateId]);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(values));
  }, [key, values]);
};

export const loadPersistedEligibilityAnswers = (
  formTemplateId: string
): EligibilityForm | undefined => {
  const payload = localStorage.getItem(generateKey(formTemplateId));
  if (!payload || !formTemplateId) return undefined;
  return JSON.parse(payload);
};

const generateKey = (formTemplateId: string): string => {
  return `eligibility-answers-${formTemplateId}`;
};
