import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  RadioProps,
  Text,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";
import { useField } from "formik";
import { RemoteData } from "../../types/remoteData";
import * as RD from "../../types/remoteData";
import { RemoteDataView } from "../RemoteDataView";
import { GenericError } from "../Feedback/GenericError";

type RadioOption = {
  label: string;
  value: string;
  description?: string;
};

type Props = {
  name: string;
  label: string;
  options: RemoteData<Error, RadioOption[]>;
  emptyLabel?: string;
};
function RadioCard(props: RadioProps) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label" flex="1" maxWidth="50%" boxSizing="border-box">
      <input {...input} />
      <Flex
        {...checkbox}
        direction="column"
        cursor="pointer"
        borderWidth="2px"
        borderRadius="md"
        _checked={{
          bg: "white",
          color: "primary.500",
          background: "primary.50",
          borderColor: "primary.500",
        }}
        p={4}
        align="center"
        justifyContent="center"
      >
        {props.children}
      </Flex>
    </Box>
  );
}
export const RadioGroup = ({ name, label, options, emptyLabel }: Props) => {
  const [field, meta, helper] = useField(name);

  const { getRootProps, getRadioProps } = useRadioGroup({
    value: field.value,
    name,
    onChange: (value) => helper.setValue(value),
  });

  const group = getRootProps();

  return (
    <FormControl
      as={Flex}
      direction="column"
      gap={4}
      isInvalid={Boolean(meta.error) && !RD.isLoading(options)}
    >
      <FormLabel>{label}</FormLabel>
      <RemoteDataView remoteData={options} error={(error) => <GenericError />}>
        {(data) => {
          return data.length ? (
            <Flex {...group} flexWrap="wrap" gap={2}>
              {data.map((option) => {
                const radio = getRadioProps({ value: option.value });
                return (
                  <RadioCard key={option.value} {...radio}>
                    <Flex direction="column" align="center">
                      <Text fontWeight={700}>{option.label}</Text>
                      <Text textAlign="center">{option.description}</Text>
                    </Flex>
                  </RadioCard>
                );
              })}
            </Flex>
          ) : (
            <Flex color="blackAlpha.500">{emptyLabel}</Flex>
          );
        }}
      </RemoteDataView>
      {meta.error && <FormErrorMessage>{meta.error}</FormErrorMessage>}
    </FormControl>
  );
};
