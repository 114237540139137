import { Button } from "@chakra-ui/react";

export type AllowedVariants = "outline" | "ghost";
export interface CheckEligibilityButtonProps {
  variant: AllowedVariants;
  isContinueDisabled?: boolean;
  buttonText: string;
  onClick: () => void;
}
export const CheckEligibilityButton = ({
  variant,
  isContinueDisabled,
  buttonText,
  onClick,
}: CheckEligibilityButtonProps) => {
  return (
    <>
      <Button
        variant={variant}
        color="primary.500"
        aria-label="Check eligibility"
        onClick={onClick}
        _focusVisible={{ outline: "none" }}
        isDisabled={isContinueDisabled}
      >
        {buttonText}
      </Button>
    </>
  );
};
