import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ModalsSliceType = {
  showEligibilityWelcomeModal: boolean;
  showFirstSavedSchoolModal: boolean;
  showCheckEligibilityModal: boolean;
};
const initialState: ModalsSliceType = {
  showEligibilityWelcomeModal: false,
  showFirstSavedSchoolModal: false,
  showCheckEligibilityModal: false,
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setShowFirstSavedSchoolModal: (state, action: PayloadAction<boolean>) => {
      state.showFirstSavedSchoolModal = action.payload;
    },
    setShowEligibilityWelcomeModal: (state, action: PayloadAction<boolean>) => {
      state.showEligibilityWelcomeModal = action.payload;
    },
    setShowCheckEligibilityModal: (state, action: PayloadAction<boolean>) => {
      state.showCheckEligibilityModal = action.payload;
    },
  },
});

export const {
  setShowFirstSavedSchoolModal,
  setShowEligibilityWelcomeModal,
  setShowCheckEligibilityModal,
} = modalsSlice.actions;
export default modalsSlice.reducer;
