import * as React from "react";
import {
  Slider,
  Text,
  FormControl,
  FormLabel,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderProps,
  Box,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { DistanceFilter as DistanceFilterType } from "../../../types/UIConfig";
import { removeFilterField, setFilterField } from "../../../store/filter";
import { RootState } from "../../../store";

type OwnProps = {
  filter: DistanceFilterType;
  selectedValue: number | undefined;
  disabledReason?: React.ReactNode;
};

type Props = Omit<SliderProps, keyof OwnProps> & OwnProps;

export const DistanceFilter = ({
  filter,
  selectedValue,
  disabledReason,
  ...sliderProps
}: Props) => {
  const step = filter.step ?? 0.5;
  const any = filter.maxMiles + step;

  const value = useSelector(
    (state: RootState) => state.filter.byFieldName?.distance?.selected
  );
  const [distance, setDistance] = React.useState(any);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (
      value === undefined ||
      Array.isArray(value) ||
      typeof value !== "number"
    ) {
      setDistance(any);
    } else {
      setDistance(value);
    }
  }, [value, any]);

  const handleChangeEnd = (finalDistance: number) => {
    if (finalDistance === any) {
      dispatch(
        removeFilterField({
          fieldName: filter.fieldName,
        })
      );
    } else {
      dispatch(
        setFilterField({
          type: "distance",
          fieldName: filter.fieldName,
          selected: finalDistance,
        })
      );
    }
  };

  return (
    <FormControl>
      <FormLabel>{filter.label}</FormLabel>
      {sliderProps.isDisabled && disabledReason && (
        <Text fontSize="sm" color="gray.700">
          {disabledReason}
        </Text>
      )}
      <Slider
        max={any}
        value={distance}
        onChange={(val) => setDistance(val)}
        onChangeEnd={handleChangeEnd}
        marginTop={5}
        step={step}
        size="lg"
        {...sliderProps}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb position="relative">
          <Box
            position="absolute"
            bottom="calc(100% + 0.25em)"
            whiteSpace="nowrap"
            fontSize="small"
          >
            {distance === any ? (
              <FormattedMessage id="ANY" />
            ) : (
              <FormattedMessage id="MILES_SHORT_LABEL" values={{ distance }} />
            )}
          </Box>
        </SliderThumb>
      </Slider>
    </FormControl>
  );
};
