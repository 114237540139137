import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/index";
import { Panel } from "../store/openedPanel";
import {
  clearSelectedPlacement,
  setSelectedPlacement,
} from "../store/selectedPlacement";
import * as OpenedPanel from "../store/openedPanel";
import { useNavigate, useLocation } from "react-router-dom";
import { gotoHome, gotoProfile } from "../services/routing";
import { PlacementId } from "../types/Placement";
export type { TopPanel, BottomPanel, Panel } from "../store/openedPanel";

export const usePanelDisclosure = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const openedPanel = useSelector((state: RootState) => state.openedPanel);

  const isOpen = React.useCallback(
    (panel: Panel) => {
      return openedPanel[panel];
    },
    [openedPanel]
  );

  const open = React.useCallback(
    (panel: Exclude<Panel, "placementProfile" | "miniPlacementCard">) => {
      dispatch(OpenedPanel.open(panel));
    },
    [dispatch]
  );

  const close = React.useCallback(
    (panel: Exclude<Panel, "placementProfile" | "miniPlacementCard">) => {
      dispatch(OpenedPanel.close(panel));
    },
    [dispatch]
  );

  const closeTopPanel = React.useCallback(() => {
    dispatch(OpenedPanel.closeTopPanel());
  }, [dispatch]);

  const openPlacementProfile = React.useCallback(
    (placementId: PlacementId) => {
      gotoProfile(navigate, location)(placementId);
      dispatch(setSelectedPlacement(placementId));
      dispatch(OpenedPanel.open("placementProfile"));
      dispatch(OpenedPanel.close("filter"));
      dispatch(OpenedPanel.close("miniPlacementCard"));
    },
    [dispatch, navigate, location]
  );

  const closePlacementProfile = React.useCallback(() => {
    gotoHome(navigate, location);
    dispatch(clearSelectedPlacement());
    dispatch(OpenedPanel.close("placementProfile"));
  }, [dispatch, navigate, location]);

  const openMiniPlacementCard = React.useCallback(
    (placementId: PlacementId) => {
      dispatch(setSelectedPlacement(placementId));
      dispatch(OpenedPanel.open("miniPlacementCard"));
    },
    [dispatch]
  );

  const closeMiniPlacementCard = React.useCallback(() => {
    dispatch(clearSelectedPlacement());
    dispatch(OpenedPanel.close("miniPlacementCard"));
  }, [dispatch]);

  const closeAll = React.useCallback(() => {
    dispatch(OpenedPanel.closeAll());
  }, [dispatch]);

  return {
    isOpen,
    close,
    open,
    closeTopPanel,
    openPlacementProfile,
    closePlacementProfile,
    openMiniPlacementCard,
    closeMiniPlacementCard,
    closeAll,
  };
};
