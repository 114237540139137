import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { GeoJSON } from "geojson";
// import { boundary } from "../boundaries/nola";
// import { boundary } from "../boundaries/hartford";
import { UIConfig } from "../types/UIConfig";

export type BoundaryMap = { [boundaryType: string]: GeoJSON };

type BoundarySliceState =
  | { state: "loading" }
  | { state: "empty" }
  | { state: "finished"; values: BoundaryMap };
const initialState: BoundarySliceState = {
  state: "loading",
} as BoundarySliceState;

export const fetchBoundaryRaw = async (
  config: UIConfig
): Promise<BoundaryMap | null> => {
  // return Promise.resolve(boundary);

  if (config === undefined || config.boundary === undefined) return null;
  const boundaries = await Promise.all(
    config.boundary.values.map(async (boundary) => {
      const { data } = await axios.get(boundary.url);
      const geojson = data;
      return { [boundary.config.boundaryType]: geojson };
    })
  );

  return boundaries.reduce((prev, current) => ({ ...current, ...prev }), {});
};

export const fetchBoundary = createAsyncThunk(
  "boundary/get",
  async (config: UIConfig, thunkAPI): Promise<BoundaryMap | null> => {
    return await fetchBoundaryRaw(config);
  }
);

const boundarySlice = createSlice({
  name: "boundary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBoundary.fulfilled, (state, action) => {
      return action.payload === null
        ? { state: "empty" }
        : { state: "finished", values: action.payload };
    });
  },
});

const { reducer } = boundarySlice;
export default reducer;
