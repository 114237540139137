import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum SortTypeEnum {
  ALPHABETICAL = "alphabetical",
  DISTANCE = "distance",
  BOUNDARY = "boundary",
  ELIGIBILITY = "eligibility",
}

type SortSliceState = {
  type: SortTypeEnum;
};

const initialState: SortSliceState = {
  type: SortTypeEnum.ALPHABETICAL,
};

const sortSlice = createSlice({
  name: "sort",
  initialState,
  reducers: {
    setSortType(state, action: PayloadAction<SortTypeEnum>) {
      const { payload } = action;
      state.type = payload;
    },
  },
});

const { reducer } = sortSlice;

export const { setSortType } = sortSlice.actions;

export default reducer;
