import React from "react";
import { useSelector } from "react-redux";
import { useConfig } from "../../../hooks/useConfig";
import { getPlacementNumberValue } from "../../../services/transformData";
import { RootState } from "../../../store";
import {
  calculateAcceptanceProbability,
  getStepForProbability,
} from "../services/demandMeter";

export type Step = 1 | 2 | 3 | 4 | 5;

export const useStep = (): Step | undefined => {
  const placements = useSelector((state: RootState) => state.placements);
  const savedIds = useSelector((state: RootState) => state.saved.ids);
  const config = useConfig();

  const meter = config?.savedList?.meter;

  const individualProbabilities = React.useMemo(() => {
    if (
      placements.status !== "finished" ||
      meter === undefined ||
      savedIds.length === 0
    )
      return undefined;

    const probabilities: number[] = [];

    savedIds.forEach((id) => {
      const placement = placements.byId[id];
      if (placement) {
        const probability = getPlacementNumberValue(placement, meter.fieldName);
        if (probability) {
          probabilities.push(probability);
        }
      }
    });

    return probabilities;
  }, [placements, meter, savedIds]);

  const acceptanceProbability = React.useMemo(() => {
    if (individualProbabilities === undefined || meter === undefined)
      return undefined;

    const { minimumDesired, defaultAcceptanceRate, individualScalingFactor } =
      meter;

    return calculateAcceptanceProbability(individualProbabilities, {
      minimumDesired,
      defaultAcceptanceRate,
      individualScalingFactor,
    });
  }, [individualProbabilities, meter]);

  const step = React.useMemo(() => {
    if (acceptanceProbability === undefined) return undefined;
    return getStepForProbability(acceptanceProbability);
  }, [acceptanceProbability]);

  return step;
};
