import { Polygon, PolygonProps } from "@react-google-maps/api";
import { useColors } from "../../../hooks/useColors";
import { Boundary } from "../../../types/Boundary";

type Props = {
  boundary: Boundary;
  color?: string | null;
} & PolygonProps;
export function BoundaryPolygon({ color, boundary, ...props }: Props) {
  const { secondary } = useColors();
  let fillOpacity = 0;
  switch (boundary.type) {
    case "address":
      fillOpacity = 0.25;
  }

  return (
    <Polygon
      options={{
        fillColor: color ?? secondary,
        fillOpacity,
        strokeWeight: 1,
        strokeColor: color ?? secondary,
      }}
      paths={boundary.polygon.getPaths()}
      {...props}
    />
  );
}
