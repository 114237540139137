import axios from "axios";

export type Organization = {
  path: string;
  title: string;
  apply_path: string;
};

export const DEFAULT_ORGANIZATION_PATH = "demo";

export const fetchOrganization = async (
  hostname: string
): Promise<Organization> => {
  // comment this out to bypass hostname lookup.
  // return {
  //   path: "boston",
  //   title: "Boston testing",
  //   apply_path: "boston",
  // };
  const URL = process.env.REACT_APP_PIPELINE_ENDPOINT;
  if (!URL) return Promise.reject();

  const path = `${URL}/lookup`;
  return await axios.post(path, { hostname: hostname }).then(({ data }) => {
    return data;
  });
};
