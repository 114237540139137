import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type LatLngBoundsSliceType = {
  bounds: google.maps.LatLngBoundsLiteral | undefined;
};
const initialState: LatLngBoundsSliceType = {
  bounds: undefined,
};

const latLngBoundsSlice = createSlice({
  name: "latLngBounds",
  initialState,
  reducers: {
    setBounds: (
      state,
      action: PayloadAction<google.maps.LatLngBoundsLiteral>
    ) => {
      state.bounds = action.payload;
    },
    clearBounds: (state) => {
      state.bounds = undefined;
    },
  },
});

export const { setBounds, clearBounds } = latLngBoundsSlice.actions;
export default latLngBoundsSlice.reducer;
