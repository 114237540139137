import * as React from "react";

export function useInlineSizeObserver(onChange?: (inlineSize: number) => void) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (onChange === undefined || ref.current === null) return;
    const resizeObserver = new ResizeObserver(([update]) => {
      if (update && update.borderBoxSize && update.borderBoxSize.length > 0) {
        onChange(update.borderBoxSize[0].inlineSize);
      }
    });

    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [onChange, ref]);

  return { ref };
}

export function useInlineSizeObserverForElement(
  element: Element | null,
  onChange?: (inlineSize: number) => void
) {
  React.useEffect(() => {
    if (onChange === undefined || element === null) return;
    const resizeObserver = new ResizeObserver(([update]) => {
      if (update && update.borderBoxSize && update.borderBoxSize.length > 0) {
        onChange(update.borderBoxSize[0].inlineSize);
      }
    });

    resizeObserver.observe(element);
    return () => {
      resizeObserver.disconnect();
    };
  }, [onChange, element]);
}
