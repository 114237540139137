import { Card, Flex, Text } from "@chakra-ui/react";
import { TipTag } from "./TipTag";
import { useEffect, useState } from "react";

export const EligibilityHint = () => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(false);
    }, 5000);
  }, []);
  return (
    <Card
      display={isOpen ? "block" : "none"}
      p={6}
      position="absolute"
      zIndex={99}
      m={2}
    >
      <Flex direction="column" gap={2}>
        <TipTag />
        <Text fontWeight={700}>Seeing eligible schools only</Text>
        <Text>Change this anytime by using the Filter</Text>
      </Flex>
    </Card>
  );
};
