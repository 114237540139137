import * as React from "react";
import { PlacementList } from "./PlacementList";
import { PlacementProfile } from "./PlacementProfile";
import { SlideIn } from "../components/animation/SlideIn";
import { Map } from "./Map";
import { MapListButton } from "../components/MapListButton";
import { ControlBar } from "./ControlBar";
import { Filter } from "./Filter";
import { Search } from "./Search";
import { SavedPlacements } from "./SavedPlacements";
import { usePanelDisclosure, TopPanel } from "../hooks/usePanelDislosure";
import { Box, Flex } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { FadeIn } from "../components/animation/FadeIn";
import { MiniPlacementCard } from "../components/MiniPlacementCard";

const zIndexScene = 0;
const zIndexButton = 1;
const zIndexOverlay = 2;
const zIndexFullscreen = 2;
const MINI_CARD_HEIGHT = 120;

export const SmallScreen = (): React.ReactElement => {
  const {
    isOpen,
    closeTopPanel,
    open,
    close,
    openPlacementProfile,
    openMiniPlacementCard,
    closeMiniPlacementCard,
  } = usePanelDisclosure();

  const [map, setMap] = React.useState<google.maps.Map>();

  const initializePanel = React.useCallback(() => {
    open("map");
  }, [open]);

  React.useEffect(() => {
    initializePanel();
  }, [initializePanel]);

  const toggle = (panel: TopPanel) => {
    if (isOpen(panel)) {
      close(panel);
    } else {
      closeTopPanel();
      closeMiniPlacementCard();
      open(panel);
    }
  };

  const handleLoadMap = (loadedMap: google.maps.Map) => {
    setMap(loadedMap);
  };

  return (
    <Box height="100%" width="100%" overflow="hidden" position="relative">
      <AnimatePresence initial={false}>
        <Flex
          key="mainWithPanel"
          direction="column"
          as="main"
          flexGrow={1}
          width="100%"
          height="100%"
          flexWrap="nowrap"
          overflow="hidden"
          position="relative"
        >
          <ControlBar flexGrow={0} isOpen={isOpen} toggle={toggle} map={map} />
          <Box flexGrow={1} width="100%" overflow="hidden" position="relative">
            <AnimatePresence initial={false}>
              {isOpen("map") && (
                <FadeIn key="map">
                  <Map
                    map={map}
                    onLoadMap={handleLoadMap}
                    onClick={closeMiniPlacementCard}
                    onPlacementMarkerClick={openMiniPlacementCard}
                    options={{ zoomControl: false }}
                    height="100%"
                    width="100%"
                    position="absolute"
                    top="0"
                    left="0"
                    zIndex={zIndexScene}
                  />
                </FadeIn>
              )}
              {isOpen("miniPlacementCard") && (
                <SlideIn
                  key="miniPlacementCard"
                  direction="bottom"
                  position="absolute"
                  zIndex={zIndexScene}
                  height={`${MINI_CARD_HEIGHT}px`}
                  bottom="0"
                  boxShadow="dark-lg"
                >
                  <MiniPlacementCard />
                </SlideIn>
              )}
              {isOpen("placementList") && (
                <SlideIn
                  key="placementList"
                  direction="bottom"
                  position="absolute"
                  zIndex={zIndexScene}
                >
                  <PlacementList onClick={openPlacementProfile} />
                </SlideIn>
              )}
              <MapListButton
                position="fixed"
                left="50%"
                bottom="0"
                transform={
                  isOpen("miniPlacementCard")
                    ? `translate(-50%, -${MINI_CARD_HEIGHT + 10}px)`
                    : "translate(-50%, -15px)"
                }
                transition="transform 0.25s"
                zIndex={zIndexButton}
              />
              {isOpen("filter") && (
                <SlideIn key="filter" direction="top" zIndex={zIndexOverlay}>
                  <Filter onClose={() => toggle("filter")} />
                </SlideIn>
              )}
              {isOpen("search") && (
                <SlideIn key="search" direction="top" height={20}>
                  <Search onClose={() => close("search")} />
                </SlideIn>
              )}
              {isOpen("savedPlacements") && (
                <SlideIn
                  direction="top"
                  key="savedPlacements"
                  zIndex={zIndexOverlay}
                >
                  <SavedPlacements onClose={() => close("savedPlacements")} />
                </SlideIn>
              )}
            </AnimatePresence>
          </Box>
        </Flex>
        {isOpen("placementProfile") && (
          <SlideIn
            key="placementProfile"
            zIndex={zIndexFullscreen}
            direction="left"
          >
            <PlacementProfile />
          </SlideIn>
        )}
      </AnimatePresence>
    </Box>
  );
};
