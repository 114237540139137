import React from "react";
import { useMapClusters } from "../../../hooks/useMapClusters";
import * as Map from "../../../services/map";
import { Placement } from "../../../types/Placement";

interface GMapAutoFitBoundsProps {
  map: google.maps.Map | undefined;
}

export const GMapAutoFitBounds = ({ map }: GMapAutoFitBoundsProps): null => {
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const clusters = useMapClusters();

  React.useEffect(() => {
    if (initialized) return;

    if (!clusters || !map) return;

    const bounds = new google.maps.LatLngBounds();

    clusters.forEach((cluster: Map.CoordinateOrCluster<Placement>) => {
      let latLng: google.maps.LatLng;
      if (Map.isCluster(cluster)) {
        latLng = new google.maps.LatLng({
          lat: cluster.coordinate.lat,
          lng: cluster.coordinate.lng,
        });
      } else {
        latLng = new google.maps.LatLng({
          lat: cluster.lat,
          lng: cluster.lng,
        });
      }

      bounds.extend(latLng);
    });

    map.fitBounds(bounds);

    setInitialized(true);
  }, [initialized, clusters, map]);

  return null;
};
