import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface OpenedTopPanel {
  filter: boolean;
  search: boolean;
  savedPlacements: boolean;
}

interface OpenedBottomPanel {
  map: boolean;
  placementList: boolean;
  miniPlacementCard: boolean;
  placementProfile: boolean;
}

type OpenedPanel = OpenedTopPanel & OpenedBottomPanel;

const initialTopPanelState: OpenedTopPanel = {
  filter: false,
  search: false,
  savedPlacements: false,
};

const initialBottomPanelState: OpenedBottomPanel = {
  map: false,
  placementList: false,
  miniPlacementCard: false,
  placementProfile: false,
};

const initialState = { ...initialBottomPanelState, ...initialTopPanelState };

export type Panel = keyof OpenedPanel;
export type TopPanel = keyof OpenedTopPanel;
export type BottomPanel = keyof OpenedBottomPanel;

const openedPanelSlice = createSlice({
  name: "openedPanel",
  initialState,
  reducers: {
    open(state, action: PayloadAction<Panel>) {
      state[action.payload] = true;
    },
    close(state, action: PayloadAction<Panel>) {
      state[action.payload] = false;
    },
    closeAll() {
      return initialState;
    },
    closeTopPanel(state) {
      return { ...state, ...initialTopPanelState };
    },
  },
});

export const { open, close, closeAll, closeTopPanel } =
  openedPanelSlice.actions;
export default openedPanelSlice.reducer;
