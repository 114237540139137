import * as React from "react";
import { MotionProps, Target, TargetAndTransition } from "framer-motion";
import { BoxProps, usePrefersReducedMotion } from "@chakra-ui/react";
import { MotionBox } from "./MotionBox";
import { FadeIn } from "./FadeIn";

type Direction = "bottom" | "left" | "right" | "top";

function closeTransition(direction: Direction): Target {
  switch (direction) {
    case "top":
      return { top: "-100%" };
    case "left":
      return { left: "-100%" };
    case "right":
      return { right: "-100%" };
    case "bottom":
      return { bottom: "-100%" };
  }
}

function openTransition(direction: Direction): TargetAndTransition {
  switch (direction) {
    case "top":
      return { top: "0%" };
    case "left":
      return { left: "0%" };
    case "right":
      return { right: "0%" };
    case "bottom":
      return { bottom: "0%" };
  }
}

type Props = BoxProps &
  MotionProps & {
    isDisabled?: boolean;
    direction: Direction;
  };

export const SlideIn = React.memo(
  ({
    children,
    isDisabled = false,
    direction = "bottom",
    ...props
  }: Props): React.ReactElement => {
    const ref = React.useRef<HTMLElement>();
    const prefersReduceMotion = usePrefersReducedMotion();

    if (isDisabled) {
      return <></>;
    }

    const allProps: BoxProps & MotionProps = {
      width: "100%",
      height: "100%",
      ...props,
    };

    if (prefersReduceMotion) {
      return <FadeIn {...props}>{children}</FadeIn>;
    }

    return (
      <MotionBox
        position="absolute"
        top={direction !== "bottom" ? "0" : undefined}
        left={direction === "left" ? "0" : undefined}
        right={direction === "right" ? "0" : undefined}
        bottom={direction !== "top" ? "0" : undefined}
        animate={openTransition(direction)}
        exit={closeTransition(direction)}
        initial={closeTransition(direction)}
        forwardedref={ref}
        transition={{ type: "tween" }}
        width="100%"
        height="100%"
        overflow="hidden"
        {...allProps}
      >
        {children}
      </MotionBox>
    );
  }
);
