import { Flex, Spinner, Text } from "@chakra-ui/react";

interface LoadingProps {
  text?: string;
}

export function Loading({ text }: LoadingProps) {
  return (
    <Flex
      top="0"
      left="0"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      direction="column"
      gap={4}
    >
      <Spinner color="primary.500" size="lg" />
      {text && <Text>{text}</Text>}
    </Flex>
  );
}
