import * as React from "react";
import { MotionProps } from "framer-motion";
import { BoxProps } from "@chakra-ui/react";
import { MotionBox } from "./MotionBox";

const initial = { opacity: "0%" };

type Props = BoxProps &
  MotionProps & {
    children: React.ReactNode;
    isDisabled?: boolean;
    delay?: number;
  };

export const FadeIn = ({
  children,
  isDisabled = false,
  delay = 0,
  ...props
}: Props): React.ReactElement => {
  if (isDisabled) {
    return <></>;
  }

  return (
    <MotionBox
      width="100%"
      height="100%"
      animate={{ opacity: "100%" }}
      exit={{ opacity: "0%" }}
      initial={initial}
      {...props}
    >
      {children}
    </MotionBox>
  );
};
