import React, { useMemo } from "react";
import { Boundary } from "../types/Boundary";
import {
  findBoundariesForPlacement,
  findBoundaryForAddress,
  geoJSONToBoundaries,
} from "../services/geoJSON";
import * as Redux from "react-redux";
import { RootState } from "../store";
import { useConfig } from "./useConfig";
import { usePlacementData } from "./usePlacementData";
import { Placement } from "../types/Placement";
import { BoundaryConfig, UIConfig } from "../types/UIConfig";
import { PlacementMap } from "../store/placements";
import { useParams } from "react-router-dom";

function getBoundaryConfig(
  config: UIConfig,
  boundaryType: string
): BoundaryConfig | undefined {
  if (config.boundary === undefined) return undefined;
  return config.boundary.values.find(
    (b) => b.config.boundaryType === boundaryType
  )?.config;
}

export function useAddressBoundary(): Boundary[] | null {
  const address = Redux.useSelector((root: RootState) => root.address);
  const boundary = Redux.useSelector((root: RootState) => root.boundary);
  const googleMap = Redux.useSelector((root: RootState) => root.googleMap);

  const config = useConfig();

  return React.useMemo(() => {
    if (address.status !== "finished") return null;
    if (config === undefined) return null;
    if (boundary.state !== "finished") return null;
    if (googleMap.status !== "finished") return null;

    const boundaryConfigs = config.boundary?.values.map((b) => b.config) ?? [];

    return findBoundaryForAddress(
      address.values,
      boundary.values,
      boundaryConfigs
    );
  }, [address, config, boundary, googleMap]);
}

export function useSelectedPlacementBoundaries(): Boundary[] | null {
  const { placementId } = useParams();
  const boundary = Redux.useSelector((root: RootState) => root.boundary);
  const config = useConfig();
  const { getPlacementBoundaries } = usePlacementData(placementId);
  const googleMap = Redux.useSelector((root: RootState) => root.googleMap);

  return React.useMemo(() => {
    if (config === undefined) return null;
    if (boundary.state !== "finished") return null;
    if (googleMap.status !== "finished") return null;

    const boundaryConfigs = config.boundary?.values.map((b) => b.config) ?? [];

    const boundaryIds = getPlacementBoundaries();
    if (!boundaryIds) return null;

    return findBoundariesForPlacement(
      boundaryIds,
      boundary.values,
      boundaryConfigs
    );
  }, [boundary, config, getPlacementBoundaries, googleMap]);
}

export function useAllBoundaries(): Boundary[] | null {
  const config = useConfig();
  const boundaryMap = Redux.useSelector((root: RootState) => root.boundary);
  return React.useMemo(() => {
    if (boundaryMap.state === "loading" || boundaryMap.state === "empty")
      return null;
    if (config === undefined) return null;

    const boundaryTypes: string[] = Object.keys(boundaryMap.values);
    const boundaries = boundaryTypes
      .map((boundaryKey: string) => {
        const boundaryConfig = getBoundaryConfig(config, boundaryKey);
        if (boundaryConfig === undefined) return null;
        const geoJSON = boundaryMap.values[boundaryKey];
        return geoJSONToBoundaries(boundaryConfig, geoJSON);
      })
      .filter((b: Boundary[] | null): b is Boundary[] => b !== null)
      .flat();
    return boundaries ?? [];
  }, [boundaryMap, config]);
}

function getBoundaryTag(
  placement: Placement,
  boundaries: Boundary[],
  config: UIConfig
): string[] {
  const boundaryMap = placement.boundaries;
  if (boundaryMap === undefined) {
    return [];
  }

  return boundaries
    .map((boundary) => {
      const boundaries = boundaryMap[boundary.boundaryType];
      if (boundaries && boundaries.includes(boundary.id)) {
        return getBoundaryConfig(config, boundary.boundaryType)?.tagLabel;
      }
      return undefined;
    })
    .filter(
      (label: string | undefined): label is string => label !== undefined
    );
}

export function useBoundaryTag(placement: Placement | undefined): string[] {
  const addressBoundaries: Boundary[] | null = useAddressBoundary();
  const config = useConfig();
  if (
    config === undefined ||
    addressBoundaries === null ||
    placement === undefined
  ) {
    return [];
  }

  return getBoundaryTag(placement, addressBoundaries, config);
}

export interface BoundaryTagsByPlacementId {
  [placementId: string]: string[];
}

export function useBoundaryTagsByPlacementId(
  placementsById: PlacementMap | undefined
): BoundaryTagsByPlacementId {
  const addressBoundaries: Boundary[] | null = useAddressBoundary();
  const config = useConfig();

  return useMemo(() => {
    if (!addressBoundaries) {
      return {};
    }
    if (placementsById === undefined || config === undefined) return {};

    const boundaryTagsByPlacementId: BoundaryTagsByPlacementId = {};
    Object.keys(placementsById).forEach((id) => {
      const placement = placementsById[id];
      boundaryTagsByPlacementId[id] = getBoundaryTag(
        placement,
        addressBoundaries,
        config
      );
    });
    return boundaryTagsByPlacementId;
  }, [addressBoundaries, config, placementsById]);
}
