import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { removeSaved } from "../../../store/saved";

export const useRemoveNonexistentPlacementIds = (ids: string[]): void => {
  const placements = useSelector((state: RootState) => state.placements);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (placements.status !== "finished") return;

    const placementIds = Object.keys(placements.byId);
    if (placementIds.length === 0) return;

    ids.forEach((id) => {
      if (!placementIds.includes(id)) {
        dispatch(removeSaved(id));
      }
    });
  }, [dispatch, placements, ids]);
};
