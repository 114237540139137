import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { SortTypeEnum } from "../store/sort";
import { useConfig } from "./useConfig";

export type SortOption = { disabled: boolean; sortType: SortTypeEnum };
export function useSort() {
  const address = useSelector((state: RootState) => state.address);
  const placements = useSelector((state: RootState) => state.placements);
  const config = useConfig();

  return React.useMemo(() => {
    let sortOptions: SortOption[] = [
      { disabled: false, sortType: SortTypeEnum.ALPHABETICAL },
      {
        disabled: address.status !== "finished",
        sortType: SortTypeEnum.DISTANCE,
      },
    ];

    if (config?.boundary?.sort === true) {
      sortOptions.push({
        disabled: address.status !== "finished",
        sortType: SortTypeEnum.BOUNDARY,
      });
    }

    if (placements.status === "finished" && placements.eligibilityEnabled) {
      sortOptions.push({
        disabled: false,
        sortType: SortTypeEnum.ELIGIBILITY,
      });
    }

    return {
      sortOptions,
    };
  }, [address, config, placements]);
}
