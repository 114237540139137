import { configureStore, createSelector } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import address from "./address";
import config from "./config";
import placements from "./placements";
import distance from "./distance";
import filter from "./filter";
import sort from "./sort";
import results from "./results";
import saved from "./saved";
import miscPersistedReducer from "./miscPersisted";
import lang from "./lang";
import googleMap from "./googleMap";
import highlightedPlacement from "./highlightedPlacement";
import openedPanel from "./openedPanel";
import selectedPlacement from "./selectedPlacement";
import organization from "./organization";
import latLngBounds from "./latLngBounds";
import messaging from "./messaging";
import boundary from "./boundary";
import modals from "./modals";
import { PlacementDetails } from "../types/Placement";
import { UIConfig } from "../types/UIConfig";
import { getPlacementIndices } from "../services/searchIndex";

const persistedSaved = persistReducer(
  { key: "saved", version: 1, storage },
  saved
);

const miscPersisted = persistReducer(
  { key: "miscPersisted", version: 1, storage },
  miscPersistedReducer
);

const reduxSearchModule = require("redux-search");
const { reducer: searchReducer, reduxSearch } = reduxSearchModule;

export const getConfiguredStore = (uiConfig: UIConfig | void) => {
  const enhancers: any = undefined;
  const options = {
    enhancers,
    reducer: {
      config,
      placements,
      address,
      distance,
      filter,
      sort,
      results,
      lang,
      highlightedPlacement,
      openedPanel,
      selectedPlacement,
      organization,
      latLngBounds,
      messaging,
      search: searchReducer,
      saved: persistedSaved,
      miscPersisted,
      googleMap,
      boundary,
      modals,
    },
    middleware: (
      getDefaultMiddleware: (arg0: {
        serializableCheck: {
          ignoredActions: (
            | "persist/FLUSH"
            | "persist/REHYDRATE"
            | "persist/PAUSE"
            | "persist/PERSIST"
            | "persist/PURGE"
            | "persist/REGISTER"
          )[];
        };
      }) => any
    ) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  };

  if (uiConfig) {
    const placementsSelector = createSelector(
      (state: any) => {
        return state.placements?.byId || {};
      },
      (placementsById) => {
        return Object.keys(placementsById).map((id) => {
          const placement = placementsById[id];
          return {
            id: placement.id,
            ...Object.keys(placement.details).reduce(
              (acc: PlacementDetails, key) => {
                const data = placement.details[key];
                acc[key] = Array.isArray(data) ? data.join(" ") : data;
                return acc;
              },
              {}
            ),
          };
        });
      }
    );

    options.enhancers = [
      reduxSearch({
        resourceIndexes: {
          placements: getPlacementIndices(uiConfig),
        },
        resourceSelector: (resourceName: any, state: any) => {
          if (state.placements.status !== "finished") return;
          return placementsSelector(state);
        },
      }),
    ];
  }

  return configureStore(options);
};

const initialStore = getConfiguredStore();

export type RootState = ReturnType<typeof initialStore.getState>;
export type AppDispatch = typeof initialStore.dispatch;
