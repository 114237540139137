import React from "react";
import {
  useCheckboxGroup,
  FormLabel,
  FormControl,
  Flex,
} from "@chakra-ui/react";

import { CheckboxCard } from "./CheckboxCard";
import { uniq } from "lodash";

interface CheckboxCardsProps {
  options: string[];
  selectedValues: string[];
  label?: string;
  onChange: (options: string[]) => void;
}

export const CheckboxCards = (
  props: CheckboxCardsProps
): React.ReactElement => {
  const handleChange = (options: string[]) => {
    props.onChange(options);
  };

  const { getCheckboxProps } = useCheckboxGroup({
    onChange: handleChange,
    value: props.selectedValues,
  });

  return (
    <FormControl>
      {props.label && <FormLabel>{props.label}</FormLabel>}
      <Flex gridGap={4} align="top" wrap="wrap">
        {uniq(props.options).map((value) => {
          const checkbox = getCheckboxProps({ value });
          return (
            <CheckboxCard key={value} {...checkbox}>
              {value}
            </CheckboxCard>
          );
        })}
      </Flex>
    </FormControl>
  );
};
